import { ProjectAssets, ImageAssets, VideoAssets, DownloadAssets } from '@models';
import * as Image_01 from './Image_01.jpg';
import * as Image_02 from './Image_02.jpg';
import * as Image_03 from './Image_03.png';

export const Title: ImageAssets = {
    Title_01 : Image_01
};

export const Images: ImageAssets = {
    Image_02,
    Image_03
};

export const Videos: VideoAssets = {
    
};

export const Downloads: DownloadAssets = {
    
};

const PatchInfused: ProjectAssets = {
    Title,
    Images,
    Videos,
    Downloads
};

export default PatchInfused;