import { ProjectData, Tags, ProjectStatus, Products, Contributors, OtherLanguages, OtherSoftware, OtherTechnologies } from '@structures/ProjectData';

export const SnakeClone: ProjectData = {
    summary: {
        id: "SC",
        title: "Snake (Clone)",
        aliases: ["Snake", "SC"],
        version: 1.0,
        status: ProjectStatus.Completed,
        initial: new Date(2005, 6, 1),
        release: new Date(2005, 7, 5),
        tags: {
            [Tags.Products]: [
                Products.Games
            ],
            [Tags.Software]: [
                OtherSoftware.Flash
            ],
            [Tags.Languages]: [
                OtherLanguages.AS3
            ],
            [Tags.Technologies]: [
                OtherTechnologies.Dream
            ]
        },
        description: "A clone of the popular classic game 'Snake'."
    },
    details: {
        description: "A clone of 'Snake', worked just like the original, only in Flash."
            + "<br/><br/>"
            + "Created after I took a class in high school about web development and learned Flash.",
        gallery: [],
        contributions: [{
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Programmer",
                involvement: "Develop a basic snake game with pixel graphics.",
                achievements: [
                    "Developed the snake to show on screen",
                    "Developed the ability to spawn a random pellet on screen.",
                    "Developed the ability for the snake to eat pelletes and grow.",
                    "Developed the ability to store the score on the browser local storage.",
                    "Developed a simple UI to keep track of the score.",
                    "Developed game ending conditions."
                ]
            }]
        }]
    },
};

export default SnakeClone;