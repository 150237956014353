import { ComponentProps } from '@models/types';
import Social from './Social';

export type GitHubProps = ComponentProps & {
  username: string
};

export const GitHub = ({username, className, style, children} : GitHubProps) => {
  return (
    <Social
      className={className}
      style={style}
      type={"github"}
      link={new URL(`https://www.github.com/${username}`)}
    >
      {children}
    </Social>
  );
};

export default GitHub;