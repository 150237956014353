import sanitizeHtml from 'sanitize-html';
import './Html.css';

export type SanitizeOptions = sanitizeHtml.IOptions & {
    wrapInParagraph: boolean
};

const defaultOptions: SanitizeOptions = {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ]),
    allowedAttributes: false,
    allowedIframeHostnames: ['www.youtube.com'],
    wrapInParagraph: true
};

const defaultDisplayOptions: SanitizeOptions = {
    allowedTags: (defaultOptions?.allowedTags || []).concat([ 'button' ]),
    wrapInParagraph: false
};
  
export const sanitize = (html: string, options?: SanitizeOptions) => ({
    __html: sanitizeHtml(
        !options?.wrapInParagraph || (html.startsWith("<p>") && html.endsWith("</p>")) ? html : `<p>${html}</p>`,
        { ...defaultOptions, ...options }
    )
});

export type SafeHtmlProps = {
    html: string,
    options?: SanitizeOptions
}

export const ViewHtml = ({ html, options } : SafeHtmlProps) => (
    <span className="view-html" dangerouslySetInnerHTML={sanitize(html, options)} />
);

export const InteractiveHtml = ({ html, options = defaultDisplayOptions } : SafeHtmlProps) => (
    <ViewHtml html={html} options={options} />
);

export default ViewHtml;