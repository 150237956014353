import { ProjectData, Tags, Languages, Software, ProjectStatus, Products, Contributors, Technologies, OrientationType } from '@structures/ProjectData';
import { Title, Images, Videos } from '@projects/Screen Snap';
import { FileType } from '@models';

export const ImageAssets = Object.values(Images);
export const VideoAssets = Object.values(Videos);

export const ScreenSnap: ProjectData = {
    summary: {
        id: "SS",
        title: "Screen Snap",
        orientation: OrientationType.Portrait,
        aliases: ["SS"],
        filter: { settings: { pixelSize: 1 }},
        version: 0.9,
        status: ProjectStatus.Completed,
        initial: new Date(2015, 10, 14),
        release: new Date(2015, 10, 17),
        previews:[{
            file: Title.Title_01,
            type: FileType.Image,
            description: "A screen with 5 buttons on the top and a lot of empty canvas space.",
            caption: `Main window of the screen recording application.`
        }],
        tags: {
            [Tags.Products]: [
                Products.Tools
            ],
            [Tags.Software]: [
                Software.VS
            ],
            [Tags.Languages]: [
                Languages.CSharp
            ],
            [Tags.Technologies]: [
                Technologies.WinForms
            ]
        },
        description: "A simple and free screen recording application which utilizes windows native print screen capabilities."
    },
    details: {
        description: "This simple application provides a free and easy to use screen recorder."
            + "<br/><br/>"
            + "It was made as a simple way to show off other work I've done and was used to capture many of the videos on my portfolio!",
        gallery: [{
            file: ImageAssets[0],
            type: FileType.Image,
            description: "An image of a window displaying options to provide an output folder and the frames per second for the output video.",
            caption: "The settings window, provides some very basic options for how and where the video will be recorded."
        }, {
            file: VideoAssets[0],
            type: FileType.Video,
            description: "A video demonstration of the application recording itself and outputting the final video being watched.",
            caption: "A demo of Screen Snap recording a demo of itself!"
        }],
        contributions: [{
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Tools Engineer",
                involvement: "Developed all of the tools logic and designed the WinForms UI.",
                achievements: [
                    "Developed application to record part of a users screen, they can select the portion of the screen to record.",
                    "Utilized native window calls to do the heavy lift of actually generating the images and streaming them into a video format.",
                    "Completed the application, along with all features, within 3 days!",
                    "Designed & developed the entire user interface."
                ]
            }]
        }]
    },
};

export default ScreenSnap;