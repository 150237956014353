
export const localhost = ["localhost", "127.0.0.1"];

export const isDevelopment = () => {
    return localhost.includes(window.location.hostname);
};

export const hasNoNetwork = () => {
    return !navigator.onLine;
};
