import React, { useState, useCallback } from 'react';
import { ComponentProps } from '@models';
import { alignmentToCssPropertyItem, Column, ContainerAlignment, Dropdown, Row } from '@app/components';
import { clamp, useRenderOnResize } from '@utilities';
import { HasOrderable, useExistingProject } from '@services';

export type SizeRange = {
    min?: number,
    max?: number,
    ideal?: number
};

export type ProjectsProps = ComponentProps & {
    alignment?: ContainerAlignment,
    size?: SizeRange
};

export const Projects = ({alignment, size, className, style, children}: ProjectsProps) => {
    const directionOptions = [
        "Ascending",
        "Descending"
    ];
    const [direction, setDirection] = useState(directionOptions[0]);
    const onChangeDirectionOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setDirection(event.target.value);
    };

    const orderOptions = [
        "Title",
        "Inception",
        "Release"
    ];
    const [order, setOrder] = useState(orderOptions[0]);
    const onChangeOrderOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setOrder(event.target.value);
    };
    const orderBy = useCallback((a: HasOrderable, b: HasOrderable)  =>  {
        switch (order) {
            case "Inception":
                return a.initial && b.initial ? b.initial.getTime() - a.initial.getTime() : b.initial ? 1 : a.initial ? -1 : 0;
            case "Release":
                return a.release && b.release ? b.release.getTime() - a.release.getTime() : b.release ? 1 : a.release ? -1 : 0;
            default:
                return b.title?.localeCompare(a.title || "") || 0;
        }
    }, [order]);

    const sizeRange = { ideal: size?.ideal || 0.28, min: size?.min || 350, max: size?.max || 450 };
    const alignmentStyles = alignmentToCssPropertyItem(alignment);
    const maxWidth = clamp(Math.ceil(document.body.clientWidth * sizeRange.ideal), sizeRange.min, sizeRange.max);
    const optimalColumns = Math.floor((document.body.clientWidth - 37 * 2) / maxWidth);
    const { getFilteredProjects: getFilteredTags } = useExistingProject(orderBy);
    const allChildren = [
        ...(React.Children.toArray(children))
    ];
    const rows: typeof allChildren[] = [];
    const totalChildren = allChildren.length;
    const orderedChildren = getFilteredTags(allChildren);
    if(direction === "Ascending") {
        orderedChildren.reverse();
    }
    let visibleChildren = 0;
    
    while (orderedChildren.length > 0) {
        const row = orderedChildren.splice(0, optimalColumns);
        visibleChildren += row.length;
        if(row.length) {
            if(alignment && ![ContainerAlignment.Middle, ContainerAlignment.Resize].includes(alignment)) {
                while(row.length < optimalColumns) {
                    if(alignment === ContainerAlignment.Start) {
                        row.push(<div style={{width: maxWidth}}></div>);
                    } else {
                        row.splice(0, 0, <div style={{width: maxWidth}}></div>);
                    }
                }
            }
            rows.push(row);
        }
    }

    useRenderOnResize();

    const fixedHeightForOrder = 30;

    return (
        <div className={classNames("rpgui-projects", className)} style={{position: "relative", ...style}}>
            <span style={{position: "absolute", top: -15, left: 0, right: 0}}>
                <span className="super-font">
                    Showing {visibleChildren}/{totalChildren} Ordered by
                    &nbsp;<Dropdown options={orderOptions} onChange={onChangeOrderOption} /> and
                    &nbsp;<Dropdown options={directionOptions} onChange={onChangeDirectionOption} />
                </span>
            </span>
            {rows.length ? (rows.map((row, index) => (
                <Column key={index} spacing={index === 0 ? fixedHeightForOrder : 10} alignment={ContainerAlignment.Middle}>
                    <Row
                        style={{width: alignment === ContainerAlignment.Resize ? "100%" : ((maxWidth + 10) * row.length) + 10}}
                        spacing={10}
                        alignment={ContainerAlignment.Start}
                        wrap
                    >
                        {row.map((child, index) => (
                            <div key={index} className="rpgui-project" style={{...alignmentStyles, width: maxWidth, height: 333}}>
                                {child}
                            </div>
                        ))}
                    </Row>
                </Column>
            ))) : (
                <>
                    <div style={{height: fixedHeightForOrder}}></div>
                    <span>¯\_(ツ)_/¯</span>
                    <br/>
                    <span>No Projects Found!</span>
                </>
            )}
        </div>
    );
};

export default Projects;