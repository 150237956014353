import React from "react";
import Header from "@layouts/Header";
import Content from "@layouts/Content";
import Footer from "@layouts/Footer";

const Layout = () => {
  return (
    <>
      <Header />
      <Content />
      <Footer />
    </>
  );
};

export default Layout;