import { ProjectAssets, ImageAssets, VideoAssets, DownloadAssets } from '@models';
import * as Protected from './Chuunin Exams-Forest Of Death v1.07 - Production.zip';
import * as Unprotected from './Chuunin Exams-Forest Of Death v1.07 - Unprotected.zip';
import * as Title_01 from './Title_01.jpg';
import * as Image_01 from './Image_01.jpg';

export const Title: ImageAssets = {
    Title_01
};

export const Images: ImageAssets = {
    Image_01
};

export const Videos: VideoAssets = {
    
};

export const Downloads: DownloadAssets = {
    Protected,
    Unprotected
};

const ChuuninExamsForestOfDeath: ProjectAssets = {
    Title,
    Images,
    Videos,
    Downloads
};

export default ChuuninExamsForestOfDeath;