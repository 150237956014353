import { ProjectData, Tags, OtherLanguages, OtherSoftware, ProjectStatus, Products, Contributors, Software, Languages, Technologies, OrientationType } from '@structures/ProjectData';
import { Title, Images, Downloads } from '@projects/Dragon Ball Z Legend of the Super Saiyan - Editor';
import { FileType } from '@models';

export const ImageAssets = Object.values(Images);

export const DownloadAssets = Object.values(Downloads);

export const DragonBallZLegendOfTheSuperSaiyanEditor: ProjectData = {
    summary: {
        id: "DBZLOTSSE",
        title: "Dragon Ball Z: Legend of the Super Saiyan - Editor",
        aliases: ["DBZ LOTSS SNES"],
        orientation: OrientationType.Portrait,
        filter: { settings: { pixelSize: 1 }},
        version: 0.76,
        status: ProjectStatus.Completed,
        initial: new Date(2014, 9, 1),
        release: new Date(2023, 6, 24),
        previews:[{
            file: Title.Title_01,
            type: FileType.Image,
            description: "An editor window showing Playable Character modification of Battler, Level, Battle Power, Experience, Health, Ki, Rage.",
            caption: `Playable Character Editor.`
        }],
        tags: {
            [Tags.Products]: [
                Products.Tools
            ],
            [Tags.Software]: [
                Software.VS,
                OtherSoftware.Olly
            ],
            [Tags.Technologies]: [
                Technologies.WinForms
            ],
            [Tags.Languages]: [
                Languages.CSharp,
                OtherLanguages.SNES
            ]
        },
        description: "An all-purpose editor for the SNES game DBZ: LoTSS."
    },
    details: {
        description: "Designed to eventually become an all-purpose editor for the Dragon Ball Z: Legend of the Super Saiyan game."
            + "<br/><br/>"
            + "It was planned to allow the user to edit just about anything in the game, with the purpose of empowering users to create their own patches and mods that can expand on various areas of gameplay."
            + "<br/><br/>"
            + "Currently it features a Character, Battler, Skill, Enemy Group, Item and Map editor."
            + "<br/><br/>"
            + "The Character Editor allows modification of a playable characters associated Battler, Level, Battle Power, Health, Ki, and Rage (which is their chance to perform a critical strike)."
            + "<br/><br/>"
            + "The Battler Editor allows modification of enemy characters Level, Battle Power, Health, Ki, and Rage (which is their chance to perform a critical strike), Skills, Sprites, Portraits and Palettes."
            + "<br/><br/>"
            + "The Skill Editor allows modification of Ki usage."
            + "<br/><br/>"
            + "The Groups Editor allows modification of enemy encounter groups."
            + "<br/><br/>"
            + "The Item Editor allows modification of Portraits, Palettes, Zenie cost and Item Types which are their effects."
            + "<br/><br/>"
            + "The Map Editor allows modification of some raw map data."
            + "<br/><br/>"
            + "The Palette Editor allows modification of all color palettes in the game."
            + "<br/><br/>"
            + "The Font Editor allows modification of the sybmols used for fonts."
            + "<br/><br/>"
            + `The editor is also hosted on <a href="https://www.romhacking.net/utilities/1108/" target="_blank">ROMHacking.net</a>`
            + "<br/><br/>"
            + `An article was published on <a href="https://tcrf.net/index.php?title=Dragon_Ball_Z%3A_Super_Saiya_Densetsu" target="_blank">The Cutting Room Floor</a> detailing more information about the ROM and hacking it.`,
        gallery: [{
            file: ImageAssets[0],
            type: FileType.Image,
            description: "",
            caption: "Playable & Non-Playable Battler Editor."
        }, {
            file: ImageAssets[1],
            type: FileType.Image,
            description: "",
            caption: "Skills Editor."
        }, {
            file: ImageAssets[2],
            type: FileType.Image,
            description: "",
            caption: "Items Editor."
        }, {
            file: ImageAssets[3],
            type: FileType.Image,
            description: "",
            caption: "Groups (Enemy Encounters) Editor."
        }, {
            file: ImageAssets[4],
            type: FileType.Image,
            description: "",
            caption: "Maps Editor."
        }, {
            file: ImageAssets[5],
            type: FileType.Image,
            description: "",
            caption: "Global Settings Editor."
        }, {
            file: ImageAssets[6],
            type: FileType.Image,
            description: "",
            caption: "Application File Dropdown showing that the application is fully feature complete when it comes to loading/saving ROMs."
        }, {
            file: ImageAssets[7],
            type: FileType.Image,
            description: "",
            caption: "Data Editor which allows users to search for specific bytes of data, view them using the current Font Table, or manipulate them, while providing a way to undo/redo any changes they make."
        }, {
            file: ImageAssets[8],
            type: FileType.Image,
            description: "",
            caption: "Font Table Editor which allows bytes in the ROM to be associated with (encoded as) Text."
        }, {
            file: ImageAssets[9],
            type: FileType.Image,
            description: "",
            caption: "History Viewer allows the user to see all of the changes they've made in the UI as changes Hex Changes to the ROM, displaying the full undo/redo history."
        }, {
            file: ImageAssets[10],
            type: FileType.Image,
            description: "",
            caption: "Application Preferences Editor."
        }, {
            file: ImageAssets[11],
            type: FileType.Image,
            description: "",
            caption: "Palette Portrait Editor."
        }, {
            file: ImageAssets[12],
            type: FileType.Image,
            description: "",
            caption: "Palette Battler Editor."
        }, {
            file: ImageAssets[13],
            type: FileType.Image,
            description: "",
            caption: "Palette Color Swatch Editor."
        }, {
            file: ImageAssets[14],
            type: FileType.Image,
            description: "",
            caption: "Text for Characters Editor."
        }, {
            file: ImageAssets[15],
            type: FileType.Image,
            description: "",
            caption: "Text for Battlers Editor."
        }, {
            file: ImageAssets[16],
            type: FileType.Image,
            description: "",
            caption: "Text for Skills Editor."
        }, {
            file: ImageAssets[17],
            type: FileType.Image,
            description: "",
            caption: "Text for Items Editor."
        }, {
            file: ImageAssets[18],
            type: FileType.Image,
            description: "",
            caption: "Text for Game Events Editor."
        }, {
            file: DownloadAssets[0],
            type: FileType.Download,
            description: `<button class="rpgui-button">Download</button>`,
            caption: "Latest Stable Version 0.75 of the Editor."
        }],
        contributions: [{
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Software Engineer",
                involvement: "Designed the Software, helped investigate assembly code, built out UI using WinForms, wrote all code to read the ROM and write to different places in it.",
                achievements: [
                    "Worked with Assembly and learned to reverse engineer compiled code.",
                    "Learned how a fully finished/featured game was structured and how the resulting code was compiled.",
                    "Built a library to assist in providing controls which can read from a particular hex offset and make modifications according to provided encryption and compression algorithims.",
                    "Put together the User Interface with the appropriate settings for hex offsets which correspond to particular data from the game using the BasicTools.dll & HexTools.dll control libraries I built.",
                    "Published an article about the ROM and additional information regarding the game data and functions."
                ]
            }]
        }]
    },
};

export default DragonBallZLegendOfTheSuperSaiyanEditor;