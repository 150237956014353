import React from 'react';
import { Avatar as MuiAvatar } from '@mui/material';
import { ComponentProps, UserModel } from '@models/types';
import { withTooltip } from './Tooltip';
import { Flex } from '@aws-amplify/ui-react';
import { getInitials, nameToColor } from '@utilities';

export type AvatarDisplayElement = {
  className?: string,
  compact?: boolean,
  hidden?: boolean
};

export type AvatarCustomization = {
  text?: AvatarDisplayElement,
  icon?: AvatarDisplayElement
};

export type AvatarProps = ComponentProps & {
    user?: UserModel,
    onClick?: React.MouseEventHandler<HTMLDivElement>,
    customization?: AvatarCustomization
};

export const Avatar = withTooltip(({user, onClick, customization, className, style, children} : AvatarProps) => {
  const label = customization?.text?.compact ? getInitials(user?.name) : user?.name;

  return (
      <Flex
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{padding: "0px !important", ...style}}
        className={classNames("rpgui-cursor-point", className)}
        onClick={onClick}
      >
        {!customization?.text?.hidden && label &&  (
          <span className={classNames("is-button simple", customization?.text?.className)}>
            {label}
          </span>
        )}
        {!customization?.icon?.hidden && (
          <MuiAvatar variant="rounded"
            aria-label={user?.name + " icon"}
            src={user?.iconURL || undefined}
            className={classNames("align-self-center nes-container is-rounded does-glow", customization?.icon?.className)}
            {...nameToColor(user?.name)}
            style={{margin: "0px", padding: "0px"}}
          />
        )}
        {children}
      </Flex>
  );
});

export default Avatar;