import { ProjectAssets, ImageAssets, VideoAssets } from '@models';
import * as Image_01 from './Image_01.png';
import * as Image_02 from './Image_02.png';
import * as Image_03 from './Image_03.png';
import * as Image_04 from './Image_04.png';
import * as Image_05 from './Image_05.png';
import * as Image_06 from './Image_06.png';
import * as Title_01 from './Title_01.jpg';

export const Title: ImageAssets = {
    Title_01
};

export const Images: ImageAssets = {
    Image_01,
    Image_02,
    Image_03,
    Image_04,
    Image_05,
    Image_06
};

export const Videos: VideoAssets = {
    
};

const FinalFantasy8SleepingLionheart: ProjectAssets = {
    Title,
    Images,
    Videos
};

export default FinalFantasy8SleepingLionheart;