import {
    GoogleImages,
    Page,
    Section
} from '@components';
import { isDevelopment } from '@utilities';

export const Unknown = () => {
    const randomBetweenRange = (min: number, max: number) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    };

    return (
        <Page name="unknown" heading="Page Not Found">
            <Section name="content">
                <p>Oops...you've walked into some dangerous territory!</p>
                { isDevelopment()
                    ? <p>It's dangerous to go alone...take this!</p>
                    : (
                        <GoogleImages
                            imageSearch={`it's dangerous to go alone`}
                            loadingText={`Hmmm...`}
                            index={randomBetweenRange(0, 1000)}
                        />
                    )
                }
            </Section>
        </Page>
    );
};

export default Unknown;