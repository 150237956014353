import { ProjectData, Tags, Languages, Software, ProjectStatus, Products, Contributors } from '@structures/ProjectData';
import { Title, Images } from '@projects/Spy Ski';
import { FileType } from '@models';

export const ImageAssets = Object.values(Images);

export const SpySki: ProjectData = {
    summary: {
        id: "ASATS",
        title: "A Spy and their Skis",
        aliases: ["Spy Ski", "ASATS"],
        filter: { settings: { pixelSize: 2 }},
        version: 1.0,
        status: ProjectStatus.Completed,
        initial: new Date(2022, 0, 15),
        release: new Date(2022, 0, 30),
        previews:[{
            file: Title.Title_01,
            type: FileType.Image,
            description: "A person on ski's looking out at a slope with snowy hills.",
            caption: `Title screen for the game.`
        }],
        tags: {
            [Tags.Products]: [
                Products.Games
            ],
            [Tags.Software]: [
                Software.VSC,
                Software.Unity
            ],
            [Tags.Languages]: [
                Languages.CSharp
            ]
        },
        description: "Game Jam 2022: a simple story about a spy on their ski's."
    },
    details: {
        description: "A simple skiing game with spies!"
            + "<br/><br/>"
            + "The project was completed within 14 days during a game jam.",
        gallery: [{
            file: ImageAssets[0],
            type: FileType.Image,
            description: "An image of a skier going down a mountain with text explaining how to control them.",
            caption: "Instructions and appearance of the first level in the game."
        }, {
            file: ImageAssets[1],
            type: FileType.Image,
            description: "An image of a skier cutting across the slopes on a mountain.",
            caption: "The skier going down the slopes."
        }],
        contributions: [{
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Lead Unity Developer",
                involvement: "Developed the relevant systems in order to get a skiing game and mentored other engineer with their implementations.",
                achievements: [
                    "Generated a plan for all technical systems needed in order to realize the vision for the game.",
                    "Developed conversation system which can be triggered by collision in order to display story events.",
                    "Developed player & enemy controller for a skiing game.",
                    "Setup assets for levels in the game.",
                    "Provided implementation guidance to other programmer on the level manager system."
                ]
            }]
        }]
    },
};

export default SpySki;