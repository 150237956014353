import { useState } from 'react';
import { Search, Terms } from '@components';
import { ComponentProps, GenericCallback } from '@models';

export type FiltersProps = ComponentProps & {
    filterTitle: string,
    setFilterTitle: GenericCallback<string>,
    products: any[],
    selectedProducts: any[],
    setSelectedProducts: GenericCallback<any[]>,
    languages: any[],
    selectedLanguages: any[],
    setSelectedLanguages: GenericCallback<any[]>,
    software: any[],
    selectedSoftware: any[],
    setSelectedSoftware: GenericCallback<any[]>,
    technologies: any[],
    selectedTechnologies: any[],
    setSelectedTechnologies: GenericCallback<any[]>
};

export const Filters = ({
    filterTitle,
    setFilterTitle,
    products,
    selectedProducts,
    setSelectedProducts,
    languages,
    selectedLanguages,
    setSelectedLanguages,
    software,
    selectedSoftware,
    setSelectedSoftware,
    technologies,
    selectedTechnologies,
    setSelectedTechnologies,
    className,
    style,
    children
}: FiltersProps) => {
    const [show, setShow] = useState<string>("title");

    return (
        <div className={classNames("rpgui-filters", className)} style={{...style, display: "grid", rowGap: 5, marginBottom: 5}}>
            <Search
                label={"Title"}
                text={filterTitle}
                onChange={setFilterTitle}
                show={show}
                setShow={setShow}
            />
            <Terms
                label="Products"
                options={products}
                selected={selectedProducts}
                onChange={setSelectedProducts}
                show={show}
                setShow={setShow}
            />
            <Terms
                label="Languages"
                options={languages}
                selected={selectedLanguages}
                onChange={setSelectedLanguages}
                show={show}
                setShow={setShow}
            />
            <Terms
                label="Software"
                options={software}
                selected={selectedSoftware}
                onChange={setSelectedSoftware}
                show={show}
                setShow={setShow}
            />
            <Terms
                label="Technologies"
                options={technologies}
                selected={selectedTechnologies}
                onChange={setSelectedTechnologies}
                show={show}
                setShow={setShow}
            />
            {children}
        </div>
    )
};

export default Filters;