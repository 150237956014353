import React from 'react';
import { ComponentProps } from '@models';
import { Stack as MuiStack } from '@mui/material';

export enum BlockDirection {
    Column = "column",
    Row = "row"
};

export type BlockProps = ComponentProps & {
    direction: BlockDirection
};

export const Block = ({direction, className, style, children}: BlockProps) => {
    return (
        <MuiStack direction={direction} className={className} style={style}>
            {children}
        </MuiStack>
    );
};

export enum ContainerAlignment {
    Start = "start",
    Middle = "middle",
    End = "end",
    Resize = "resize"
};

export type AlignmentToCss = (alignment?: ContainerAlignment) => React.CSSProperties | undefined;

export const alignmentToCssProperty: AlignmentToCss = (alignment?: ContainerAlignment) => {
    switch (alignment) {
        case ContainerAlignment.Start:
            return { justifyItems: "start", alignItems: "start" };
        case ContainerAlignment.Middle:
            return { justifyItems: "Center", alignItems: "center", margin: "auto" };
        case ContainerAlignment.End:
            return { justifyItems: "end", alignItems: "end" };
        case ContainerAlignment.Resize:
            return { justifyItems: "stretch", alignItems: "stretch" };
    };
};

export const alignmentToCssPropertyItem: AlignmentToCss = (alignment?: ContainerAlignment) => {
    switch (alignment) {
        case ContainerAlignment.Start:
            return { margin: "0 auto 0 0 !important" };
        case ContainerAlignment.Middle:
            return { margin: "auto !important" };
        case ContainerAlignment.End:
            return { margin: "0 0 0 auto !important" };
        case ContainerAlignment.Resize:
            return { width: "100% !important", height: "100% !important" };
    };
};

export type BlockContainerProps = ComponentProps & {
    alignment?: ContainerAlignment,
    spacing?: React.CSSProperties["gap"]
};

export type RowProps = BlockContainerProps & {
    wrap?: boolean
};

export const Row = ({alignment, wrap, spacing, className, style, children}: RowProps) => {
    const cssAlignment = alignmentToCssProperty(alignment);

    return (
        <Block
            direction={BlockDirection.Row}
            className={className}
            style={{
                ...cssAlignment,
                flexWrap: wrap ? "wrap" : "inherit",
                gap: spacing || "inherit",
                ...style
            }}
        >
            {children}
        </Block>
    );
};

export type ColumnProps = BlockContainerProps & {
    
};

export const Column = ({alignment, spacing, className, style, children}: ColumnProps) => {
    const cssAlignment = alignmentToCssProperty(alignment);

    return (
        <Block direction={BlockDirection.Column} className={className}
            style={{
                ...cssAlignment,
                padding: `${spacing}px 0` || "inherit",
                ...style
            }}
        >
            {children}
        </Block>
    );
};

export default Block;