import { clamp } from '@app/utilities';
import React from 'react';

export type GoogleImagesProps = {
    loadingText: string,
    imageSearch: string,
    index?: number,
    max?: number
};

export type Image = {
    link: string,
    width: number,
    height: number
};

export type GoogleImage = {
    fileFormat: string,
    source: URL,
    title: string,
    graphic: Image,
    thumbnail: Image,
};

export type GoogleImagesState = {
    ready?: boolean,
    images?: GoogleImage[]
};

export class GoogleImages extends React.Component<GoogleImagesProps, GoogleImagesState> {
    constructor(props: GoogleImagesProps) {
    super(props);
    this.state = {
      ready: false,
      images: []
    };
  }

  loading: boolean = false;
  get index() {
    return clamp(this.props.index || 0, 0, 100 * 10);
  };
  
  componentDidMount() {
    const searchTerms = this.props.imageSearch;
    const setState = (newState: GoogleImagesState) => this.setState({
        ...this.state,
        ...newState
    });
    const num = (this.index % 10) + 1;
    const start = Math.floor((this.index / 10) + 1);
    const max = clamp(this.props.max || 0, 1, 10);

    this.loadGoogleImages(() => {
        // https://developers.google.com/api-client-library/javascript/start/start-js
        function onCall() {
            // 2. Initialize the JavaScript client library.
            gapi.client.init({
                'apiKey': 'AIzaSyBHFuCop2sdaupSQ04tLMnduOrVAMVDOGQ',
                // clientId and scope are optional if auth is not required.
                // 'clientId': 'YOUR_WEB_CLIENT_ID.apps.googleusercontent.com',
                // 'scope': 'profile',
            }).then(function() {
                // 3. Initialize and make the API request.
                return gapi.client.request({
                    'path': 'https://customsearch.googleapis.com/customsearch/v1',
                    'params': {
                        q: searchTerms.replaceAll(" ", "+"),
                        num,
                        start,
                        imgSize: "medium",
                        searchType: "image",
                        cx: "06cd4ef4fb8aa4595"
                    }
                })
            }).then(function(response: any) {
                const result = response.result;
                const images: GoogleImage[] = [];
                result.items.forEach((item: any) => {
                    if(images.length >= max) return;

                    let image = {} as GoogleImage;

                    image.fileFormat = item.fileFormat;
                    image.source = new URL(item.image.contextLink);
                    image.title = item.title;
                    image.graphic = {
                        link: item.link,
                        width: item.image.width,
                        height: item.image.height
                    };
                    image.thumbnail = {
                        link: item.image.thumbnailLink,
                        width: item.image.thumbnailWidth,
                        height: item.image.thumbnailHeight
                    };

                    images.push(image);
                });
                setState({ images });
            }, function(reason: any) {
                console.error('Error: ' + reason.result.error.message);
            });
        };
        // 1. Load the JavaScript client library.
        gapi.load('client', onCall);
        setState({ ready: true });
    });
  }
  
  componentWillUnmount() {
    // unload script when needed to avoid multiple google scripts loaded warning
    this.unloadGoogleMaps();
  }
  
  loadGoogleImages = (callback: () => void) => {
    const existingScript = document.getElementById("googleImagesScript");
    if(this.loading) return;
    if (!existingScript) {
        const script = document.createElement("script");
        script.src =
            "https://apis.google.com/js/api.js";
        script.id = "googleImages";
        document.body.appendChild(script);
        //action to do after a script is loaded in our case setState
        script.onload = () => {
            if (callback) callback();
        };
    }
    if (existingScript && callback) callback();
    this.loading = true;
  };
  
  unloadGoogleMaps = () => {
      let googleImagesScript = document.getElementById("googleImagesScript");
      if (googleImagesScript) {
        googleImagesScript.remove();
      }
  };
  
  render() {
      if (!this.state.ready) {
        return <p>{this.props.loadingText}</p>;
      }
      return (
        <>
            {this.state.images?.map((image, index) => {
                return (
                    <img
                        key={index}
                        src={image.graphic.link}
                        width={image.graphic.width}
                        height={image.graphic.height}
                        alt={`${this.index}: ${image.title} from ${image.source.href}`}
                    />
                )
            })}
        </>
      );
  }
};

export default GoogleImages;