import { Heading as AwsHeading } from '@aws-amplify/ui-react';
import { ComponentProps } from '@models';
import { withTooltip } from './Tooltip';

export declare type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

export type HeadingProps = ComponentProps & {
    level?: HeadingLevel
};

export const Heading = withTooltip(({level = 1, id, className, style, children} : HeadingProps) => {
    return (
        <AwsHeading id={id} level={level} className={className} style={style}>
            {children}
        </AwsHeading>
    );
});

export default Heading;
