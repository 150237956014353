import { createRef, useEffect, useState } from 'react';
import { ComponentProps } from '@models';
import DotDotDot from 'react-dotdotdot';
import { withTooltip } from './Tooltip';

type DotDotDotPrivate = DotDotDot & { container: HTMLElement };

export type TextCustomization = {
    text?: {
        className?: string,
        style?: React.CSSProperties
    }
    tooltip?: {
        prefix?: string
    }
};

export type TextProps = ComponentProps & {
    maxLines?: number,
    customization?: TextCustomization
};

export const Text = ({maxLines, customization, className, style, children}: TextProps) => {
    const Ellipsis = withTooltip(() => (
        <div>&nbsp;&nbsp;</div>
    ), { style: {
        position: "absolute",
        right: 7,
        bottom: -3
    }});
    const dotdotdot = createRef<DotDotDotPrivate>();
    const [showEllipsis, setShowEllipsis] = useState(false);

    useEffect(() => {
        if(dotdotdot.current) {
            setShowEllipsis(dotdotdot.current.container.innerText.includes("…"));
        }
    }, [dotdotdot, children]);

    const text = customization?.text;
    const tooltip = customization?.tooltip;

    return (
        <span className={className} style={{position: "relative", display: "flex", ...style}}>
            <DotDotDot
                clamp={maxLines || "auto"}
                className={classNames("rpgui-text", text?.className)}
                style={text?.style}
                ref={dotdotdot}
            >
                {children}
            </DotDotDot>
            {showEllipsis &&
                <Ellipsis tooltip={`${tooltip?.prefix ? tooltip?.prefix : ""}${children?.toString()}`} />
            }
        </span>
    );
};

export default Text;