export const now = () => new Date();

export enum TimeUnit {
    Milliseconds = "ms",
    Seconds = "s",
    Minutes = "m",
    Hours = "h",
    Days = "dd",
    Months = "mm",
    Years = "yyyy",
};

export const offsetTime = (date: Date, time: number, unit: TimeUnit) => {
    const offset = new Date(date);
    switch (unit) {
        case TimeUnit.Milliseconds:
            offset.setMilliseconds(offset.getMilliseconds() + time); break;
        case TimeUnit.Seconds:
            offset.setSeconds(offset.getSeconds() + time); break;
        case TimeUnit.Minutes:
            offset.setMinutes(offset.getMinutes() + time); break;
        case TimeUnit.Hours:
            offset.setHours(offset.getHours() + time); break;
        case TimeUnit.Days:
            offset.setDate(offset.getDate() + time); break;
        case TimeUnit.Months:
            offset.setMonth(offset.getMonth() + time); break;
        case TimeUnit.Years:
            offset.setFullYear(offset.getFullYear() + time); break;
    }
    return offset;
};

export const withinTime = (time: Date, offset: number, unit: TimeUnit) => {
    const from = offsetTime(now(), -offset, unit);
    const to = offsetTime(now(), offset, unit);
    return time.getTime() <= to.getTime() && time.getTime() >= from.getTime();
};

export const recentTime = (date?: Date) => {
    if(!date) return '';

    const thisSeconds = withinTime(date, 30, TimeUnit.Seconds);
    const thisMinutes = withinTime(date, 10, TimeUnit.Minutes);
    const thisHours = withinTime(date, 1, TimeUnit.Hours);
    const thisRecent = withinTime(date, 6, TimeUnit.Hours);
    const thisDay = withinTime(date, 1, TimeUnit.Days);
    const thisMonth = withinTime(date, 1, TimeUnit.Months);
    const lastYear = withinTime(date, 1, TimeUnit.Years);
    const thisYear = date.getFullYear() === now().getFullYear();

    if(thisSeconds) return "A Few Moments Ago!";
    else if(thisMinutes) return "A Few Minutes Ago!";
    else if(thisHours) return "Less Than An Hour Ago!";
    else if(thisRecent) return "Less Than A Few Hours Ago!";
    else if(thisDay) return "Today!";
    else if (thisMonth) return "This Month";
    else if (lastYear) return "Last Year";
    else if (thisYear) return "This Year";
    
    return date?.toLocaleDateString();
};
