import { ProjectAssets, ImageAssets, VideoAssets, DownloadAssets } from '@models';
import * as Title_01 from './Title_01.jpg';
import * as GIF_01 from './GIF_01.gif';
import * as GIF_02 from './GIF_02.gif';
import * as GIF_03 from './GIF_03.gif';

export const Title: ImageAssets = {
    Title_01
};

export const Images: ImageAssets = {
    GIF_01,
    GIF_02,
    GIF_03
};

export const Videos: VideoAssets = {
    
};

export const Downloads: DownloadAssets = {
    
};

const SuperFightingRobot: ProjectAssets = {
    Title,
    Images,
    Videos,
    Downloads
};

export default SuperFightingRobot;