import React from 'react';
import { ComponentProps } from '@models/types';
import { withTooltip } from '@components/Tooltip';
import { Flex } from '@aws-amplify/ui-react';
import { v4 as uuidv4 } from 'uuid';
import { OptionType, OptionValue } from './Option';

export enum SelectorVariant {
  Thick = "golden",
  Basic = ""
};

export enum SelectorType {
  Single,
  Multiple,
}

export enum SelectorDirection {
  Horizontal,
  Vertical
}

export type SelectorProps = ComponentProps & {
  variant?: SelectorVariant,
  type?: SelectorType,
  direction?: SelectorDirection,
  values: OptionValue[],
  options?: OptionType[],
  onChange: React.ChangeEventHandler<HTMLSelectorElement>
};

export type HTMLSelectorElement = HTMLInputElement & {
  value: OptionValue[]
};

export const Selector = withTooltip(({variant = SelectorVariant.Basic, type = SelectorType.Single, direction = SelectorDirection.Horizontal, values, options, onChange, className, style, children} : SelectorProps) => {
  const controlType = type === SelectorType.Single ? "radio" : "checkbox";
  const controlUUID = uuidv4();
  
  return (
    <>
      <Flex direction={direction === SelectorDirection.Horizontal ? "row" : "column"}>
        {options?.map(
          (option) => <div key={option.value}>
          <input
            className={`rpgui-${controlType} ${variant} ${className}`}
            name={controlUUID}
            type={controlType}
            checked={values?.includes(option.value)}
            onChange={() => {}}
          />
          <label 
            onClick={() => {
              let newValues: OptionValue[] = values ? [...values] : [];
              if(type === SelectorType.Single) {
                newValues = [option.value];
              } else {
                if(values?.includes(option.value)) {
                  newValues = [...values.filter(x => x !== option.value)];
                } else if(values && !values.includes(option.value)) {
                  newValues = [...values, option.value];
                }
              }
              onChange?.({ currentTarget: { value: newValues } } as React.ChangeEvent<HTMLSelectorElement>)
            }}
          >{option.name || option.value}</label>
        </div>
        )}
      </Flex>
    </>
  );
}, { style: {width: "fit-content"} });

export default Selector;