import { ProjectData, Tags, Languages, Software, ProjectStatus, Products, Contributors } from '@structures/ProjectData';
import { Title, Images, Videos } from '@projects/Final Fantasy 8 - Sleeping Lionheart';
import { FileType } from '@models';

export const ImageAssets = Object.values(Images);

export const VideoAssets = Object.values(Videos);

export const FinalFantasy8SleepingLionheart: ProjectData = {
    summary: {
        id: "FF8SLH",
        title: "Final Fantasy 8: Sleeping Lionheart",
        aliases: ["FF8: Sleeping Lionheart", "FF8: SLH", "Final Fantasy"],
        filter: { settings: { pixelSize: 3 }},
        version: 1.0,
        status: ProjectStatus.Incomplete,
        initial: new Date(2013, 4, 1),
        release: new Date(2013, 10, 28),
        previews:[{
            file: Title.Title_01,
            type: FileType.Image,
            description: "A highly contrasted silohuette of Male with semi long hair looking toward the viewer and a female with longer hair looking off to the side surrounded by orbs swirling around them.",
            caption: `"FF8: Sleeping Lionheart" Logo.`
        }],
        tags: {
            [Tags.Products]: [
                Products.Games
            ],
            [Tags.Software]: [
                Software.RPG
            ],
            [Tags.Languages]: [
                Languages.Ruby
            ]
        },
        description: "An ambitious 2D RPG fan-made sequel lovingly expands the story of Final Fantasy 8."
    },
    details: {
        description: "The game expands the universe and story trying to improve upon the work from the previous installment, while still keeping the core gameplay intact!"
            + "<br/><br/>"
            + "Fan favorites like a Charge Time based battle system, Triple Triad card game, loads of mini games and a deep story are back."
            + "<br/><br/>"
            + "The game attempts to build on the story of the original, while faithfully filling in gaps and expanding on the universe.",
        gallery: [{
            file: ImageAssets[0],
            type: FileType.Image,
            description: "",
            caption: "Game Dungeon/Town exploring view."
        }, {
            file: ImageAssets[1],
            type: FileType.Image,
            description: "",
            caption: "Game Battle System view."
        }, {
            file: ImageAssets[2],
            type: FileType.Image,
            description: "",
            caption: "Exploring the Dollet map."
        }, {
            file: ImageAssets[3],
            type: FileType.Image,
            description: "",
            caption: "Main Menu with multiple characters of various statuses and placeholder character portraits."
        }, {
            file: ImageAssets[4],
            type: FileType.Image,
            description: "",
            caption: "Junction Ability Menu with active and passive skills that can be equipped to a character."
        }, {
            file: ImageAssets[5],
            type: FileType.Image,
            description: "",
            caption: "Triple Triad card game menu system."
        }, {
            file: new URL("https://www.youtube.com/embed/Lti3SBxbbVw?controls=0"),
            type: FileType.Video,
            description: "",
            caption: "Demo game play video."
        }],
        contributions: [{
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Game Programmer",
                involvement: "Developed all of the games logic in accordance with the rules of the game.",
                achievements: [
                    "Triple Triad card game, complete with rules, original cards, new cards, and AI.",
                    "Faitful recreation of FF8's menu system.",
                    "Charge Turn based battle system.",
                    "Fully featured Junction system.",
                    "Battle meter that fills and prevents random battle after being filled, with a reset upon leaving a dungeon."
                ]
            }]
        }, {
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Game Designer",
                involvement: "Assisted in designing game mechanics",
                achievements: [
                    "Designed the flow of the battle system to be similar to the original while being more strategic utilizing something similar to Final Fantasy X.",
                    "Designed how random battles would be scaled down by way of a battle meter which gets full based on the frequency and difficulty of defeated enemies.",
                    "Provided feedback as necessary for different aspects of the gameplay."
                ]
            }]
        }]
    },
};

export default FinalFantasy8SleepingLionheart;