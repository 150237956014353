import { ComponentProps, Email as EmailType } from '@models/types';
import Social from './Social';

export type EmailProps = ComponentProps & {
  address: EmailType
};

export const Email = ({address, className, style, children} : EmailProps) => {
  return (
    <Social
      className={className}
      style={style}
      type={"gmail"}
      link={new URL(`mailto:${address}`)}
    >
      {children}
    </Social>
  );
};

export default Email;