import { ProjectData, Tags, Languages, Software, ProjectStatus, Products, Contributors, Technologies, OrientationType } from '@structures/ProjectData';
import { Title, Images } from '@projects/Patch Infused';
import { FileType } from '@models';

export const ImageAssets = Object.values(Images);

export const PatchInfused: ProjectData = {
    summary: {
        id: "PI",
        title: "Patch Infused",
        orientation: OrientationType.Portrait,
        aliases: ["Patch", "Infused", "Patcher", "PI", "FFT", "FFT PI"],
        filter: { settings: { pixelSize: 1 }},
        version: 0.78,
        status: ProjectStatus.Completed,
        initial: new Date(2014, 9, 9),
        release: new Date(2015, 0, 22),
        previews:[{
            file: Title.Title_01,
            type: FileType.Image,
            description: "A application showing several different listed options which correlate to patches the user has created.",
            caption: `Main window, listing all of the current patches available for a project.`
        }],
        tags: {
            [Tags.Products]: [
                Products.Tools
            ],
            [Tags.Software]: [
                Software.VS
            ],
            [Tags.Languages]: [
                Languages.VB
            ],
            [Tags.Technologies]: [
                Technologies.WinForms
            ]
        },
        description: "An application which allows human-readable mod patches to be generated and applied."
    },
    details: {
        description: "An alternative to IPS patching, where the format is human readable so it can be easily modified after being created."
            + "<br/><br/>"
            + "Generates XML files, which can have comments and variables that make it easy for patch users to modify using this application or through any text editor!"
            + "<br/><br/>"
            + "Originally intended to keep track of the many patches available for games like Final Fantasy Tactics, but it can be used for any game!",
        gallery: [{
            file: ImageAssets[0],
            type: FileType.Image,
            description: "An image of the patch edit screen, which provides some patch metadata such as it's description, name and version.",
            caption: "The patch edit screen, which provides a place to store patch metadata."
        }, {
            file: ImageAssets[1],
            type: FileType.Image,
            description: "An image of a how the XML document generated by the patch would look, including all relevant fields like: Patches, Patch, Description, Location, and Variable.",
            caption: "An example of an actual patch file that was created."
        }],
        contributions: [{
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Tools Engineer",
                involvement: "Developed all of the tools logic and designed the WinForms UI.",
                achievements: [
                    "Developed a data structure which can house multiple patches, and each patch can hold a: title, version, description, variables and comments.",
                    "Implemented a strategy for XML generation and reading of the patch format.",
                    "Developed screens to create, update, and delete patches and the variables contained within them.",
                    "Designed & developed the entire user interface."
                ]
            }]
        }]
    },
};

export default PatchInfused;