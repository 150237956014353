import { Accordion, AccordionDetails, AccordionSummary, Box, InputLabel, Typography } from '@mui/material';
import { ComponentProps, GenericCallback } from '@models';
import { hoverBorderColor, Text, useBorder } from '@app/components';

export type SearchProps = ComponentProps & {
    label: string,
    text: string
    onChange?: GenericCallback<string>,
    show?: string,
    setShow?: GenericCallback<string>
};

export const Search = ({label, text, onChange, show, setShow, className, style, children}: SearchProps) => {
    text = text || "";

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(event.currentTarget.value);
    };

    const handleShow = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setShow?.(isExpanded ? panel : "");
    };

    return (
        <Accordion expanded={show === label.toLowerCase()} onChange={handleShow(label.toLowerCase())} disableGutters>
            <AccordionSummary
                expandIcon={<span style={{rotate: "90deg"}}>&gt;</span>}
                className={classNames(className, `rpgui-cursor-point propogate-no-margin nes-container is-thin is-rounded ${useBorder("white")}`)}
                style={{backgroundColor: "dimgrey", padding: "0 5px", minHeight: "2em", ...style}}
                sx={{...hoverBorderColor()}}
            >
                <Box style={{display: "inline-flex", width: "100%"}}>
                    <Typography style={{ width: "clamp(160px, 33%, 500px)", flexShrink: 0 }}>
                        <InputLabel
                            className="no-margin"
                            style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                fontSize: "clamp(12px, 2vw, 15px)"
                            }}
                        >{label}</InputLabel>
                    </Typography>
                    <span className="super-font" style={{ width: "66%" }}>
                        <Text
                            className="no-margin"
                            style={{
                                maxWidth: "60vw"
                            }}
                            maxLines={1}
                        >
                            Matching: { text || "None" }
                        </Text>
                    </span>
                </Box>
            </AccordionSummary>
            <AccordionDetails style={{backgroundColor: "grey"}}>
                <div style={ {display: "inline-flex", flexDirection: "row" }}>
                    <div style={{ display: "inline-flex", flexDirection: "column", margin: "auto" }}>
                        <div style={{ width: "fit-content" }}><InputLabel>Title</InputLabel></div>
                    </div>
                    <input
                        type={"text"}
                        style={{ marginLeft: 10, flexDirection: "column", minWidth: "50vw" }}
                        defaultValue={text}
                        onChange={handleChange}
                        placeholder="Name to Search..."
                    />
                </div>
                {children}
            </AccordionDetails>
        </Accordion>
    );
};

export default Search;
