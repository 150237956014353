import { ProjectData, Tags, Languages, Software, ProjectStatus, Products, Contributors } from '@structures/ProjectData';
import { Title, Images, Videos, Downloads } from '@projects/Dragon Ball Z - Raging Pride';
import { FileType } from '@models';

export const ImageAssets = Object.values(Images);

export const VideoAssets = Object.values(Videos);

export const DownloadAssets = Object.values(Downloads);

export const DragonBallZRagingPride: ProjectData = {
    summary: {
        id: "DBZRP",
        title: "Dragon Ball Z: Raging Pride",
        aliases: ["DBZ RP", "DBZ Raging Pride"],
        filter: { settings: { pixelSize: 1 }},
        version: 1.0,
        status: ProjectStatus.Completed,
        initial: new Date(2008, 2, 1),
        release: new Date(2010, 9, 10),
        previews:[{
            file: Title.Title_01,
            type: FileType.Image,
            description: "A image of Goku flying towards the screen with the text Dragona Ball Z: Raging Pride visible.",
            caption: `The games title screen.`
        }],
        tags: {
            [Tags.Products]: [
                Products.Games
            ],
            [Tags.Software]: [
                Software.VS,
                Software.RPG
            ],
            [Tags.Languages]: [
                Languages.CPlus
            ]
        },
        description: "An original fan RPG faithfully recreating the adventures from the source material."
    },
    details: {
        description: "A game which faithfully recreates iconic momements from the original story as a JRPG."
            + "<br/><br/>"
            + "Features favorite Z-Fighters from the Tournament Saga up until the Saiyan Saga!"
            + "<br/><br/>"
            + "It utilizes the RPG Maker Engine with custom menu and battle systems."
            + "<br/><br/>"
            + "Developed over 15 years ago, it has received over 30,000 downloads from various sources across the internet!",
        gallery: [{
            file: VideoAssets[0],
            type: FileType.Video,
            description: "A video with many of the games core elements represented.",
            caption: "A gameplay and story trailer."
        },{
            file: ImageAssets[0],
            type: FileType.Image,
            description: "A mysterious man approaches a group of people looking for Tien, Yamcha, or Krillin.",
            caption: "A mysterious man approaches a group looking for his friends, showcases the games dialogue and world sprites."
        }, {
            file: ImageAssets[1],
            type: FileType.Image,
            description: "An in game image showing Goku dressed in a turban and some strange clothes looking puzzled.",
            caption: "Goku is revealed to to be the stranger, in a cinematic sequence."
        }, {
            file: ImageAssets[2],
            type: FileType.Image,
            description: "An in game image showing Goku inside a rainy tournament arena surrounded by a crowd as he's about to speak to Yamcha, while Tien and Krillin look on.",
            caption: "Goku finds Tien, Chiaotzu, Yamcha & Krillin near the tournament arena with other friends nearby!"
        }, {
            file: ImageAssets[3],
            type: FileType.Image,
            description: "An in game image showing Goku inside a training dojo surrounded by trainers and other trainees.",
            caption: "Training minigames room that can be used to increase power before the tournament begins."
        }, {
            file: ImageAssets[4],
            type: FileType.Image,
            description: "An in game image showing Goku inside training dojo while ki blasts are coming towards him.",
            caption: "Training minigame that requires dodging ki blasts in order to improve speed."
        }, {
            file: ImageAssets[5],
            type: FileType.Image,
            description: "An in game image showing Krillin talking to Yamcha before in front of the world tournament arena ring.",
            caption: "Krillin providing Yamcha with some words of encouragement before his bout with the mysterious 'Hero'."
        }, {
            file: ImageAssets[6],
            type: FileType.Image,
            description: "An in game image showing Yamcha on his knees with a scared looking 'Hero' inside the world tournament arena ring.",
            caption: "'Hero' deals a mighty low blow to Yamcha's sensitive area during their matchup."
        }, {
            file: ImageAssets[7],
            type: FileType.Image,
            description: "An in game cinematic image showing 'Junior' taunting Kami during their bout.",
            caption: "Piccolo (known as 'Junior') is reversing an attack (Mafuba) during his bout with Kami."
        }, {
            file: ImageAssets[8],
            type: FileType.Image,
            description: "An in game image showing the battle between Goku and Piccolo during the World Tournament.",
            caption: "Battle system preview with Goku vs. Piccolo during the 23rd World Tournament."
        }, {
            file: ImageAssets[9],
            type: FileType.Image,
            description: "An in game image showing the battle Goku using a Kamehameha on Piccolo during the World Tournament.",
            caption: "Battle system preview with Goku performing a Kamehameha."
        }, {
            file: ImageAssets[10],
            type: FileType.Image,
            description: "An in game cinematic image showing Goku and Piccolo in battle torn clothing, taunting one another.",
            caption: "Cinematic system showing Piccolo taunting Goku in the middle of their bout, after both have shed their weighted clothing."
        }, {
            file: ImageAssets[11],
            type: FileType.Image,
            description: "An in game image showing Goku in a defensive stance facing off against a Giant Piccolo.",
            caption: "Boss fight with Super Namek Piccolo."
        }, {
            file: ImageAssets[12],
            type: FileType.Image,
            description: "An in game image showing Goku in a defensive stance while Piccolo causes a massive explosion.",
            caption: "Third and final boss fight with Piccolo where he summons a giant explosion after losing the fight."
        }, {
            file: ImageAssets[13],
            type: FileType.Image,
            description: "An in game image showing Goku, Piccolo, and Krillin levelling up after a battle on a results menu.",
            caption: "The custom battle result screen, features a gauge which displays experience and levels."
        }, {
            file: ImageAssets[14],
            type: FileType.Image,
            description: "An in game image showing Goku being sliced by the sword of a giant wolf creature.",
            caption: "A wolf warrior inflicting Goku with a status effect that prevents him from physically attacking."
        }, {
            file: ImageAssets[15],
            type: FileType.Image,
            description: "An in game image showing Goku walking around Kami's lookout, with Kami and Mr. Popo walking around.",
            caption: "Exploring Kami's lookout after training with Kami and Mr. Popo."
        }, {
            file: ImageAssets[16],
            type: FileType.Image,
            description: "An in game image of a menu with various settings to tweak the gameplay to a players preferences.",
            caption: "The custom menu system, showing off the settings options."
        }, {
            file: ImageAssets[17],
            type: FileType.Image,
            description: "An in game image of a wolf bandit in a forest with an exclamation emote about to chase down Goku and Piccolo.",
            caption: "Enemies are encountered visually on maps and can be avoided."
        }, {
            file: ImageAssets[18],
            type: FileType.Image,
            description: "An in game image of Goku walking around a city, in front of a movie theater with a group of people on line waiting to enter.",
            caption: "The game features a time and weather system which affects the accessibility of different storylines and side quests."
        }, {
            file: ImageAssets[19],
            type: FileType.Image,
            description: "An in game image of Goku standing on Roshi's island, with Kame and Roshi walking around and the mini menu open that displays several commands: Menu, Position, DB Radar, and Fly.",
            caption: "The mini menu used to access some features quickly, with Goku caught mid-blink."
        }, {
            file: ImageAssets[20],
            type: FileType.Image,
            description: "An in game image of several pieces of a Ghost face jumbled on the screen.",
            caption: "A puzzle hidden minigame, which when completed allows you to earn powerful items."
        }, {
            file: ImageAssets[21],
            type: FileType.Image,
            description: "An in game image of gameplay stats showing: Secrets found/missed, and Bosses defeated.",
            caption: "A menu option using Uranai Baba's crystal ball to show the player their gameplay statistics."
        }, {
            file: DownloadAssets[0],
            type: FileType.Download,
            description: `<button class="rpgui-button">Download</button>`,
            caption: "Last official release of the game."
        }],
        contributions: [{
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Game Engineer",
                involvement: "Developed all of original games systems inside of RPG Maker and used some C++ for game engine customizations.",
                achievements: [
                    "Utilized RPG Maker 2003's engine, while pushing it to the limit to create a complete game.",
                    "Developed a custom battle system using custom logic that allows for animated enemy and party battle animations.",
                    "Developed a custom menu system using custom logic, events and picture commands.",
                    "Developed a custom save system to support previews of save files and provide additional information about the party.",
                    "Developed a custom battle results window with an animated gauge to display items, zeni, experience and stats gained.",
                    "Developed a way to utilize a new game plus mode with plugins and implemented it in game.",
                    "Developed custom plugins using DynRPG engine and C++ that enhance the core gameplay elements during battle."
                ]
            }, {
                title: "Game Designer",
                involvement: "Came up with the initial pitch for the game along with the direction of the story and all gameplay elements.",
                achievements: [
                    "Wrote the entire script for the game, using the original manga translation as a reference.",
                    "Came up with fun minigames to enhance the players customization of their party members.",
                    "Sourced and imported then utilized all assets while worked with artist to fulfill unique resource requirements.",
                    "Balanced game according to beta testers feedback, while keeping the scaling of power dynamics authentic to the original source material.",
                    "Created all game story content, and added additional New Game+ content that features hidden characters!"
                ]
            }]
        }]
    },
};

export default DragonBallZRagingPride;