import { ProjectAssets, ImageAssets, VideoAssets, DownloadAssets } from '@models';
import * as Image_01 from './Image_01.jpg';

export const Title: ImageAssets = {
    Title_01: Image_01
};

export const Images: ImageAssets = {
    Image_01
};

export const Videos: VideoAssets = {
    
};

export const Downloads: DownloadAssets = {
    
};

const CetraTools: ProjectAssets = {
    Title,
    Images,
    Videos,
    Downloads
};

export default CetraTools;