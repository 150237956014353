
/**
 * Handles: Pascal: AThing, Snake: a_thing, Camel: aThing, Kebab: a-thing
 * @param text any input text
 * @returns capitalized and spaced text
 */
export const toTitleCase = (text: string) => {
    text = text.replace(/^[ _-]*(.)|[ _-]+(.)/g, (_s, m1, m2) => m1 ? m1.toUpperCase() : " " + m2.toUpperCase());
    return text.replace(/([A-Z])([A-Z])/g, (_s, m1, m2) => m1 + " " + m2);
};

export default toTitleCase;
