import { ComponentProps } from '@models/types';
import {
    Divider,
    Donation,
    Discord,
    Email,
    GitHub,
    LinkedIn,
    Logo
} from '@app/components';
import { Flex } from '@aws-amplify/ui-react';

export type ContactMenuProps = ComponentProps & {
    collapsed?: boolean
};

export const ContactMenu = ({collapsed, className, style, children}: ContactMenuProps) => {
    return (
        <>
            <Divider className="full-width" style={{margin: 0}} />
            <Flex
                direction="row"
                style={{
                    position: "absolute",
                    top: "0",
                    left: `clamp(0px, ${collapsed ? 2.5 : 7.5}vw, 200px)`,
                    display: "inline-flex",
                    alignItems: "center",
                    gap: 10,
                    fontSize: 14,
                    ...style
                }}
                className={classNames("nes-container with-title is-centered is-dark is-rounded", className)}
            >
                <Logo />
                <div><span className="first name">Dino</span> <span className="last name initial-1">Suvalic</span></div>
                <LinkedIn username="dinosuvalic" />
                <GitHub username="makoinfused" />
                <Email address="suvalic.dino@gmail.com" />
                <Discord inviteId="4yVPESk25u" />
                <Donation username="dinosuvalic" />
                {children}
            </Flex>
        </>
    );
}
