import { ProjectData, Tags, OtherLanguages, OtherSoftware, ProjectStatus, Products, Contributors, OtherTechnologies } from '@structures/ProjectData';
import { Title, Images, Videos } from '@projects/Where Did I Put That';
import { FileType } from '@models';

export const ImageAssets = Object.values(Images);

export const VideoAssets = Object.values(Videos);

export const WhereDidIPutThat: ProjectData = {
    summary: {
        id: "WDIPT",
        title: "Where Did I Put That?",
        aliases: ["WDIPT"],
        filter: { settings: { pixelSize: 2 }},
        version: 2.2,
        status: ProjectStatus.Completed,
        initial: new Date(2011, 4, 21),
        release: new Date(2013, 5, 20),
        previews:[{
            file: Title.Title_01,
            type: FileType.Image,
            description: "A red headed boy with glasses in a thinking pose looking at the player, a room can be seen in the background. There is text visible which displays the title, a selection menu to start the game and help text.",
            caption: `"Where Did I Put That?" Logo and Title screen.`
        }],
        tags: {
            [Tags.Products]: [
                Products.Games
            ],
            [Tags.Software]: [
                OtherSoftware.Flash
            ],
            [Tags.Languages]: [
                OtherLanguages.AS3
            ],
            [Tags.Technologies]: [
                OtherTechnologies.Dream
            ]
        },
        description: "A fast paced object matching game about a child trying to put away toys before his mom gets home!"
    },
    details: {
        description: "You play as Timmy a child whose mom is going to be home soon. Timmy has promised his mom he would clean his room before she got back."
            + "Although she'll be back soon, you haven't even started cleaning yet!"
            + "<br/><br/>"
            + "The charming game unfolds through a child perspective, all of the user interface and story telling is displayed through the lense of what makes sense to a child."
            + "<br/><br/>"
            + "Your goal as the player is to clean your room as quickly as possibly by finding all matching toys so that you can put them away."
            + "<br/><br/>"
            + "The game features 3 difficulties (Easy, Medium, Hard) and 2 game modes."
            + "'Clean House' is the classic game mode which has you clean 3 different times, each with more toys than the last in order to get a victory or defeat ending."
            + "'Pig Sty' is a time attack game mode where you clean the room an infinite amount of times, each getting more difficult, to see how many points you can rack up.",
        gallery: [{
            file: ImageAssets[0],
            type: FileType.Image,
            description: "A menu which looks like the scrawlings of a child on a notepad with different settings for the game, including the difficulty and play mode.",
            caption: "In game settings menu."
        }, {
            file: ImageAssets[1],
            type: FileType.Image,
            description: "A messy childs room can be seen with some toys spread about, such as: toy soldier, mini car, police badge, stuffed bear. The points and time left to play are also visible, as is arrows to change the players direction and help text about opening the controls menu.",
            caption: "Game play featuring the custom crayon mouse pointer, and messy room that the player needs to clean up."
        }],
        contributions: [{
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Principal Game Designer",
                involvement: "Designed the Game world, put together documentation for the assets, logic, maps and world.",
                achievements: [
                    "Designed An Entire World with characters, setting, and story.",
                    "Created Game Design Documents.",
                    "Created Technical Design Documents.",
                    "Created Asset Design Documents."
                ]
            }]
        }, {
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Principal Level Designer",
                involvement: "Designed and built out a world for the game to take place.",
                achievements: [
                    "Created 4 different perspectives of a room.",
                    "Provided several different placeholder areas where objects could randomly be spawned.",
                ]
            }]
        }, {
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Principal Game Scripter",
                involvement: "Developed all of the games logic in accordance with the rules of the game.",
                achievements: [
                    "Toy matching logic and scoring mechanics.",
                    "Timing related game victory and end, as well as bonus multipliers scored on a curve.",
                    "Leaderboard using in browser local storage.",
                    "Difficulty settings and game modes for more variety.",
                    "Randomized object generation which took into consideration the difficulty and modes of the game."
                ]
            }]
        }, {
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Principal Pixel Artist",
                involvement: "Illustrated most of the game art.",
                achievements: [
                    "Drew the backgrounds for the room.",
                    "Drew icons used for user interface.",
                    "Drew toys which the player would collect."
                ]
            }]
        }]
    },
};

export default WhereDidIPutThat;