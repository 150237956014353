import { useState} from 'react';
import { GenericCallback, ReactEvent } from '@models';

export type ChangeHandler<V> = (value: NonNullable<V>) => NonNullable<V>;

export type GenericChangeEvent = ReactEvent<any, any>;

export const useChangeEvent = <V extends any, E extends HTMLElement, F extends ReactEvent<E, NonNullable<V>>, H extends React.MouseEventHandler<E>>
(value: V, defaultValue: NonNullable<V>, handler: ChangeHandler<V>, setValue?: GenericCallback<F>) => {
    const [_value, _setValue] = useState(defaultValue);

    const _defaultSetValueHandler = (event: F) => {
        _setValue(event.value);
    };

    const handleValue = {
        get get() {
            return value != null ? value : _value;
        },
        get set() {
            return (event: React.MouseEvent<E>) => {
                (setValue || _defaultSetValueHandler)({ element: event.currentTarget, value: handler(this.get) } as F);
            };
        }
    };

    return [handleValue.get, handleValue.set] as [V, H];
};

export default useChangeEvent;