import { ProjectAssets, ImageAssets, VideoAssets, DownloadAssets } from '@models';
import * as Image_01 from './Image_01.jpg';
import * as Image_02 from './Image_02.jpg';
import * as Image_03 from './Image_03.jpg';
import * as Image_04 from './Image_04.jpg';
import * as Image_05 from './Image_05.jpg';
import * as Image_06 from './Image_06.jpg';
import * as Image_07 from './Image_07.jpg';
import * as Image_08 from './Image_08.jpg';
import * as Image_09 from './Image_09.jpg';
import * as Image_10 from './Image_10.jpg';
import * as Image_11 from './Image_11.jpg';
import * as Image_12 from './Image_12.jpg';
import * as Image_13 from './Image_13.jpg';
import * as Image_14 from './Image_14.jpg';
import * as Image_15 from './Image_15.jpg';
import * as Image_16 from './Image_16.jpg';
import * as Image_17 from './Image_17.jpg';
import * as Image_18 from './Image_18.jpg';
import * as Image_19 from './Image_19.jpg';
import * as Image_20 from './Image_20.jpg';
import * as Image_21 from './Image_21.jpg';
import * as Image_22 from './Image_22.jpg';
import * as Title_01 from './Title_01.jpg';
import * as Video_01 from './Video_01.mp4';
import * as Latest from './DBZ-Raging_Pride_Demo.zip';

export const Title: ImageAssets = {
    Title_01
};

export const Images: ImageAssets = {
    Image_01,
    Image_02,
    Image_03,
    Image_04,
    Image_05,
    Image_06,
    Image_07,
    Image_08,
    Image_09,
    Image_10,
    Image_11,
    Image_12,
    Image_13,
    Image_14,
    Image_15,
    Image_16,
    Image_17,
    Image_18,
    Image_19,
    Image_20,
    Image_21,
    Image_22
};

export const Videos: VideoAssets = {
    Video_01
};

export const Downloads: DownloadAssets = {
    Latest
};

const DragonaBallZRagingPride: ProjectAssets = {
    Title,
    Images,
    Videos,
    Downloads
};

export default DragonaBallZRagingPride;