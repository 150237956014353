import React, { createRef, useEffect, useRef, Children } from 'react';
import { Flex as AmplifyFlex } from '@aws-amplify/ui-react';
import { ComponentProps } from '@models/types';
import { withTooltip } from '@components/Tooltip';
import Scrollbars from '@components/Scrollbars';
import Option from '@components/Option';

export type ListProps = ComponentProps & {
  value?: any,
  maxVisible?: number,
  options?: any[],
  option?: (value: any) => React.ReactNode,
  onChange?: React.ChangeEventHandler<HTMLSelectElement>,
  customScrollbars?: boolean
};

export const List = withTooltip(({value, maxVisible, options, option, onChange, customScrollbars, className, style, children} : ListProps) => {
  const list = createRef<HTMLSelectElement>();
  const ul = useRef<HTMLUListElement>();
  const count = Children.toArray(children).length;
  const minWidth = options?.reduce(
    function (a, b) {
      const as = String(a).length * 25 + 50;
      const bs = String(b).length * 25 + 50;
      return (as > bs ? as : bs);
    }
  ) || 100;

  const handleOnClick = (event: React.MouseEvent<HTMLLIElement>) => {
    const value = event.currentTarget.getAttribute("value");
    if(value) onChange?.({currentTarget: {value}} as React.ChangeEvent<HTMLSelectElement>);
  }

  useEffect(() => {
    if(list?.current) {
      RPGUI.create(list.current, "list");
      ul.current = list.current.parentElement?.querySelector(".rpgui-list-imp") as HTMLUListElement;
      if(ul.current && maxVisible) ul.current.style.minHeight = 1 + 2 * (Math.min(maxVisible, options?.length || count)) + "rem";
    }
  }, [list, maxVisible, options, count]);

  return (
    <>
      <AmplifyFlex direction="column">
      {
        !customScrollbars
        ? <AmplifyFlex direction="column">
            <select
              id="simple-list-standard"
              value={value}
              className={`rpgui-list rpgui-cursor-point ${className}`}
              onChange={onChange}
              style={{...style, display: "none"}}
              ref={list}
            >
              {children}
              { options?.map(load => (
                option ? option(load) : <Option key={load} value={load} />
              ))}
            </select>
          </AmplifyFlex>
        : <Scrollbars 
            className="rpgui-list-imp" 
            style={{minHeight: maxVisible ? 1 + 2 * (Math.min(maxVisible, options?.length || count)) + "rem" : "inherit", minWidth: minWidth + "px"}}
          >
            <ul style={{marginBottom: "-37px"}}>
              {children}
              { options?.map(load => (
                option ? option(load) : <li key={load} className={`${(value === load) ? "rpgui-selected" : ""}`} value={load} onClick={handleOnClick}>{load}</li>
              ))}
            </ul>
          </Scrollbars>
      }</AmplifyFlex>
    </>
  );
}, { style: {width: "fit-content"} });

export default List;