import { createContext, useState, useContext } from 'react';
import { ReactChildren } from "@models";

const defaultProps = {
    initial: {} as any,
    options: {} as any
};

const QueryParametersContext = createContext(defaultProps);

export type QueryparametersProviderProps = {
    children?: ReactChildren
}

export const QueryParametersProvider = ({ children }: QueryparametersProviderProps) => {
    const [initial, setInitial] = useState({} as any);
    const [options, setOptions] = useState({} as any);

    return (
        <QueryParametersContext.Provider value={{
            initial: [initial, setInitial],
            options: [options, setOptions]
        }}>
            {children}
        </QueryParametersContext.Provider>
    );
};

export const useQueryParameterContext = () => {
    const { initial, options } = useContext(QueryParametersContext);
    return [options[0], options[1], initial[0], initial[1]] as [any, (value: any) => void, any, (value: any) => void];
};