import { ProjectData, Tags, Languages, ProjectStatus, Products, Contributors, Software, Technologies, OrientationType } from '@structures/ProjectData';
import { Title, Images, Videos } from '@projects/Sleep Timer';
import { FileType } from '@models';

export const ImageAssets = Object.values(Images);

export const VideoAssets = Object.values(Videos);

export const SleepTimer: ProjectData = {
    summary: {
        id: "ST",
        title: "Sleep Timer",
        orientation: OrientationType.Portrait,
        filter: { settings: { pixelSize: 1 }},
        version: 0.8,
        status: ProjectStatus.Completed,
        initial: new Date(2017, 7, 28),
        release: new Date(2017, 7, 30),
        previews:[{
            file: Title.Title_01,
            type: FileType.Image,
            description: "",
            caption: `"Sleep Timer main window.`
        }],
        tags: {
            [Tags.Products]: [
                Products.Apps
            ],
            [Tags.Software]: [
                Software.VS
            ],
            [Tags.Technologies]: [
                Technologies.WPF
            ],
            [Tags.Languages]: [
                Languages.CSharp
            ]
        },
        description: "A simple WPF App which allows a computer to be put to sleep at a specified time."
    },
    details: {
        description: "A simple WPF App which allows a computer to be put to sleep at a specified time."
            + "Useful when you stream shows on your laptop and would like it to go to sleep, similarly to how a TV has a sleep timer.",
        gallery: [{
            file: ImageAssets[0],
            type: FileType.Image,
            description: "A menu which looks like the scrawlings of a child on a notepad with different settings for the game, including the difficulty and play mode.",
            caption: "In game settings menu."
        }],
        contributions: [{
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "App & UI/UX Designer",
                involvement: "Came up with the idea after streaming shows on my laptop and realizing that although I would fall asleep, my computer would not :(",
                achievements: [
                    "Designed the entire applications feature set.",
                    "Designed the entire applications interface."
                ]
            }]
        }, {
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Principal Game Scripter",
                involvement: "Programming the whole application.",
                achievements: [
                    "It was coded in less than 24 hours",
                    "It should work on any Windows machine and OS which can support .NET Framework 3.5+.",
                ]
            }]
        }]
    },
};

export default SleepTimer;