import { Storage } from "aws-amplify";

export const useRemoteStorage = () => {
    const getFileUrl = async (id: string) => {
        return await Storage.get(id);
    };

    const createFile = async (id: string, file: File) => {
        return await Storage.put(id, file);
    };

    const deleteFile = async (id: string) => {
        return await Storage.remove(id);
    };

    return {
        getFileUrl,
        createFile,
        deleteFile
    };
};