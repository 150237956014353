import { ProjectData, Tags, OtherLanguages, OtherSoftware, ProjectStatus, Products, Contributors } from '@structures/ProjectData';
import { Title, Images, Videos } from '@projects/Vampire Hunter X';
import { FileType } from '@models';

export const ImageAssets = Object.values(Images);

export const VideoAssets = Object.values(Videos);

export const VampireHunterX: ProjectData = {
    summary: {
        id: "VHX",
        title: "Vampire Hunter X",
        aliases: ["VHX", "Vampire Hunter"],
        filter: { settings: { pixelSize: 3 }},
        version: 0.90,
        status: ProjectStatus.Unreleased,
        initial: new Date(2012, 0),
        release: new Date(2013, 0),
        previews:[{
            file: Title.Title_01,
            type: FileType.Image,
            description: "Vampires & Slayers battling under a dark moonlit night sky.",
            caption: `"Vampire Hunter X" Logo.`
        }],
        tags: {
            [Tags.Products]: [
                Products.Games
            ],
            [Tags.Software]: [
                OtherSoftware.Galaxy
            ],
            [Tags.Languages]: [
                OtherLanguages.Galaxy
            ]
        },
        description: "Vampires & Slayers clash in this mashup of Multiplayer Online Battle Arena & Tower Defense!"
    },
    details: {
        description: "A Mod for Starcraft 2 which allows the player to join a battle between the Human Slayers who protect the living, and the Undead Vampires who seek to drain the living."
            + "<br/><br/>"
            + "The Slayers, using their power and technology, seek to preserve human life. The Vampires, using their stealth and speed, look to enslave humanity and use them as cattle."
            + "<br/><br/>"
            + "Vampires roam across the outer villages feeding to grow stronger in preperation for a final assault on the Humans main castle town."
            + "The Slayers are responsible for impeding the Vampires as much as possible while gaining enough momentum to overcome them once and for all.",
        gallery: [{
            file: ImageAssets[0],
            type: FileType.Image,
            description: "A medieval male warrior with a stern expression and an athletic build staring at the viewer.",
            caption: "Concept Art for Male Slayer."
        }, {
            file: ImageAssets[1],
            type: FileType.Image,
            description: "A medieval female warrior with a stern expression and an athletic build staring at the viewer.",
            caption: "Concept Art for Female Slayer."
        }, {
            file: ImageAssets[2],
            type: FileType.Image,
            description: "A male nosferatu-like purple skinned Vampire with a stern expression and an slight build staring at the viewer.",
            caption: "Concept Art for Male Vampire."
        }, {
            file: ImageAssets[3],
            type: FileType.Image,
            description: "A female combination of frankenstein-eseque with nosferatu-like purple skinned Vampire with a stern expression and an slim build staring at the viewer.",
            caption: "Concept Art for Female Vampire."
        }, {
            file: ImageAssets[4],
            type: FileType.Image,
            description: "Icons are displayed showing: player icon, sundial indicating time of day, leaderboard indicating the score, minimap, description to display selected units statistics, their armor, and abilities.",
            caption: "In Game view of User Interface.",
            maps: [{
                map: "tooltip",
                text: "Avatar Icon \n Displays the players icon, provides information about their health and can be double clicked to focus the screen on them.",
                position: {
                    type: "coordinate",
                    shape: "rectangle",
                    x: "1%",
                    y: "1%",
                    width: "8%",
                    height: "15%"
                }
            }, {
                map: "tooltip",
                text: "Sundial \n Displays the current game time via shadows, hovering over it shows the exact time as a number.",
                position: {
                    type: "coordinate",
                    shape: "circle",
                    x: "44.25%",
                    y: "0",
                    radius: "10%",
                    ratio: 1.8
                }
            }, {
                map: "tooltip",
                text: "Leaderboard \n Displays the kills and deaths, grouped by teams.",
                position: {
                    type: "coordinate",
                    shape: "rectangle",
                    x: "72%",
                    y: "1%",
                    width: "27%",
                    height: "48%"
                }
            }, {
                map: "tooltip",
                text: "Minimap Visibility \n Controls the minimap in order to show/hide: terrain or fog of war and also to enable/disable color blind mode.",
                position: {
                    type: "coordinate",
                    shape: "rectangle",
                    x: "5.85%",
                    y: "66%",
                    width: "7.75%",
                    height: "4%"
                }
            }, {
                map: "tooltip",
                text: "Minimap \n Displays the map, shrouded in a fog of war which prevents visibility until an area is explored and has allied presence.",
                position: {
                    type: "coordinate",
                    shape: "rectangle",
                    x: "1.5%",
                    y: "71%",
                    width: "16.5%",
                    height: "27.5%"
                }
            }, {
                map: "tooltip",
                text: "Inventory \n Controls for the current items carried and the hotkeys which can be pressed to quickly use them.",
                position: {
                    type: "coordinate",
                    shape: "rectangle",
                    x: "36.5%",
                    y: "66%",
                    width: "24.5%",
                    height: "6%"
                }
            }, {
                map: "tooltip",
                text: "Statistics \n Displays the Name, De/Buffs, Level, Experience, Health, Damage, Armor, and Kills of the selected Unit. Hovering over the different areas provides breakdowns of the stats and their calculations.",
                position: {
                    type: "coordinate",
                    shape: "rectangle",
                    x: "19.25%",
                    y: "72%",
                    width: "44.3%",
                    height: "27%"
                }
            }, {
                map: "tooltip",
                text: "Equipment \n Controls for the currently equipped Helmet, Weapon, Shield, Armor, also toggles visibility of the Inventory and current Gold.",
                position: {
                    type: "coordinate",
                    shape: "rectangle",
                    x: "63.5%",
                    y: "72%",
                    width: "14%",
                    height: "27%"
                }
            }, {
                map: "tooltip",
                text: "Menus \n Controls to bring up the: Help Menu, Voting Menu, and the Main Menu.",
                position: {
                    type: "coordinate",
                    shape: "rectangle",
                    x: "86.5%",
                    y: "69%",
                    width: "12.25%",
                    height: "6%"
                }
            }, {
                map: "tooltip",
                text: "Abilities \n Controls for the currently available abilities the player can employ, each with their own associated cost and cooldowns.",
                position: {
                    type: "coordinate",
                    shape: "rectangle",
                    x: "78.5%",
                    y: "77%",
                    width: "20%",
                    height: "20%"
                }
            }]
        }, {
            file: ImageAssets[5],
            type: FileType.Image,
            description: "Icons for weapons are displayed, showcasing: swords, polearms, wands, axes, bows and crossbows.",
            caption: "In Game view of Weapon Icons."
        }, {
            file: ImageAssets[6],
            type: FileType.Image,
            description: "Icons for armors are displayed, showcasing: robes, hide armors, metal armor and shields.",
            caption: "In Game view of Armor Icons."
        }, {
            file: ImageAssets[7],
            type: FileType.Image,
            description: "Icons for weapons are displayed, showcasing: garlic amulets, rings and potions of various potency.",
            caption: "In Game view of Item Icons."
        }, {
            file: ImageAssets[8],
            type: FileType.Image,
            description: "Icons for special abilities are displayed, showcasing: thunder, ice, lightning, fire, vision, death magic.",
            caption: "In Game view of Special Ability Icons."
        }, {
            file: VideoAssets[0],
            type: FileType.Video,
            description: "A trailer showing the various aspects of the gameplay and development.",
            caption: "Technical Demo Trailer for the game."
        }],
        contributions: [{
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Principal Game Designer",
                involvement: "Designed the Game world, put together documentation for the assets, logic, maps and world.",
                achievements: [
                    "Designed An Entire World with characters, setting, and story.",
                    "Created Game Design Documents.",
                    "Created Technical Design Documents.",
                    "Created Asset Design Documents."
                ]
            }]
        }, {
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Principal Level Designer",
                involvement: "Designed and built out a massive world for the game to take place.",
                achievements: [
                    "Built a full fleshed out map with unique locations that influence the gameplay.",
                    "Canyon section with low visbility and many linear paths.",
                    "Forest area with reduced visibility but many paths.",
                    "Plains for high visibility, great rewards but high risk for players caught in the open."
                ]
            }]
        }, {
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Principal Game Engineer",
                involvement: "Developed all of the games logic in accordance with the rules of the game.",
                achievements: [
                    "Engineered a feature rich and robust AI to take over for leaving players, complete with Personality types which listens to human player commands, for both Slayers and Vampires.",
                    "Built a Tower Defense style game where souls collected by Vampires are turned into zombies which attack the town which Slayers are trying to protect.",
                    "Developed custom logic for spells and abilities used by Vampires & Slayers",
                    "Utilized Galaxy Script to fullfil any logic which couldn't be natively supported by the Galaxy Engine."
                ]
            }]
        }]
    },
};

export default VampireHunterX;