import { ProjectAssets, ImageAssets, VideoAssets, DownloadAssets } from '@models';
import * as Image_01 from './Image_01.png';
import * as Image_02 from './Image_02.png';
import * as Image_03 from './Image_03.png';
import * as Image_04 from './Image_04.png';
import * as Image_05 from './Image_05.png';
import * as Image_06 from './Image_06.png';
import * as Image_07 from './Image_07.png';
import * as Image_08 from './Image_08.png';
import * as Image_09 from './Image_09.png';
import * as Image_10 from './Image_10.png';
import * as Image_11 from './Image_11.png';
import * as Image_12 from './Image_12.png';
import * as Image_13 from './Image_13.png';
import * as Image_14 from './Image_14.png';
import * as Image_15 from './Image_15.png';
import * as Image_16 from './Image_16.png';
import * as Image_17 from './Image_17.png';
import * as Image_18 from './Image_18.png';
import * as Image_19 from './Image_19.png';
import * as Image_20 from './Image_20.png';
import * as Download_076 from './DBZ LotSS Editor v0.76.zip';

export const Title: ImageAssets = {
    Title_01: Image_01
};

export const Images: ImageAssets = {
    Image_02,
    Image_03,
    Image_04,
    Image_05,
    Image_06,
    Image_07,
    Image_08,
    Image_09,
    Image_10,
    Image_11,
    Image_12,
    Image_13,
    Image_14,
    Image_15,
    Image_16,
    Image_17,
    Image_18,
    Image_19,
    Image_20
};

export const Videos: VideoAssets = {
    
};

export const Downloads: DownloadAssets = {
    Download_076
};

const DragonBallZLegendOfTheSuperSaiyanEditor: ProjectAssets = {
    Title,
    Images,
    Videos,
    Downloads
};

export default DragonBallZLegendOfTheSuperSaiyanEditor;