import React, { useEffect, useState } from "react";
import { ReactComponent } from "@models";

export const useChild = <T extends React.FunctionComponent>(children: React.ReactNode, type: T) : [ReactComponent[], React.ReactNode] => {
    const [matchedChildren, setMatchedChildren] = useState<ReactComponent[]>([]);
    const [filteredChildren, setFilteredChildren] = useState<React.ReactNode[]>([]);
    const length = React.Children.count(children);
    
    useEffect(() => {
        const matched = [] as ReactComponent[];
        const filtered = [] as React.ReactNode[];
        
        React.Children.forEach(children, (child) => {
            if(React.isValidElement(child)) {
                if((child as ReactComponent)?.type === type) {
                    matched.push(child as ReactComponent);
                } else {
                    filtered.push(child);
                }
            }
        });
    
        setMatchedChildren(matched);
        setFilteredChildren(filtered);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children, length]);
  
    return [matchedChildren, filteredChildren];
};