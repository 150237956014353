import { useState, useEffect, useRef, useCallback } from "react";
import { HandleUpdateModel, UserModel, UserRuntimeModel } from "@models";
import { getUser as getUserQuery } from "@app/graphql/queries";
import { createUser as createUserMutation, updateUser as updateUserMutation } from "@app/graphql/mutations";
import { GraphQLResult, useAuthentication, useGraphQL } from "./GraphQL";
import { useRemoteStorage } from "./RemoteStorage";
import { getFile, modelToDto } from "@utilities";
import { useNotifications } from "@components";

export const useUser = () => {
    const { authenticatedUser } = useAuthentication();
    const { apiCall } = useGraphQL();
    const { getFileUrl, createFile, deleteFile } = useRemoteStorage();
    const userLock = useRef(false);
    const [user, setUser] = useState({} as UserModel);
    const { enqueueUpdateObject, enqueueError } = useNotifications();

    const createUser = useCallback(async function internalCreateUser() {
        const data = {
            id: authenticatedUser.username,
            name: "Anonymous" + Math.floor(Math.random() * 100 + 1)
        };
        await apiCall(createUserMutation, { input: data });
    }, [apiCall, authenticatedUser]);
    
    const getUser = useCallback(async function internalFetchUser() {
        if(!userLock.current) {
            userLock.current = true;
            if(!authenticatedUser) {
                if(user?.createdAt) {
                    setUser({} as UserModel);
                }
                return;
            }
            const apiData = await apiCall(getUserQuery, { id: authenticatedUser.username }) as GraphQLResult<any>;
            let userFromAPI = (apiData?.data?.getUser) as UserModel;
            if(!userFromAPI)
            {
                await createUser();
                await internalFetchUser();
                return;
            }
            if(userFromAPI?.icon) userFromAPI.iconURL = await getFileUrl(userFromAPI.icon);
            setUser(userFromAPI);
        } else {
            userLock.current = false;
        }
    }, [authenticatedUser, apiCall, getFileUrl, user?.createdAt, createUser]);

    const updateUser = useCallback(async function internalUpdateUser(event: HandleUpdateModel<UserModel>) {
        const newProfile = event.data;
        if(!user?.id) return "User doesn't exist";
            try {
            const requestObject = modelToDto<UserModel, keyof UserRuntimeModel>(newProfile);
            await apiCall(updateUserMutation, { input: requestObject });
            if (!!newProfile.icon && !!newProfile.iconFile) {
                await createFile(newProfile.icon, newProfile.iconFile);
            }
            else if(user.icon && newProfile.icon === null) {
                deleteFile(user.icon);
            }
            setUser(newProfile);

            enqueueUpdateObject({
                userName: user.name,
                userIcon: getFile(user.icon || "Default")
            }, {
                userName: newProfile.name,
                userIcon: newProfile?.iconFile?.name || "Default"
            });
        
            return "";
        } catch (error) {
            return enqueueError(error);
        }
    }, [apiCall, createFile, deleteFile, enqueueError, enqueueUpdateObject, user.icon, user?.id, user.name]);

    useEffect(() => {
        if(user?.id !== authenticatedUser?.username) {
            getUser();
        }
    }, [getUser, user, authenticatedUser]);

    return {
        createUser,
        getUser,
        updateUser,
        user,
        setUser,
    };
};