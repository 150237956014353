import { ProjectAssets, ImageAssets, VideoAssets } from '@models';
import * as Image_01 from './Image_01.jpg';
import * as Image_02 from './Image_02.jpg';
import * as Title_01 from './Title_01.jpg';

export const Title: ImageAssets = {
    Title_01
};

export const Images: ImageAssets = {
    Image_01,
    Image_02,
};

export const Videos: VideoAssets = {
    
};

const WhereDidIPutThat: ProjectAssets = {
    Title,
    Images,
    Videos
};

export default WhereDidIPutThat;