import { useEffect } from "react";

export const useKeyboardPress = (onKeyPress: (event: KeyboardEvent) => void) => {
    useEffect(() => {
        const keyPress = (event: KeyboardEvent) => {
            onKeyPress?.(event);
        };

        document.body.addEventListener("keyup", keyPress)

        return () => {
            document.body.removeEventListener("keyup", keyPress);
        }
    }, [onKeyPress]);
};