import { useState, createRef, useEffect } from 'react';
import { ComponentProps } from '@models/types';
import { Document as DocumentViewer, Page as DocumentPage, pdfjs } from 'react-pdf';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './Document.css';
import { saveAs } from 'file-saver';
import Button from './Button';
import { publicFolder, waitForElement, clamp, useKeyboardPress, useRenderOnResize } from '@app/utilities';

pdfjs.GlobalWorkerOptions.workerSrc = publicFolder("pdf.worker.min.js");

export type SaveDocumentCallback = Promise<Uint8Array>;

export type PostProcessCallback = (element: HTMLElement) => void;

export type DocumentProps = ComponentProps & {
    file: string,
    width?: number,
    height?: number,
    postProcess?: PostProcessCallback
};

export const Document = ({file, width, height, postProcess, className, style, children}: DocumentProps) => {
    const [pageCount, setPageCount] = useState(0);
    const [pageIndex, setPageIndex] = useState(1);
    const [saveDocument, setSaveDocument] = useState<SaveDocumentCallback | null>(null);
    const container = createRef<HTMLDivElement>();

    useRenderOnResize();
    useKeyboardPress((event) => {
        switch (event.key) {
            case "ArrowLeft":
                handlePrevious();
                break;
            case "ArrowRight":
                handleNext();
                break;
        }
    });

    const canPrevious = () => {
        return pageIndex > 1;
    };

    const handlePrevious = () => {
        if(canPrevious()) setPageIndex(pageIndex - 1);
    };

    const canNext = () => {
        return pageIndex < pageCount;
    };

    const handleNext = () => {
        if(canNext()) setPageIndex(pageIndex + 1);
    };
  
    const handleLoadSuccess = (pdf: PDFDocumentProxy) => {
        setPageCount(pdf.numPages);
        setSaveDocument(pdf.getData.bind(pdf));
    };

    const handleLoadError = (error: Error) => {
        // Known issue: https://github.com/wojtekmaj/react-pdf/issues/1062
        // Doesn't affect the rendering of the PDF.
        if(error.message === "Worker was destroyed") {
            return;
        }

        console.error(error);
    };

    useEffect(() => {
        if(pageCount && postProcess) {
            const onPostProcess = async () => {
                if(container.current) {
                    const element = (await waitForElement(".textLayer span", container.current) as HTMLElement)?.parentElement;
                    if(element) {
                        Array.from(element.querySelectorAll('span')).forEach(postProcess);
                    }
                }
            };
            onPostProcess();
        }
    }, [container, pageCount, postProcess]);

    const exportDocument = async () => {
        if(saveDocument) {
            const document = await saveDocument;
            const documentBlob = new Blob([document], { type: 'application/pdf' });
            const browser = (window as any);

            // if the browser supports file picking: allow them to pick the location
            if(browser.showSaveFilePicker) {
                const pickerOptions = {
                    suggestedName: file,
                    types: [{
                        description: file,
                        accept: {
                          'application/pdf': ['.pdf']
                        },
                    }],
                };
                
                const fileHandle = await browser.showSaveFilePicker(pickerOptions);
                const writableFileStream = await fileHandle.createWritable();
                await writableFileStream.write(documentBlob);
                await writableFileStream.close();
            // Otherwise just use fileSaver.js for the greatest compatibility
            } else {
                saveAs(documentBlob, file);
            }
        }
    };

    const viewport = document.documentElement;
    width = Math.min(width || 620);
    height = Math.min(height || 792);
    const scale = clamp((viewport.clientWidth - 40) / width, 400 / width, 1.0);
    
    return (
      <div className={className} style={style}>
        <p>
            <span className="super-font-container"><label className="super-font">FileName:</label>{file}</span>
            <span>&nbsp;</span>
            <Button loading={!pageCount} onClick={exportDocument}>Download</Button>
        </p>
        <div className="document-container" ref={container}>
            <DocumentViewer
                file={publicFolder(file)} 
                onLoadSuccess={handleLoadSuccess}
                onLoadError={handleLoadError}
                className="document-viewer select-text"
                loading={<span>Loading PDF...</span>}
                error={<span>Failed to load PDF file.</span>}
            >
                <DocumentPage
                    width={width}
                    height={height}
                    pageNumber={pageIndex}
                    loading={<span>Loading PDF page...</span>}
                    error={<span>Failed to load PDF page.</span>}
                    scale={scale}
                />
            </DocumentViewer>
        </div>
        <p className="document-controls">
            <Button onClick={handlePrevious} disabled={!canPrevious()}><span style={{rotate: "-90deg"}}>↑</span></Button>
            <span className="page-state"> Page {pageCount ? pageIndex : "?"} of {pageCount || "?"} </span>
            <Button onClick={handleNext} disabled={!canNext()}><span style={{rotate: "90deg"}}>↑</span></Button>
        </p>
        {children}
      </div>
    );
};

export default Document;