import { withTooltip } from "@components/Tooltip";
import { ComponentProps } from "@models/types";

export type ToolbarButtonProps = ComponentProps & {
    module: string
};

export const ToolbarButton = withTooltip(({module, className, style, children}: ToolbarButtonProps) => {
    return (
        <button style={style} className={classNames("ql-" + module, "ql-custom", className)}>
            {children}
        </button>
    );
});

export default ToolbarButton;
