import React from 'react';
import { ComponentProps } from '@models/types';
import { withTooltip } from '@components/Tooltip';

export enum DividerVariant {
  Thick = "golden",
  Basic = ""
};

export type DividerProps = ComponentProps & {
  variant?: DividerVariant,
};

export const Divider = withTooltip(({variant = DividerVariant.Thick, className, style, children} : DividerProps) => {
  return (
    <hr className={`${className} ${variant}`} style={{width: "100%", ...style}}>
      {children}
    </hr>
  );
}, { block: true });

export default Divider;