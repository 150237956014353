import { AbscissionOfLight as AOL } from './AbscissionOfLight';
import { CetraTools as CT} from './CetraTools';
import { ChuuninExamsForestOfDeath as CEFOD} from './ChuuninExamsForestOfDeath';
import { DragonBallZLegendOfTheSuperSaiyanNDS as DBZLOTSSNDS } from './DragonBallZLegendOfTheSuperSaiyanNDS';
import { DragonBallZLegendOfTheSuperSaiyanEditor as DBZLOTSSE } from './DragonBallZLegendOfTheSuperSaiyanEditor';
import { DragonBallZRagingPride as DBZRP } from './DragonBallZRagingPride';
import { DropChat as DC } from './DropChat';
import { DiabloIV as D4 } from './DiabloIV';
import { FinalFantasy8SleepingLionheart as FF8SLH } from './FinalFantasy8SleepingLionheart';
import { Makonoid as MN } from './Makonoid';
import { PaperFlyerAdventures as PFA }  from './PaperFlyerAdventures'
import { PatchInfused as PI } from './PatchInfused';
import { RPGMakerVXBattleSystem as RPGVXBS } from './RPGMakerVXBattleSystem';
import { ScreenSnap as SS } from './ScreenSnap';
import { SleepTimer as ST } from './SleepTimer';
import { SnakeClone as SC } from './SnakeClone';
import { SpySki as ASATS } from './SpySki';
import { SuperFightingRobot as SFR } from './SuperFightingRobot';
import { VampireHunterX as VHX } from './VampireHunterX';
import { WhereDidIPutThat as WDIPT } from './WhereDidIPutThat';

export const AbscissionOfLight = AOL;
export const CetraTools = CT;
export const ChuuninExamsForestOfDeath = CEFOD;
export const DragonBallZLegendOfTheSuperSaiyanNDS = DBZLOTSSNDS;
export const DragonBallZLegendOfTheSuperSaiyanEditor = DBZLOTSSE;
export const DragonBallZRagingPride = DBZRP;
export const DropChat = DC;
export const DiabloIV = D4;
export const FinalFantasy8SleepingLionheart = FF8SLH;
export const Makonoid = MN;
export const PaperFlyerAdventures = PFA;
export const PatchInfused = PI;
export const RPGMakerVXBattleSystem = RPGVXBS;
export const ScreenSnap = SS;
export const SleepTimer = ST;
export const SnakeClone = SC;
export const SpySki = ASATS;
export const SuperFightingRobot = SFR;
export const VampireHunterX = VHX;
export const WhereDidIPutThat = WDIPT;

export const Data = [
    AbscissionOfLight,
    CetraTools,
    ChuuninExamsForestOfDeath,
    DragonBallZLegendOfTheSuperSaiyanNDS,
    DragonBallZLegendOfTheSuperSaiyanEditor,
    DragonBallZRagingPride,
    DropChat,
    DiabloIV,
    FinalFantasy8SleepingLionheart,
    Makonoid,
    PaperFlyerAdventures,
    PatchInfused,
    RPGMakerVXBattleSystem,
    ScreenSnap,
    SleepTimer,
    SnakeClone,
    SpySki,
    SuperFightingRobot,
    VampireHunterX,
    WhereDidIPutThat
];

export default Data;
