import { ComponentProps } from "@models/types";
import { publicFolder } from "@app/utilities";

export const Logo = ({className, style}: ComponentProps) => {
    return (
        <a className="does-glow" href="/">
            <img
                className={className}
                style={style}
                alt={`Pixel Dino`}
                src={publicFolder("logo192.png")}
                width={24}
                height={24}
            />
        </a>
    );
};

export default Logo;
