import { isEqual, isEmpty } from 'lodash';
import { useSearchParams } from "react-router-dom";
import { useQueryParameterContext } from '@components/QueryParameters';

export const useQueryParameters = <T extends any | null>() => {
    const [params, setParams] = useSearchParams();
    const [allOptions, setAllOptions, initialParams, setInitialParams] = useQueryParameterContext();

    const getInitialParamsForQuery = (queryIdentifier: string) => {
        return initialParams[queryIdentifier];
    };

    const setInitialParamsForQuery = (queryIdentifier: string, data: T) => {
        if(!isEmpty(queryIdentifier) && !isEmpty(data)) {
            const initialValue = getInitialParamsForQuery(queryIdentifier);
            if(!isEqual(data, initialValue)) {
                setInitialParams(({...initialParams, [queryIdentifier]: data}));
                if(!allOptions[queryIdentifier]) {
                    setAllOptions({...allOptions, [queryIdentifier]: data});
                }
            }
        }
    };

    const getParamsFromQuery = (queryIdentifier: string) => {
        const queryParams = params.get(queryIdentifier);
        if(queryParams) {
            try {
                return JSON.parse(queryParams) as T;
            } catch (error) {
                console.error(error);
                return null;
            }
        }
        return null;
    };

    const setParamsForQuery = (queryIdentifier: string, data: T) => {
        const initialValue = getInitialParamsForQuery(queryIdentifier);
        if(isEmpty(data) || isEqual(data, initialValue)) {
            params.delete(queryIdentifier);
        } else {
            params.set(queryIdentifier, JSON.stringify(data));
        }
        setParams(params);
    };

    return [
        getParamsFromQuery,
        setParamsForQuery,
        getInitialParamsForQuery,
        setInitialParamsForQuery,
        allOptions
    ] as [
        typeof getParamsFromQuery,
        typeof setParamsForQuery,
        typeof getInitialParamsForQuery,
        typeof setInitialParamsForQuery,
        { [key: string]: T }
    ];
};

export const useQueryParameter = <T extends any | null>(name: string, defaultValue?: T) => {
    const [getParamsFromQuery, setParamsForQuery, getInitialParamsForQuery, setInitialParamsForQuery] = useQueryParameters();

    if(!isEmpty(defaultValue)) {
        setInitialParamsForQuery(name, defaultValue);
    }

    const initialParams = getInitialParamsForQuery(name);
    const newParams = getParamsFromQuery(name) || initialParams;
    const setNewParams = (newValue: T) => {
        if(!isEqual(newParams, newValue)) {
            setParamsForQuery(name, newValue);
        }
    };

    return [newParams, setNewParams] as [T, typeof setNewParams];
};