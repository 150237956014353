import { ComponentProps } from '@models';
import { Divider, Heading } from '@app/components';
import { toTitleCase } from '@utilities';

export type SectionProps = ComponentProps & {
  name: string,
  title?: string,
  label?: boolean
};

export const Section = ({name, title, label, className, style, children} : SectionProps) => {
    if(title == null && label) title = toTitleCase(name);
    const hasTitle = title || label;

    return (
        <div
          id={"section-" + name}
          className={classNames("section", className)}
          style={{...(hasTitle ? {} : { marginTop: 20 }), ...{ marginBottom: 20 }, ...style}}
        >
          {hasTitle && <Heading level={3}>{title}</Heading>}
          <div id={`anchor-section-${name}`}></div>
          <div className={"section-content"}>
            {children}
          </div>
          <Divider />
        </div>
    );
};

export default Section;