import { ComponentProps, InputComponentProps } from '@models';
import {
    Button,
    ButtonVariant,
    OnClickType
} from '@components';

export type NavigationItemProps = ComponentProps & InputComponentProps & {
    link?: string,
    aliases?: string[],
    onClick?: OnClickType
};

export const NavigationItem = ({link, aliases, onClick, className, style, children, ...rest}: NavigationItemProps) => {
    const active = [link, ...(aliases || [])].includes(window.location.pathname) ? "active" : "";
    return (
        <Button
            className={classNames(className, "navigation-item", active)}
            style={style}
            variant={ButtonVariant.Link}
            onClick={onClick}
            {...rest}
        >
            {link ? (
                <a href={link} onClick={() => onClick?.({} as React.MouseEvent<HTMLButtonElement>)}>
                    {children}
                </a>
            ) : (
                children
            )}
        </Button>
    );
};

export default NavigationItem;