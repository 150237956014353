import {
    Document,
    Page,
    Section
} from '@app/components';
import { Flex } from "@aws-amplify/ui-react";

export type ResumeProps = {

};

export const Resume = () => {
    const handlePostProcess = (element: HTMLElement) => {
        if(element.textContent?.includes("@")) {
            const email = element.textContent.match(/([^"<>]*@.*?[^"<>]*)/)?.[0];
            element.innerHTML = `<a href="mailto:${email}">${email}</a>`;
            element.classList.add("rpgui-cursor-point");
        } else {
            element.classList.add("rpgui-cursor-select");
        }
        if(element.textContent === "") {
            element.textContent = "•";
        }
    };

    return (
        <Page name="resume">
            <Section name="content">
                <Flex alignItems="center" justifyContent="center">
                    <Flex direction="row">
                        <Flex direction="column">
                            <Document file="Dino_Suvalic_Resume.pdf" postProcess={handlePostProcess}/>
                        </Flex>
                    </Flex>
                </Flex>
            </Section>
        </Page>
    );
};

export default Resume;
