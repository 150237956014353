import { GenericCallback } from "@models";
import { useEffect } from "react";
import useForceUpdate from "./ForceUpdate";

export type OnResizeOptions = {
    initialize?: boolean
};

export const useRenderOnResize = (onResize?: GenericCallback<void>, options?: OnResizeOptions) => {
    const forceRender = useForceUpdate();

    useEffect(() => {
        const resize = () => {
            forceRender();
            onResize?.();
        };

        window.addEventListener("resize", resize);
        if(options?.initialize) {
            onResize?.();
        }

        return () => {
            window.removeEventListener("resize", resize);
        }
    }, [forceRender, onResize, options]);
};

export default useRenderOnResize;
