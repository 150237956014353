import { ComponentProps } from '@models/types';
import {
    Navigation,
    NavigationItems,
    ScrollbarToolbar
} from '@app/components';

export type SiteProps = ComponentProps & {
    navigation?: NavigationItems
};

export const Site = ({navigation, className, style, children}: SiteProps) => {
    return (
        <div
            className={classNames("App rpgui-container framed rpgui-content", className)}
            style={{...style, overflow: "unset", imageRendering: "pixelated"}}
        >
            <Navigation navigation={navigation} />
            <ScrollbarToolbar />
            <br />
            {children}
        </div>
    );
};

export default Site;
