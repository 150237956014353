import React from "react";
import { Flex } from '@aws-amplify/ui-react';
import { ComponentProps } from '@models/types';
import { Icon } from './Icon';

export enum SpinnerType {
    Gear = "gear",
    Blocks = "blocks"
};

export type SpinnerProps = ComponentProps & {
    loading: boolean,
    type?: SpinnerType,
};

export const Spinner = ({loading, type = SpinnerType.Gear, className, style, children} : SpinnerProps) => {
    const Blocks = (size: number) => {
        const x = size;
        const y = size;
        const px = (value: number) => value + 'px';
        const width = px(x);
        const height = px(y);
        const totalWidth = px(x * 4 + 4);
        const totalHeight = px(y * 4 + 4);
        return (
            <div className="loading-blocks" style={{width: totalWidth, height: totalHeight}}>
                <div className="loading-blocks-container">
                    <div style={{width, height, left:px(x-2), top:px(y-2), animationDelay:'0s'}}></div>
                    <div style={{width, height, left:px(x*2), top:px(y-2), animationDelay:'0.125s'}}></div>
                    <div style={{width, height, left:px(x*3+2), top:px(y-2), animationDelay:'0.25s'}}></div>
                    <div style={{width, height, left:px(x*3+2), top:px(y*2), animationDelay:'0.375s'}}></div>
                    <div style={{width, height, left:px(x*3+2), top:px(y*3+2), animationDelay:'0.5s'}}></div>
                    <div style={{width, height, left:px(x*2), top:px(y*3+2), animationDelay:'0.625s'}}></div>
                    <div style={{width, height, left:px(x-2), top:px(y*3+2), animationDelay:'0.75s'}}></div>
                    <div style={{width, height, left:px(x-2), top:px(y*2), animationDelay:'0.875s'}}></div>
                </div>
            </div>
        )
    };

    return (
        <Flex display="inline-flex" className={className} alignItems="center" style={{...style, height: "100%"}}>
            { loading && (type === SpinnerType.Gear ? <Icon icon="settings" className={"slow-spin"} /> : Blocks(6))}
            {children}
        </Flex>
    );
};