import { Chip } from '@mui/material';
import { ComponentProps, GenericCallback } from '@models';
import { hoverBackgroundColor, useBorder } from '@app/components';
import { withTooltip } from '@components/Tooltip';

export enum GrowType {
    None,
    Inner,
    Outer
}

export type TermProps = ComponentProps & {
    option: string,
    setOption: GenericCallback<string, GenericCallback<any>>,
    active?: boolean,
    disabled?: boolean,
    noPadding?: boolean,
    grow?: GrowType,
    seeThrough?: boolean,
    width?: number
};

export const Term = withTooltip(({option, setOption, active, disabled, noPadding, grow, seeThrough, width, className, style, children}: TermProps) => {
    const internalClassName = "nes-container is-thin is-rounded rpgui-cursor-point rpgui-tag";
    const borderColor = useBorder("yellow");

    const term = (
        <Chip
            key={option}
            className={classNames(
                internalClassName,
                className,
                noPadding ? "propogate-size" : "",
                active ? borderColor : "", (!width || noPadding) ? "no-padding" : ""
            )}
            style={{
                ...{ borderColor: "transparent", padding: 0.8 },
                ...(width ? { width } : { }),
                flexGrow: grow === GrowType.Inner ? 1 : "inherit",
                ...style
            }}
            label={children}
            disabled={disabled}
            onClick={setOption(option)}
            sx={{
                ...hoverBackgroundColor()
            }}
            classes={{root: seeThrough ? "see-through-background" : ""}}
        />
    );

    if(grow === GrowType.Outer) {
        return (
            <div style={{flexGrow: 1}}>
                {term}
            </div>
        );
    }

    return term;
});

export default Term;
