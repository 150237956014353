import { useState, useEffect, useCallback } from "react";
import {
    CommentModel,
    UserModel,
    SortDirection,
    HandleCreateModel,
    CreateNoteModel,
    HandleDeleteModel
} from "@models";
import { getFile, toTitleCase } from "@utilities";
import { useNotifications } from "@components";
import { notesByDate } from "@app/graphql/queries";
import { fetchNotesCount } from "@app/graphql/custom-queries";
import {
  createNote as createNoteMutation,
  deleteNote as deleteNoteMutation,
} from "@app/graphql/mutations";
import { GraphQLResult, useAuthentication, useGraphQL } from "./GraphQL";
import { useRemoteStorage } from "./RemoteStorage";
import { useUser } from "./User";
import { isEmpty } from "lodash";

export const useComments = (type?: string) => {
    const [comments, setComments] = useState([] as CommentModel[]);
    const { user } = useUser();
    const { signedIn } = useAuthentication();
    const { getFileUrl } = useRemoteStorage();
    const { enqueueCreateObject, enqueueDeleteObject } = useNotifications();
    const { apiCall } = useGraphQL();

    const [totalComments, setCommentsTotal] = useState(0);
    const [token, setToken] = useState<string | null>(null);
    const [commentsPerPage, setCommentsPerPage] = useState(2);

    const loadMoreComments = () => {
        fetchComments(true);
    };
    
    const handleSetCommentsPerPage = (event: React.SyntheticEvent<HTMLSelectElement>) => {
        setCommentsPerPage(typeof event.currentTarget.value === 'string' 
        ? parseInt(event.currentTarget.value, 10)
        : event.currentTarget.value);
    };  
    
    const defaultCommentsName = () => {
        return getFile(window.location.pathname);
    };
    
    const commentsName = type || toTitleCase(defaultCommentsName());
    
    const countComments = useCallback(async function internalCountComments() {
        const apiData = commentsName ? await apiCall(fetchNotesCount, { name: commentsName }) as GraphQLResult<any> : undefined;
        if(!apiData) {
            if(totalComments !== 0) {
                setCommentsTotal(0);
            }
            return;
        }
        const notesCountFromAPI = (apiData.data?.notesByDate?.scannedCount || apiData.data?.notesByDate?.count) as number || 0;
        setCommentsTotal(notesCountFromAPI);
    }, [apiCall, commentsName, totalComments]);
    
    const fetchComments = useCallback(async function internalFetchComments(add?: boolean) {
        await countComments();
        const apiData = commentsName ? await apiCall(notesByDate, { 
            name: commentsName,
            limit: commentsPerPage,
            nextToken: add ? token : null,
            sortDirection: SortDirection.Ascending
        }) as GraphQLResult<any> : undefined;
        if(!apiData) {
            if(!isEmpty(comments)) {
                setToken(null);
                setComments([]);
            }
            return;
        }
        const notesFromAPI = (apiData.data?.notesByDate?.items || []) as CommentModel[];
        await Promise.all(
            notesFromAPI.map(async (note) => {
                if (note.user?.icon) {
                    const url = await getFileUrl(note.user.icon);
                    (note.user as UserModel).iconURL = url;
                }
                return note;
            })
        );
        setToken(apiData.data?.notesByDate?.nextToken);
        setComments(add ? [...comments, ...notesFromAPI] : notesFromAPI);
    }, [countComments, apiCall, commentsName, commentsPerPage, token, comments, getFileUrl]);
    
    useEffect(() => {
        fetchComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signedIn, commentsName]);
    
    async function createComment(event : HandleCreateModel<CreateNoteModel>) {
        const data = {
            name: commentsName,
            description: event.data.description,
            noteUserId: user?.id
        };
        await apiCall(createNoteMutation, { input: data });
        fetchComments();
        event.reset();
        const { name, description } = data;
        enqueueCreateObject("Comment Added", { name, description });
    };
    
    async function deleteComment(event: HandleDeleteModel<CommentModel>) {
        const { id } = event.data;
        const deletedNote = comments.find((note) => note.id === id);
        if(deletedNote) {
            const newNotes = comments.filter((note) => note.id !== id);
            await apiCall(deleteNoteMutation, { input: { id } });
            setComments(newNotes);
            setCommentsTotal(totalComments-1);
            const { name, description } = deletedNote;
            enqueueDeleteObject(`Comment Deleted`, { name, description });
        }
    };

    return {
        comments,
        totalComments,
        loadMoreComments,
        createComment,
        deleteComment,
        commentsPerPage,
        setCommentsPerPage: handleSetCommentsPerPage,
    };
};