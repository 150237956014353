import React from 'react';
import { ComponentProps } from '@models/types';
import { Scrollbars as CustomScrollbars } from 'react-custom-scrollbars-2';

export type ScrollbarsProps = ComponentProps & {
  width?: number,
  height?: number,
};

export const Scrollbars = ({width, height, className, style, children} : ScrollbarsProps) => {
  return (
    <CustomScrollbars
      className={className}
      style={{display: "flex", width: width ? width + "px" : "100%", height: height ? height + "px" : "inherit", ...style}}
      renderTrackHorizontal={(props) => <div {...props} className="scrollbar-track track-horizontal rpgui-cursor-point" />}
      renderTrackVertical={props => <div {...props} className="scrollbar-track track-vertical rpgui-cursor-point" />}
      renderThumbVertical={props => <div {...props} className="scrollbar-thumb thumb-vertical rpgui-cursor-grab" />}
      renderThumbHorizontal={props => <div {...props} className="scrollbar-thumb thumb-vertical rpgui-cursor-grab" />}
      renderView={props => <div {...props} className="scrollbar-view" />}
    >
      {children}
    </CustomScrollbars>
  );
};

export default Scrollbars;