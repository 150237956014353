import { ProjectData, Tags, Languages, Software, ProjectStatus, Products, Contributors, OrientationType, OtherSoftware } from '@structures/ProjectData';
import { Title, Images } from '@projects/Dragon Ball Z - Legend of the Super Saiyan NDS';
import { FileType } from '@models';

export const ImageAssets = Object.values(Images);

export const DragonBallZLegendOfTheSuperSaiyanNDS: ProjectData = {
    summary: {
        id: "DBZLOTSSNDS",
        title: "Dragon Ball Z: Legend Of The Super Saiyan NDS",
        orientation: OrientationType.Portrait,
        aliases: ["DBZ LOTSS NDS"],
        filter: { settings: { pixelSize: 1 }},
        version: 0.7,
        status: ProjectStatus.Unreleased,
        initial: new Date(2017, 4, 1),
        release: new Date(2017, 9, 1),
        previews:[{
            file: Title.Title_01,
            type: FileType.Image,
            description: "This is the initial landing screen of the game after starting it",
            caption: `In Game view of the initial starting map with Goku and friends.`
        }],
        tags: {
            [Tags.Products]: [
                Products.Games
            ],
            [Tags.Software]: [
                Software.VS,
                OtherSoftware.DevKit
            ],
            [Tags.Languages]: [
                Languages.C
            ]
        },
        description: "A port of the Super-Nintendo game to the Nintendo DS."
    },
    details: {
        description: "A game which takes the original Super-Nintendo game and enhances it for modern consoles."
            + "<br/><br/>"
            + "It is built in pure C and is performant, as well as feature rich, bringing back all of the loved aspects of the original."
            + "<br/><br/>"
            + "The menu system was reworked to make use of the dual screens of the Nintendo DS!",
        gallery: [{
            file: ImageAssets[0],
            type: FileType.Image,
            description: "An in game image of the players starting party on the earth world map.",
            caption: "The starting party on the earth world map, with the main menu visible."
        }, {
            file: ImageAssets[1],
            type: FileType.Image,
            description: "An in game image showing Goku inside the Kame house on the bottom screen, while the player is viewing his list of skills on the top screen.",
            caption: "Goku inside of Kame house, reviewing his list of skills."
        }, {
            file: ImageAssets[2],
            type: FileType.Image,
            description: "An in game image showing Goku flying on the world map on the bottom screen, while the player is viewing the main menu on the top screen.",
            caption: "Goku & Piccolo flying around on the world map."
        }, {
            file: ImageAssets[3],
            type: FileType.Image,
            description: "An in game image showing Goku about to fight Frieza utilizing the turn based battle system.",
            caption: "Goku & Piccolo about to fight Frieza in the battle system."
        }],
        contributions: [{
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Game Engineer",
                involvement: "Developed all of original games systems inside of the NDS using C.",
                achievements: [
                    "Developed a simple, but fully featured RPG Engine complete with turn based battles on the NDS.",
                    "Developed an object management system in C which enables Object Oriented programming.",
                    "Developed optimized calls to the ARM Processor and draw calls which transferred graphics to GPU buffer.",
                    "Developed menu system which stays true to the original game, while enhancing some capabilities.",
                    "Developed a robust animation system, capable of displaying sequenced animations in many different styles, timings, positions, rotations and scales."
                ]
            }]
        }]
    },
};

export default DragonBallZLegendOfTheSuperSaiyanNDS;