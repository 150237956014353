import { ComponentProps } from '@models/types';

export type SocialProps = ComponentProps & {
  type: string,
  theme?: string,
  link: URL
};

export const Social = ({type, theme = "nes", link, className, style, children} : SocialProps) => {
  return (
    <a href={link.href} className={"does-glow"} target="_blank" rel="noreferrer">
        <i 
          className={classNames(theme === "nes" ? `nes-icon ${type} is-small` : `rpgui-icon ${type} md`, className)} 
          style={style}
        >
        </i>
        {children}
    </a>
  );
};

export default Social;