import { ComponentProps } from '@models/types';
import Social from './Social';

export type DiscordProps = ComponentProps & {
  inviteId: string
};

export const Discord = ({inviteId, className, style, children} : DiscordProps) => {
  return (
    <Social
      className={className}
      style={style}
      type={"discord"}
      theme={"rpg"}
      link={new URL(`https://discord.gg/${inviteId}`)}
    >
      {children}
    </Social>
  );
};

export default Discord;