import { ComponentProps } from '@models/types';
import Social from './Social';

export type LinkedInProps = ComponentProps & {
  username: string
};

export const LinkedIn = ({username, className, style, children} : LinkedInProps) => {
  return (
    <Social
      className={className}
      style={style}
      type={"linkedin"}
      link={new URL(`https://www.linkedin.com/in/${username}`)}
    >
      {children}
    </Social>
  );
};

export default LinkedIn;