import { ProjectData, Tags, Languages, Software, ProjectStatus, Products, Contributors } from '@structures/ProjectData';
import { Videos } from '@projects/Abscission of Light';
import { FileType } from '@models';

export const VideoAssets = Object.values(Videos);

export const AbscissionOfLight: ProjectData = {
    summary: {
        id: "AOL",
        title: "Abscission of Light",
        aliases: ["AOL"],
        filter: { settings: { pixelSize: 3 }},
        version: 1.0,
        status: ProjectStatus.Completed,
        initial: new Date(2012, 8, 1),
        release: new Date(2012, 11, 31),
        tags: {
            [Tags.Products]: [
                Products.Games
            ],
            [Tags.Software]: [
                Software.Unreal
            ],
            [Tags.Languages]: [
                Languages.CPlus
            ]
        },
        description: "A game featuring a female hero trapped in the desert seeking to find & save her father!"
    },
    details: {
        description: "A game featuring a female protagonist trapped in the desert."
            + "<br/><br/>"
            + "She's looking for a way to free her father by unlocking the mysteries of a temple she discovered."
            + "<br/><br/>"
            + "She quickly finds out that the temple is full of: secrets, monsters, mazes, and man-eating minotaurs!",
        gallery: [{
            file: VideoAssets[0],
            type: FileType.Video,
            description: "A trailer showing the various aspects of the gameplay and story.",
            caption: "Story & Gameplay Demo Trailer for the game."
        }],
        contributions: [{
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Game Programmer",
                involvement: "Developed all of the games logic in accordance with the rules of the game.",
                achievements: [
                    "Developed a magic spell and casting system.",
                    "Developed a melee combat system.",
                    "Developed many features to support the game using Kismet: such as key collecting and door unlocking.",
                    "Developed a complex AI for scripted Boss sequences using a finite-state machine."
                ]
            }]
        }]
    },
};

export default AbscissionOfLight;