import { createRef, useEffect } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Dialog, DialogPreload } from "@components/Dialog";
import { ComponentProps } from "@app/models/types";
import { waitForElement } from "@utilities";
import { useAuthentication } from "@services";

export type LoginProps = ComponentProps & {
  open: boolean,
  onClose?: () => void
};

const Authenticator = () => {
  return (
    <></>
  );
};

export const Login = ({open, onClose, className, style, children} : LoginProps) => {
  const { signedIn } = useAuthentication();

  useEffect(() => {
    if(open && signedIn) {
      onClose?.();
    }
  }, [onClose, open, signedIn])

  const replaceSvg = (container: HTMLElement, icon: string, disabled?: boolean, size?: string) => {
    const svg = container.querySelector("svg");
    if(svg) {
      const div = document.createElement("div");
      div.classList.add("rpgui-icon");
      if(icon) div.classList.add(icon);
      if(size) div.classList.add(size);
      if(disabled) div.classList.add("disabled");
      svg.replaceWith(div);
    }
  };

  const Header = () => {
    const header = createRef<HTMLDivElement>();

    function onNextFrame(callback: FrameRequestCallback) {
      setTimeout(function () {
          requestAnimationFrame(callback)
      });
    };

    useEffect(() => {
      const root = document.querySelector(".MuiDialog-root");
      if(root) {
        const refreshPrimaryButton = () => {
          root.querySelectorAll(`button[data-variation="primary"]`).forEach(x => {
            x.classList.add("rpgui-button");
            x.classList.add("golden");
            x.classList.add("lg");
          });
        };
        refreshPrimaryButton();
        root.querySelectorAll(`button[role="switch"]`).forEach(x => {
          x.classList.add("rpgui-button");
          x.classList.add("display-inline-flex");
        });
        root.querySelectorAll(`button[role="tab"]`).forEach(x => {
          x.classList.add("button-link");
        });
        root.querySelectorAll(`button[data-variation="link"]`).forEach(x => {
          x.classList.add("button-link");
        });
        root.querySelectorAll(`div[data-amplify-router=""]`).forEach(x => {
          x.classList.add("rpgui-container");
          x.classList.add("framed-grey");
        });
        root.querySelectorAll(`button.amplify-field__show-password`).forEach(x => {
          const button = x as HTMLButtonElement;
          button.onclick = () => {
            onNextFrame(replaceVisibilitySvg);
          };
          const replaceVisibilitySvg = () => {
            replaceSvg(x as HTMLElement, "reveal", button.getAttribute("aria-checked") === 'true');
          };
          replaceVisibilitySvg();
        });
        waitForElement(".amplify-alert--error", root as HTMLElement).then((result) => {
          refreshPrimaryButton();
          const alert = result as HTMLElement;
          alert.querySelectorAll(`.amplify-alert .amplify-alert__icon`).forEach(x => {
            const replaceInfoSvg = () => {
              replaceSvg(x as HTMLElement, "shield", false, "sm");
            };
            replaceInfoSvg();
          });
          alert.querySelectorAll(`button`).forEach(x => {
            x.classList.add("rpgui-button");
            x.classList.add("simple");
            const replaceCloseSvg = () => {
              replaceSvg(x as HTMLElement, "", true);
            };
            replaceCloseSvg();
          });
        })
        root.classList.add("rpgui-cursor-point");
        root.querySelector(".visibility-hidden")?.classList.remove("visibility-hidden");
      }
    }, [header]);

    return (
      <div className="authenticator" ref={header}></div>
    );
  };

  type CustomAuthenticatorProps = {
    preload?: boolean
  };

  const CustomAuthenticator = ({preload}: CustomAuthenticatorProps) => {
    const AuthenticatorComponent = withAuthenticator(Authenticator, {
      variation: 'default',
      className: 'amplify-dialog visibility-hidden',
      components: preload ? {} : {
        Header
      }
    });

    return <AuthenticatorComponent />
  };
  
  return (
    <>
      {!signedIn && (
        <Dialog open={open} onClose={onClose} fitContent={true} className={className} style={style}>
          <DialogPreload>
            <CustomAuthenticator preload={true} />
          </DialogPreload>
          <CustomAuthenticator />
          {children}
        </Dialog>
      )}
    </>
  );
};