import React from "react";
import ReactQuill, { Quill } from "react-quill";
import QuillJs, { DeltaStatic, Sources, RangeStatic } from "quill";
import "react-quill/dist/quill.snow.css";
import "./RichTextEditor.css"
import { ComponentProps, InputComponentProps } from "@app/models/types";
import { RichTextEditorToolbar } from './RichTextEditorToolbar';
import { v4 } from "uuid";
import { isEqual } from "lodash";
import { withTooltip } from "./Tooltip";

type QuillContainer = {
    quill: QuillJs & {
        history: any,
        theme: {
            tooltip: ITooltip
        }
    },
    controls: any[]
};

interface ITooltip {
    show: () => void,
    hide: () => void,
    save: () => void,
    root: HTMLElement,
    position: (rect: DOMRect) => void
};

type TooltipContainer = {
    [key: string]: ITooltip
};

function insertHeart(this: QuillContainer) {
    const selection = this.quill.getSelection();
    if(selection) {
        this.quill.insertText(selection.index, "♥");
        this.quill.setSelection(selection.index + 1, selection.length);
    }
};

enum MediaType {
    Hyperlink = "link",
    Image = "image",
    Video = "video"
}

function insertEmbed(this: any, media: MediaType, message: string) {
    const tooltip = this.quill.theme.tooltip;
    const originalSave = tooltip.save;
    const originalHide = tooltip.hide;
  
    tooltip.save = function () {
        const range = this.quill.getSelection(true);
        let url = this.textbox.value;
        if (url) {
            this.quill.insertEmbed(range.index, media, url, 'user');
        }
    };
    // Called on hide and save.
    tooltip.hide = function () {
        tooltip.save = originalSave;
        tooltip.hide = originalHide;
        tooltip.hide();
    };
    tooltip.edit(media);
    tooltip.textbox.placeholder = message;
};

function imageEmeded(this: any) {
    insertEmbed.apply(this, [MediaType.Image, "Embed URL"]);
};

// Undo and redo functions for Custom Toolbar
function undoChange(this: QuillContainer) {
    this.quill.history.undo();
};

function redoChange(this: QuillContainer) {
    this.quill.history.redo();
};

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "Press Start 2P",
  "-apple-system",
  "Roboto",
  "BlinkMacSystemFont",
  "Noto Color Emoji"
];
Quill.register(Font, true);

type RichTextEditorFooterProps = ComponentProps & {
    editor: RichTextEditor
};

const RichTextEditorFooter = ({editor}: RichTextEditorFooterProps) => {
    const characterLimit = `${editor.props.value.length}/${editor.props.maxSize}`;

    const Footer = withTooltip(() => {
        return (
            <span
                className={"super-font" + (editor.props.maxSize === editor.props.value.length ? " ql-max-limit" : "")}
            >
                {characterLimit}
            </span>
        );
    });
    
    return (
        <>
            {editor.props.maxSize && (
                <div className="ql-footer">
                    <Footer 
                        tooltip={editor.props.maxSize === editor.props.value.length
                            ? "Max Character Limit Reached"
                            : "Character Limit"
                        }
                    />
                </div>
            )}
        </>
    );
};

type RichTextEditorProps = ComponentProps & InputComponentProps & {
    value: string,
    onChange: (value: string) => void,
    placeholder?: string,
    maxLength?: number,
    maxSize?: number,
};

type RichTextEditorState = {
    selection?: RangeStatic,
    mode: number
};

/*
 * Editor component with custom toolbar and content containers
 * Note: for some reason this must be a class component, otherwise the Quill editor disappears on rerenders
 */
export class RichTextEditor extends React.Component<RichTextEditorProps, RichTextEditorState> {
    constructor(props: RichTextEditorProps) {
        super(props);
        this.state = {
            selection: undefined,
            mode: 1
        };
    }

    height = 0;
    container = `toolbar-${v4()}`;
    tooltips = {} as TooltipContainer;

    modules = {
        toolbar: {
            container: "#" + this.container,
            handlers: {
                undo: undoChange,
                redo: redoChange,
                "insert-heart": insertHeart,
                "image-embed": imageEmeded
            }
        }
    };

    quill?: ReactQuill | null;
    toolbar?: any;
    private privateEditor: any;

    static formats = [
        "header",
        "font",
        "size",
        "bold",
        "background",
        "direction",
        "italic",
        "underline",
        "strike",
        "script",
        "blockquote",
        "code-block",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "align"
    ];

    handleSetMode = (mode: number) => {
        if(mode !== this.state.mode){
            this.setState({
                ...this.state,
                mode
            });
        }
    };

    handleOnChange = (html: string, _delta: DeltaStatic, _source: Sources, editor: ReactQuill.UnprivilegedEditor ) => {
        if(this.props.maxLength && editor.getLength() > this.props.maxLength) {
            this.quill?.editor?.deleteText(this.props.maxLength, editor.getLength());
            html = editor.getHTML();
        }
        if(this.props.maxSize && html.length > this.props.maxSize) {
            const minCharacters = html.length - editor.getLength();
            if(minCharacters < this.props.maxSize) {
                this.quill?.editor?.deleteText(this.props.maxSize - minCharacters - 1, editor.getLength());
                html = editor.getHTML();
            } else {
                html = html.slice(0, this.props.maxSize);
            }
        }
        if(html === "<p><br></p>") {
            html = "";
        }
        this.props.onChange(html);
    };

    handleSelectionChange = (selection: ReactQuill.Range) => {
        if(selection && !isEqual(this.state.selection, selection)) {
            this.setState({
                ...this.state,
                selection: this.quill?.editor?.getSelection() || undefined
            });
        }
    };

    tooltipsRefresh = (parentElement?: HTMLElement | null) => {
        const editor = this.quill?.editor;
        const editorRoot = editor?.root;
        
        if(editorRoot) {
            editorRoot.classList.add("rpgui-cursor-select");
            editorRoot.classList.add("select-text");

            if(this.props.disabled) {
                editorRoot.classList.add("ql-disabled");
            }

            const container = editorRoot.parentElement;
            if(container) {
                container.className += this.props.className ? " " + this.props.className : "";
                this.height = container.offsetHeight;
            }
        }
    }

    render() {
        const createRef = (ref: ReactQuill | null) => {
            this.quill = ref;
            this.privateEditor = this.quill?.editor;
            if(this.privateEditor) {
                this.toolbar = this.privateEditor.theme.modules.toolbar;
                const tooltipRoot = this.toolbar.quill.theme.tooltip.root;
                tooltipRoot.classList.add("rpgui-container");
                tooltipRoot.classList.add("framed");
                tooltipRoot.querySelectorAll("a").forEach((x: any) => {
                    x.classList.add("rpgui-cursor-point");
                });
            }
            this.tooltipsRefresh();
        };

        return (
            <div className="text-editor">
                <RichTextEditorToolbar
                    editor={this}
                    id={this.container}
                    mode={this.state.mode}
                    setMode={this.handleSetMode}
                    selection={this.state.selection}
                />
                <ReactQuill
                    value={this.props.value}
                    onChange={this.handleOnChange}
                    onChangeSelection={this.handleSelectionChange}
                    placeholder={this.props.placeholder}
                    modules={this.modules}
                    formats={RichTextEditor.formats}
                    readOnly={this.props.disabled}
                    ref={createRef}
                    style={{display: this.state.mode !== 1 ? "none" : "inherit"}}
                />
                <textarea
                    disabled={this.props.disabled}
                    className={this.props.className}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    style={{
                        display: this.state.mode === 1 ? "none" : "inherit",
                        height: this.height
                    }}
                    readOnly={true}
                />
                <RichTextEditorFooter editor={this} />
            </div>
        );
    }
}

export default RichTextEditor;
