import { ProjectContributor, TagCategory } from '@structures';
import { useEffect } from 'react';
import { useQueryParameter, useQueryParameters } from './QueryParameters';

export type ProjectOptions = {
    initialContributor?: ProjectContributor,
    initialProducts?: string[],
    initialLanguages?: string[],
    initialSoftware?: string[],
    initialTechnologies?: string[]
};

export const useProject = ({initialContributor, initialProducts, initialLanguages, initialSoftware, initialTechnologies}: ProjectOptions) => {
    const useQueryParameterWithSort = (name: string, data?: any[]) => {
        return useQueryParameter(name, [...(data || []), "Other"]?.sort());
    };

    const [filterTitle, setFilterTitle] = useQueryParameter("title", "");
    const [contributor, setContributor] = useQueryParameter("contributor", "");
    const [showDetails, setShowDetails] = useQueryParameter("details", "");
    const [projectOrder, setProjectOrder] = useQueryParameter("order", "");
    const [defaultProducts, setDefaultProducts] = useQueryParameterWithSort("products", initialProducts);
    const [defaultLanguages, setDefaultLanguages] = useQueryParameterWithSort("languages", initialLanguages);
    const [defaultSoftware, setDefaultSoftware] = useQueryParameterWithSort("software", initialSoftware);
    const [defaultTechnologies, setDefaultTechnologies] = useQueryParameterWithSort("technologies", initialTechnologies);

    useEffect(() => {
        if(!contributor && initialContributor) {
            setContributor(initialContributor.id);
        }
    }, [contributor, initialContributor, setContributor]);
    
    return {
        contributor,
        setContributor,
        showDetails,
        setShowDetails,
        filterTitle,
        setFilterTitle,
        projectOrder,
        setProjectOrder,
        defaultProducts,
        setDefaultProducts,
        defaultLanguages,
        setDefaultLanguages,
        defaultSoftware,
        setDefaultSoftware,
        defaultTechnologies,
        setDefaultTechnologies
    }
};

export interface HasTitle {
    title?: string
};

export interface HasOrderable extends HasTitle {
    initial?: Date,
    release?: Date,
};

export interface HasAliases {
    aliases?: string[]
};

export interface HasTags {
    tags?: TagCategory
};

export type ProjectOrderBy = (a: HasOrderable, b: HasOrderable) => number;

export const useExistingProject = (order?: ProjectOrderBy) => {
    const [getParams, setParams, , , allOptions] = useQueryParameters<string[]>();
    const [getTitle] = useQueryParameters<string>();

    const withInterface = <T extends any>(component: any) => {
        return (component as unknown as React.Component<T>);
    };

    const getFilteredProjects = (array: any[]) => {
        return array.filter((child) => {
            const title = withInterface<HasTitle>(child)?.props?.title;
            const aliases = withInterface<HasAliases>(child)?.props.aliases;
            if(title) {
                const filteredTitle = getTitle("title");
                if(filteredTitle) {
                    if(!(title.includes(filteredTitle) || aliases?.some(alias => alias.includes(filteredTitle)))) {
                        return false;
                    }
                }
            }
            const tags = withInterface<HasTags>(child)?.props?.tags;
            if(tags) {
                if(!(Object.entries(tags).every(([category, projectTags]) => {
                    const filteredTags = getParams(category);
                    if(!filteredTags) {
                        return true;
                    }
                    return projectTags.every(projectTag => {
                        return filteredTags.every(filterTag => {
                            return filterTag === "Other"
                                ? !allOptions?.[category]?.includes(projectTag)
                                : filterTag === projectTag;
                        });
                    });
                }))) {
                    return false;
                }
            }
            return true;
        }).sort((x, y) => {
            if(!order) return 0;
            return order(withInterface<HasOrderable>(x)?.props, withInterface<HasOrderable>(y)?.props);
        })
    };

    const setFilteredProjects = (category: string, tags: string[]) => {
        setParams(category, tags);
    };

    return { getFilteredProjects, setFilteredProjects };
};

export default useProject;
