import { useEffect, useState } from 'react';
import { ComponentProps, FileType, Image, Audio, Video, Link, GenericCallback } from '@models';
import { ProjectMedia } from '@structures';
import { IconButton } from '@templates';
import { InteractiveHtml } from './Html';
import { MapViewer } from './MapFactory';

export const isEmbeddedMedia = (media: ProjectMedia) => {
    return !!((media.file as Link)?.href);
}

export const getMediaSource = (media: ProjectMedia) => {
    const url = media.file as Link;
    const file = media.file as Image | Video | Audio;

    return url?.href || file.default;
};

export type MediaFactoryProps = ComponentProps & {
    media: ProjectMedia,
    onClick?: React.MouseEventHandler<HTMLDivElement>,
    map?: boolean,
    highlight?: boolean
};

export const MediaFactory = ({ media, onClick, map, highlight, className, style, children }: MediaFactoryProps) => {
    const embedded = isEmbeddedMedia(media);
    const source = getMediaSource(media);
    const [image, setImage] = useState<HTMLImageElement>();
    const [recalculate, setRecalculate] = useState("");

    const handleOnLoad = (event: React.SyntheticEvent<HTMLImageElement>) => {
        setImage(event.currentTarget);
    };

    const calculate = () => {
        if(recalculate !== source) {
            setRecalculate(source);
        }
    };

    useEffect(calculate, [source, recalculate]);

    switch(media.type) {
        case FileType.Image:
            return (
                <>
                    <img
                        className="media"
                        src={source}
                        alt={media.description}
                        onLoad={handleOnLoad}
                        onResize={calculate}
                    />
                    {map && image && (
                        <MapViewer
                            media={image}
                            maps={media.maps}
                            highlight={highlight}
                            onClick={onClick}
                        />
                    )}
                </>
            );
        case FileType.Video:
            if(embedded) {
                return (
                    <iframe className={classNames(className, "media")} style={style} title={source} src={source} />
                );
            }

            return (
                <video className={"media"} autoPlay={false} preload={"auto"} controls>
                    <source src={`${source}#t=0.5`} />
                    <span>{media.description}</span>
                </video>
            );
        case FileType.Audio:
            if(embedded) {
                return (
                    <iframe className={classNames(className, "media")} style={style} title={source} src={source} />
                );
            }

            return (
                <audio className={"media"} autoPlay={false} preload={"auto"} controls>
                    <source src={source} />
                    <span>{media.description}</span>
                </audio>
            );
        case FileType.Download:
            return (
                <div className={classNames(className, "media")} style={{display: "inline-flex", ...style}}>
                    <a style={{width: "100%", height: "100%", margin: "auto"}} href={source} download>
                        <InteractiveHtml html={media.description} />
                    </a>
                </div>
            );
    }
    return <>{children}</>;
};

export type MediaViewerProps = MediaFactoryProps & {
    onFullscreen?: GenericCallback<ProjectMedia>
};

export const MediaViewer = ({media, onClick, highlight, onFullscreen, className, style, children}: MediaViewerProps) => {
    const handleFullscreen = () => {
        onFullscreen?.(media);
    };

    return (
        <span className={className} style={{position: "relative", ...style}} onDoubleClick={handleFullscreen}>
            <MediaFactory media={media} onClick={onClick} map={!onFullscreen} highlight={highlight}>
                {children}
            </MediaFactory>
            {onFullscreen && (
                <div className="fullscreen-button fullscreen-enter">
                    <IconButton icon="md fullscreen" tooltip="Fullscreen" onClick={handleFullscreen} />
                </div>
            )}
        </span>
    );
};

export default MediaViewer;
