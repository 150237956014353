import { toHumanReadable, toTitleCase } from "@utilities";
import {
    RequestError,
    ObjectNameMapping
  } from '@models';
import { useSnackbar } from 'notistack';

export const useNotifications = () => {
    const { enqueueSnackbar } = useSnackbar();

    const enqueueMessage = (message: string, variant: "success" | "warning" | "error" | "info") => {
        return enqueueSnackbar(message, {
        variant,
        style: {
            width: "clamp(400px, 40vw, 40vw)",
            fontSize: "8px !important"
        },
        className: "rpgui-content rpgui-text is-tiny"
        })
    };

    const enqueueCreate = (type: string, change: string) => {
        enqueueMessage(`${type} \n ${change}`, "success");
    };

    const enqueueCreateObject = (type: string, change: {}) => {
        enqueueCreate(type, toHumanReadable(change));
    };

    const enqueueUpdate = (type: string, change: string) => {
        enqueueMessage(`${type} \n ${change}`, "warning");
    };

    const enqueueUpdateProperty = <T extends any>(name: string, a: T, b: T) => {
        if(a !== b) {
            enqueueUpdate(`${name} Updated`, `${a} → ${b}`);
        }
    };

    const enqueueUpdateObject = <T extends {}>(objectA: T, objectB: T, names?: ObjectNameMapping<T>) => {
        for (const key in objectA) {
            if (Object.prototype.hasOwnProperty.call(objectA, key)) {
                const elementA = objectA[key];
                const elementB = objectB[key];
                const name = names?.[key] || toTitleCase(key);
                enqueueUpdateProperty(name, elementA, elementB);
            }
        }
    };

    const enqueueDelete = (type: string, change: string) => {
        enqueueMessage(`${type} \n ${change}`, "info");
    };

    const enqueueDeleteObject = (type: string, change: {}) => {
        enqueueDelete(type, toHumanReadable(change));
    };

    const enqueueError = (error: unknown) => {
        const badRequest = error as RequestError;
        if(badRequest.errors) {
        const errors = "The following errors occurred:\n" + badRequest.errors.map(x => x.message).join("\n");
        enqueueMessage(errors, "error");
        return errors;
        } else {
        console.error(error);
        return "An unknown error occurred";
        }
    };

    return {
        enqueueCreate,
        enqueueCreateObject,
        enqueueUpdate,
        enqueueUpdateProperty,
        enqueueUpdateObject,
        enqueueDelete,
        enqueueDeleteObject,
        enqueueError
    };
};