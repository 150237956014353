import { useRef } from "react";
import { ComponentProps, GenericCallback, ReactEvent, ReactEventWithTarget } from "@models/types";
import styled from '@mui/styled-engine';
import { Dialog as MuiDialog, DialogContent as MuiDialogContent, DialogActions as MuiDialogActions } from '@mui/material';
import { clamp, useChild, useRenderOnResize } from "@utilities";

const DialogPreloadDiv = styled("div")`
  display: none;
`;

export type DialogPreloadProps = ComponentProps & {

};

export const DialogPreload = ({className, style, children}: DialogPreloadProps) => {
    return (
        <DialogPreloadDiv style={style} className={classNames(className, "rpgui-dialog-preload")}>
          {children}
        </DialogPreloadDiv>
    );
};

export type DialogActionProps = ComponentProps & {

};

export const DialogActions = ({className, style, children}: DialogActionProps) => {
    return (
        <MuiDialogActions className={className} style={style}>
            {children}
        </MuiDialogActions>
    );
};

export type DialogContentProps = ComponentProps & {

};

export const DialogContent = ({className, style, children}: DialogContentProps) => {
    return (
        <MuiDialogContent className={className} style={style}>
            {children}
        </MuiDialogContent>
    );
};

export type DialogInitEvent = ReactEventWithTarget<HTMLDivElement>;
export type DialogCloseEvent = ReactEvent<HTMLDivElement, string>;

export type DialogProps = ComponentProps & {
    onInit?: GenericCallback<DialogInitEvent>,
    open: boolean,
    onClose?: GenericCallback<DialogCloseEvent>,
    fitContent?: boolean
};

export const Dialog = ({ onInit, open, onClose, fitContent, className, style, children }: DialogProps) => {
    const [preload, nonPreload] = useChild(children, DialogPreload);
    const [actions, nonActions] = useChild(nonPreload, DialogActions);
    const [content, allContent] = useChild(nonActions, DialogContent);
    const PreloadComponent: React.FunctionComponent<DialogPreloadProps> = (preload.length > 0 ? preload[0]?.type : DialogPreload);

    useRenderOnResize();

    const dialog = useRef<HTMLDivElement>();

    const handleOnClose = (reason: string) => {
        if(dialog.current) {
            onClose?.({ element: dialog.current, value: reason });
        }
    };

    const handleRef = (instance: HTMLDivElement) => {
        if(instance) {
            onInit?.({ element: instance });
        }
        dialog.current = instance;
    };

    const instance = dialog.current;
    if(instance) {
        const container = instance.querySelector(".MuiPaper-root") as HTMLElement;
        if(container) {
            const viewport = document.documentElement;
            const width = instance.clientWidth || 0;
            const height = instance.clientHeight || 0;
            const adjustX = viewport.clientWidth / 600;
            const adjustY = viewport.clientHeight / 400;
            const scaleX = clamp((viewport.clientWidth - 40) / width * adjustX, 400 / width * adjustX, 1.0);
            const scaleY = clamp((viewport.clientHeight - 40) / height * adjustY, 300 / height * adjustY, 1.0);

            container.style.scale = `${scaleX} ${scaleY}`;
            container.style.transformOrigin = "center center 0px";
            container.style.minWidth = width <= 580 ? "550px" 
                : fitContent ? "fit-content"
                : `clamp(450px, ${100 + (1 - scaleX) * 100}vw, 1000px)`;
            container.style.minHeight = height <= 330 ? "300px" 
                : fitContent ? "fit-content"
                : `clamp(300px, ${100 + (1 - scaleY) * 100}vh, 400px)`;
            container.style.overflow = "hidden";
            container.style.width = "fit-content";
            container.style.height = "fit-content";

            container.classList.add("rpgui-content");
            container.classList.add("rpgui-container");
            container.classList.add("framed");
            container.classList.add("dialog-container");
        }
    }

    return (
        <>
            <PreloadComponent>
                {preload[0]?.props?.children || content}
            </PreloadComponent>
            <MuiDialog
                className={classNames(className, "rpgui-dialog-root rpgui-content")}
                style={style}
                open={open}
                onClose={handleOnClose}
                ref={handleRef}
            >                
                <div style={{overflow: "auto"}}>
                    {content[0]?.props?.children &&
                        <DialogContent>
                            {content}
                        </DialogContent>
                    }
                    {allContent}
                    {actions[0]?.props?.children &&
                        <DialogActions>
                            {actions}
                        </DialogActions>
                    }
                </div>
            </MuiDialog>
        </>
    );
};

export default Dialog;