import React, { useEffect, useRef, useState } from "react";
import RichTextEditor from "./RichTextEditor";
import { ToolbarOverflowMenu } from "./RichTextEditor/ToolbarOverflowMenu";
import { HTMLPickerElement, ToolbarPicker } from "./RichTextEditor/ToolbarPicker";
import { InsertHeartEmoji } from "./RichTextEditor/InsertHeartEmoji";
import UndoButton from "./RichTextEditor/UndoButton";
import RedoButton from "./RichTextEditor/RedoButton";
import { withTooltip } from "./Tooltip";
import { View } from "@aws-amplify/ui-react";
import{ RangeStatic } from "quill";

const setDefaultFormat = (richText: RichTextEditor, format: string, value?: any) => {
    const editor = richText?.quill?.editor;
    if(editor) {
        const currentValue = editor.getFormat()[format];
        editor.format(format, value != null && (format !== currentValue || value !== currentValue) 
            ? value : !currentValue, "user");
    }
};

const getDefaultFormat = (richText: RichTextEditor, format: string, selection?: RangeStatic) => {
    const editor = richText?.quill?.editor;
    if(editor) {
        const initialized = selection && editor.getLength() > 0;
        const currentValue = initialized && editor.getFormat(selection)?.[format];
        return currentValue;
    }
};

const defaultFormat = (richText: RichTextEditor) => {
    return (event: React.MouseEvent<HTMLElement>) => {
        if(!event.currentTarget.classList.contains("ql-custom-handle")) return;
        const regex = /ql-\S*/;
        const format = event.currentTarget.className.match(regex)?.[0]?.replace("ql-", "");
        if(format) {
            const value = event.currentTarget.getAttribute("value");
            setDefaultFormat(richText, format, value);
        }
    }
};

const setFormatName = (richText: RichTextEditor, initialize: boolean, format: string) => {
    const editor = richText?.quill?.editor;
    const element = editor?.root.querySelector(`.ql-${format}`);
    const value = element?.getAttribute("value");
    //BUG: I don't full understand this, but when calling getFormat the quill editor
    // will try to focus  the editor. So to prevent that from happening on the initial
    // page load we simply don't call that until after the page is loaded/initialized ¯\_(ツ)_/¯
    const weirdBug = !initialize && (editor?.getLength() || 0) > 1;
    return classNames(`ql-${format}`, (weirdBug && editor?.getFormat()[format] === (value || true) ? "ql-active" : ""));
};

const formatName = (richText: RichTextEditor, initialize: boolean) => {
    return (format: string) => {
        return setFormatName(richText, initialize, format);
    }
};

const setDefaultHandler = (richText: RichTextEditor, handler: string, value?: any) => {
    const handlers = richText.toolbar.handlers;
    handlers[handler].apply(richText.toolbar);
};

const defaultHandler = (richText: RichTextEditor) => {
    return (event: React.MouseEvent<HTMLElement>) => {
        if(!event.currentTarget.classList.contains("ql-custom-handle")) return;
        const regex = /ql-\S*/;
        const handler = event.currentTarget.className.match(regex)?.[0]?.replace("ql-", "");
        if(handler) {
            setDefaultHandler(richText, handler);
        }
    }
};

export const RichTextEditorControl = withTooltip((props: any) => {
    const { tooltip, type, ...componentProps} = props;

    return <View as={"button"} {...componentProps} />
}, { style: {height: "fit-content"}});

export type RichTextEditorToolbarProps = {
    editor: RichTextEditor,
    id: string,
    mode?: number,
    setMode?: (mode: number) => void,
    selection?: RangeStatic
};

export const RichTextEditorToolbar = ({editor, id, mode, setMode, selection}: RichTextEditorToolbarProps) => {
    const initialized = useRef(false);
    const [size, setSize] = useState("medium");
    const [style, setStyle] = useState(0);
    const handleDefaultHandler = defaultHandler(editor);
    const handleDefaultFormat = defaultFormat(editor);
    const handleFormatName = formatName(editor, initialized.current);
    initialized.current = true;

    useEffect(() => {
        const newSize = getDefaultFormat(editor, "size", selection) || "medium";
        if(size !== newSize) {
            setSize(newSize);
        }
        const newStyle = getDefaultFormat(editor, "header", selection) || 0;
        if(style !== newStyle) {
            setStyle(newStyle);
        }
    }, [editor, selection, size, style])

    return (
        <div id={id} className="rich-text-editor-toolbar rpgui-cursor-default">
            <div className="rpgui-cursor-point">
                <div className={classNames(mode !== 1 ? "ql-disabled" : "", "ql-controls")}>
                    <span className="ql-formats not-indexed" style={{position: "relative", zIndex: 11}}>
                        {/* <select className="ql-font" defaultValue={"Press Start 2P"} style={{width: 136}}>
                            <option value="Press Start 2P">Default</option>
                        </select> */}
                        <ToolbarPicker
                            value={size}
                            defaultValue={size}
                            options={[
                                { name: "Small", value: "small" },
                                { name: "Medium", value: "medium" },
                                { name: "Large", value: "large" }
                            ]}
                            onChange={(event: React.ChangeEvent<HTMLPickerElement>) => {
                                const newValue = event.currentTarget.value;
                                setSize?.(newValue);
                                setDefaultFormat(editor, "size", newValue);
                            }}
                            tooltip="Size"
                            style={{width: 120}}
                        />
                        <ToolbarPicker
                            value={style}
                            defaultValue={style}
                            options={[
                                { name: "Normal", value: 0 },
                                { name: "Title", value: 1 },
                                { name: "Subtitle", value: 2 },
                                { name: "Heading", value: 3 },
                                { name: "Subheading", value: 4 },
                                { name: "Note", value: 5 },
                                { name: "Subnote", value: 6 }
                            ]}
                            onChange={(event: React.ChangeEvent<HTMLPickerElement>) => {
                                const newValue = parseInt(event.currentTarget.value);
                                setStyle?.(newValue);
                                setDefaultFormat(editor, "header", newValue);
                            }}
                            tooltip="Style"
                            style={{width: 160}}
                        />
                    </span>
                    <span className="ql-formats" style={{position: "relative", zIndex: 0}}>
                        <ToolbarOverflowMenu editor={editor} maxSizes={[200, 450, 520, 700, 850, 1150]} tooltip="Formatting Types">
                            <RichTextEditorControl type="button" className={handleFormatName("bold")} tooltip="Bold" onClick={handleDefaultFormat}>
                                <svg viewBox="0 0 18 18"> <path className="ql-stroke" d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"></path> <path className="ql-stroke" d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"></path> </svg>
                            </RichTextEditorControl>
                            <RichTextEditorControl type="button" className={handleFormatName("italic")} tooltip="Italic" onClick={handleDefaultFormat}>
                                <svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="13" y1="4" y2="4"></line> <line className="ql-stroke" x1="5" x2="11" y1="14" y2="14"></line> <line className="ql-stroke" x1="8" x2="10" y1="14" y2="4"></line> </svg>
                            </RichTextEditorControl>
                            <RichTextEditorControl type="button" className={handleFormatName("underline")} tooltip="Underline" onClick={handleDefaultFormat}>
                                <svg viewBox="0 0 18 18"> <path className="ql-stroke" d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"></path> <rect className="ql-fill" height="1" rx="0.5" ry="0.5" width="12" x="3" y="15"></rect> </svg>
                            </RichTextEditorControl>
                            <RichTextEditorControl type="button" className={handleFormatName("strike")} tooltip="Strike-through" onClick={handleDefaultFormat}>
                                <svg viewBox="0 0 18 18"> <line className="ql-stroke ql-thin" x1="15.5" x2="2.5" y1="8.5" y2="9.5"></line> <path className="ql-fill" d="M9.007,8C6.542,7.791,6,7.519,6,6.5,6,5.792,7.283,5,9,5c1.571,0,2.765.679,2.969,1.309a1,1,0,0,0,1.9-.617C13.356,4.106,11.354,3,9,3,6.2,3,4,4.538,4,6.5a3.2,3.2,0,0,0,.5,1.843Z"></path> <path className="ql-fill" d="M8.984,10C11.457,10.208,12,10.479,12,11.5c0,0.708-1.283,1.5-3,1.5-1.571,0-2.765-.679-2.969-1.309a1,1,0,1,0-1.9.617C4.644,13.894,6.646,15,9,15c2.8,0,5-1.538,5-3.5a3.2,3.2,0,0,0-.5-1.843Z"></path> </svg>
                            </RichTextEditorControl>
                            <RichTextEditorControl type="button" className={handleFormatName("script")} value="super" tooltip="Superscript" onClick={handleDefaultFormat}>
                                <svg viewBox="0 0 18 18"> <path className="ql-fill" d="M15.5,7H13.861a4.015,4.015,0,0,0,1.914-2.975,1.8,1.8,0,0,0-1.6-1.751A1.922,1.922,0,0,0,12.021,3.7a0.5,0.5,0,1,0,.957.291,0.917,0.917,0,0,1,1.053-.725,0.81,0.81,0,0,1,.744.762c0,1.077-1.164,1.925-1.934,2.486A1.423,1.423,0,0,0,12,7.5a0.5,0.5,0,0,0,.5.5h3A0.5,0.5,0,0,0,15.5,7Z"></path> <path className="ql-fill" d="M9.651,5.241a1,1,0,0,0-1.41.108L6,7.964,3.759,5.349a1,1,0,1,0-1.519,1.3L4.683,9.5,2.241,12.35a1,1,0,1,0,1.519,1.3L6,11.036,8.241,13.65a1,1,0,0,0,1.519-1.3L7.317,9.5,9.759,6.651A1,1,0,0,0,9.651,5.241Z"></path> </svg>
                            </RichTextEditorControl>
                            <RichTextEditorControl type="button" className={handleFormatName("script")} value="sub" tooltip="Subscript" onClick={handleDefaultFormat}>
                                <svg viewBox="0 0 18 18"> <path className="ql-fill" d="M15.5,15H13.861a3.858,3.858,0,0,0,1.914-2.975,1.8,1.8,0,0,0-1.6-1.751A1.921,1.921,0,0,0,12.021,11.7a0.50013,0.50013,0,1,0,.957.291h0a0.914,0.914,0,0,1,1.053-.725,0.81,0.81,0,0,1,.744.762c0,1.076-1.16971,1.86982-1.93971,2.43082A1.45639,1.45639,0,0,0,12,15.5a0.5,0.5,0,0,0,.5.5h3A0.5,0.5,0,0,0,15.5,15Z"></path> <path className="ql-fill" d="M9.65,5.241a1,1,0,0,0-1.409.108L6,7.964,3.759,5.349A1,1,0,0,0,2.192,6.59178Q2.21541,6.6213,2.241,6.649L4.684,9.5,2.241,12.35A1,1,0,0,0,3.71,13.70722q0.02557-.02768.049-0.05722L6,11.036,8.241,13.65a1,1,0,1,0,1.567-1.24277Q9.78459,12.3777,9.759,12.35L7.316,9.5,9.759,6.651A1,1,0,0,0,9.65,5.241Z"></path> </svg>
                            </RichTextEditorControl>
                        </ToolbarOverflowMenu>
                    </span>
                    <span className="ql-formats" style={{position: "relative", zIndex: 0}}>
                        <ToolbarOverflowMenu editor={editor} maxSizes={[200, 400, 600, 800]} tooltip="Display Blocks">
                            <RichTextEditorControl type="button" className="ql-list" value="ordered" tooltip="Ordered List" onClick={handleDefaultFormat}>
                                <svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line> <line className="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line> <line className="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line> <path className="ql-fill" d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path> <path className="ql-stroke ql-thin" d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path> <path className="ql-stroke ql-thin" d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path> </svg>
                            </RichTextEditorControl>
                            <RichTextEditorControl type="button" className="ql-list" value="bullet" tooltip="Bulleted List" onClick={handleDefaultFormat}>
                                <svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line> <line className="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line> <line className="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line> <line className="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line> <line className="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line> <line className="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line> </svg>
                            </RichTextEditorControl>
                            <RichTextEditorControl type="button" className="ql-blockquote" tooltip="Block Quote" onClick={handleDefaultFormat}>
                                <svg viewBox="0 0 18 18"> <rect className="ql-fill ql-stroke" height="3" width="3" x="4" y="5"></rect> <rect className="ql-fill ql-stroke" height="3" width="3" x="11" y="5"></rect> <path className="ql-even ql-fill ql-stroke" d="M7,8c0,4.031-3,5-3,5"></path> <path className="ql-even ql-fill ql-stroke" d="M14,8c0,4.031-3,5-3,5"></path> </svg>
                            </RichTextEditorControl>
                            <RichTextEditorControl type="button" className="ql-code-block" tooltip="Code-Block" onClick={handleDefaultFormat}>
                                <svg viewBox="0 0 18 18"> <polyline className="ql-even ql-stroke" points="5 7 3 9 5 11"></polyline> <polyline className="ql-even ql-stroke" points="13 7 15 9 13 11"></polyline> <line className="ql-stroke" x1="10" x2="8" y1="5" y2="13"></line> </svg>
                            </RichTextEditorControl>
                        </ToolbarOverflowMenu>
                    </span>
                    <span className="ql-formats">
                        <RichTextEditorControl type="button" className="ql-indent" value="-1" tooltip="Indent Right" style={{zIndex: 4}} />
                        <RichTextEditorControl type="button" className="ql-indent" value="+1" tooltip="Indent Left" style={{zIndex: 5}} />
                        {/* <button className="ql-direction" tooltip="Direction" /> */}
                    </span>
                    {/* <span className="ql-formats">
                        <select className="ql-align" tooltip="Alignment" />
                        <select className="ql-color ql-no-hover" tooltip="Text Color" />
                        <select className="ql-background" tooltip="Background Color" />
                    </span> */}
                    <span className="ql-formats">
                        <RichTextEditorControl type="button" className="ql-clean" tooltip="Clear Formatting" style={{zIndex: 6}} />
                    </span>
                    <span className="ql-formats">
                        <ToolbarOverflowMenu editor={editor} maxSizes={[200, 400, 600]} tooltip="Link Types" style={{zIndex: 7}}>
                            <RichTextEditorControl type="button" className="ql-link" tooltip="Hyperlink" onClick={handleDefaultHandler}>
                                <svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="11" y1="7" y2="11"></line> <path className="ql-even ql-stroke" d="M8.9,4.577a3.476,3.476,0,0,1,.36,4.679A3.476,3.476,0,0,1,4.577,8.9C3.185,7.5,2.035,6.4,4.217,4.217S7.5,3.185,8.9,4.577Z"></path> <path className="ql-even ql-stroke" d="M13.423,9.1a3.476,3.476,0,0,0-4.679-.36,3.476,3.476,0,0,0,.36,4.679c1.392,1.392,2.5,2.542,4.679.36S14.815,10.5,13.423,9.1Z"></path> </svg>
                            </RichTextEditorControl>
                            <RichTextEditorControl type="button" className="ql-image-embed" tooltip="Embed Image" onClick={handleDefaultHandler}>
                                <svg viewBox="0 0 18 18">
                                    <rect className="ql-stroke" height="10" width="12" x="3" y="4"></rect>
                                    <circle className="ql-fill" cx="6" cy="7" r="1"></circle>
                                    <polyline className="ql-even ql-fill" points="5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12"></polyline>
                                </svg>
                            </RichTextEditorControl>
                            <RichTextEditorControl type="button" className="ql-video" tooltip="Embed Video" onClick={handleDefaultHandler}>
                                <svg viewBox="0 0 18 18"> <rect className="ql-stroke" height="12" width="12" x="3" y="3"></rect> <rect className="ql-fill" height="12" width="1" x="5" y="3"></rect> <rect className="ql-fill" height="12" width="1" x="12" y="3"></rect> <rect className="ql-fill" height="2" width="8" x="5" y="8"></rect> <rect className="ql-fill" height="1" width="3" x="3" y="5"></rect> <rect className="ql-fill" height="1" width="3" x="3" y="7"></rect> <rect className="ql-fill" height="1" width="3" x="3" y="10"></rect> <rect className="ql-fill" height="1" width="3" x="3" y="12"></rect> <rect className="ql-fill" height="1" width="3" x="12" y="5"></rect> <rect className="ql-fill" height="1" width="3" x="12" y="7"></rect> <rect className="ql-fill" height="1" width="3" x="12" y="10"></rect> <rect className="ql-fill" height="1" width="3" x="12" y="12"></rect> </svg>
                            </RichTextEditorControl>
                        </ToolbarOverflowMenu>
                        {/* <button className="ql-formula" /> */}
                        {/* <button className="ql-image" tooltip="Upload Image" /> */}
                    </span>
                    <span className="ql-formats">
                        <ToolbarPicker
                            overrideDisplay={<svg style={{width: "fit-content", marginTop: 0, top: 0}} strokeWidth={0} width="24" height="24" fill="none" viewBox="-1 0 28 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16zM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12z" fill="#000"/><path d="M11 9.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" fill="#000"/><path fillRule="evenodd" clipRule="evenodd" d="M9.5 10a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" fill="#000"/><path d="M16 9.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" fill="#000"/><path fillRule="evenodd" clipRule="evenodd" d="M14.5 10a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM7.427 13.18a1 1 0 0 1 1.392.247c.49.698 1.393 1.495 2.472 1.719.984.204 2.35-.02 3.966-1.815a1 1 0 0 1 1.486 1.338c-1.984 2.205-4.034 2.814-5.859 2.435-1.73-.36-3.026-1.563-3.703-2.53a1 1 0 0 1 .246-1.393z" fill="#000"/></svg>}
                            options={[
                                { name: <InsertHeartEmoji />, value: "♥" }
                            ]}
                            tooltip="Emoji"
                            style={{zIndex: 8}}
                        />
                    </span>
                    <span className="ql-formats">
                        <UndoButton style={{zIndex: 9}} tooltip="Undo Last Action" />
                        <RedoButton style={{zIndex: 10}} tooltip="Redo Last Action" />
                    </span>
                </div>
                <div className="ql-formats ql-views">
                    <ToolbarPicker
                        defaultValue={mode}
                        options={[
                            { name: "Formatted", value: 1 },
                            { name: "Raw Html", value: 2 }
                        ]}
                        onChange={(event: React.ChangeEvent<HTMLPickerElement>) => {
                            setMode?.(parseInt(event.currentTarget.value));
                        }}
                        tooltip="Text Viewer Type"
                        style={{zIndex: 11}}
                    />
                </div>
            </div>
        </div>
    );
};

export default RichTextEditorToolbar;
