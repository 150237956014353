import {
  Comments,
  ContainerAlignment,
  Filters,
  Page,
  Project,
  ProjectDetails,
  Projects,
  Section
} from '@components';
import projects from '@data';
import { Contributors, Languages, Products, Software, Technologies } from '@structures';
import { useProject } from '@services/Project';

export const Portfolio = () => {
  const software = Object.values(Software);
  const products = Object.values(Products);
  const languages = Object.values(Languages);
  const technologies = Object.values(Technologies);
  
  const {
    contributor,
    filterTitle, setFilterTitle,
    showDetails, setShowDetails,
    defaultProducts, setDefaultProducts,
    defaultLanguages, setDefaultLanguages,
    defaultSoftware, setDefaultSoftware,
    defaultTechnologies, setDefaultTechnologies
  } = useProject({
    initialContributor: Contributors.MakoInfused,
    initialProducts: products,
    initialLanguages: languages,
    initialSoftware: software,
    initialTechnologies: technologies
  });

  const project = projects.find(project => project.summary.id === showDetails);

  return (
    <Page name="portfolio">
      <Section name="filters" label>
        <Filters
          filterTitle={filterTitle}
          setFilterTitle={setFilterTitle}
          products={products}
          selectedProducts={defaultProducts}
          setSelectedProducts={setDefaultProducts}
          languages={languages}
          selectedLanguages={defaultLanguages}
          setSelectedLanguages={setDefaultLanguages}
          software={software}
          selectedSoftware={defaultSoftware}
          setSelectedSoftware={setDefaultSoftware}
          technologies={technologies}
          selectedTechnologies={defaultTechnologies}
          setSelectedTechnologies={setDefaultTechnologies}
        />
      </Section>
      <Section name="content" title="Projects">
        <Projects alignment={ContainerAlignment.Start}>
          {projects.map((project) => (
            <Project
              key={project.summary.title}
              {...project.summary}
              showDetails={showDetails}
              setShowDetails={setShowDetails}
            />
          ))}
        </Projects>
        <ProjectDetails
          open={showDetails}
          setOpen={setShowDetails}
          contributor={contributor}
          data={project}
        />
      </Section>
      <Section name="comments" label>
        <Comments />
      </Section>
    </Page>
  );
};

export default Portfolio;