import { ProjectData, Tags, Languages, Software, ProjectStatus, Products, Contributors, Technologies, OrientationType } from '@structures/ProjectData';
import { FileType } from '@models';

export const DiabloIV: ProjectData = {
    summary: {
        id: "D4",
        title: "Diablo IV",
        orientation: OrientationType.Portrait,
        aliases: ["D4", "DIV", "Diablo"],
        filter: { settings: { pixelSize: 3 }},
        version: 1.5,
        status: ProjectStatus.Live,
        initial: new Date(2023, 2, 1),
        release: new Date(),
        previews:[{
            file: new URL("https://upload.wikimedia.org/wikipedia/en/1/1c/Diablo_IV_cover_art.png"),
            type: FileType.Image,
            description: "A ghoulish image of a demonic looking woman holding a skull of a demon, with the text 'Diablo IV'.",
            caption: `Cover art for the game.`
        }],
        tags: {
            [Tags.Products]: [
                Products.Tools,
                Products.Games
            ],
            [Tags.Software]: [
                Software.VS
            ],
            [Tags.Languages]: [
                Languages.CSharp
            ],
            [Tags.Technologies]: [
                Technologies.WPF
            ]
        },
        description: "A game about where humans get trapped in a war between devils and angels quest for domination."
    },
    details: {
        description: "Set in the Diablo series' world of Sanctuary, Diablo IV takes place 50 years after the events of Diablo III."
            + "<br/><br/>"
            + "Cultists have summoned the main antagonist and daughter of Mephisto, Lilith."
            + "<br/><br/>"
            + "After the events of previous games, the forces of demons and angels have been depleted, allowing an opening for her to establish power in Sanctuary.",
        gallery: [{
            file: new URL("https://www.youtube.com/embed/XV4zVqb9vWc?si=jjZzXtXKLHDvH0Mj?controls=0"),
            type: FileType.Video,
            description: "",
            caption: "The gameplay trailer for the game!"
        }],
        contributions: [{
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Lead Tools Engineer",
                involvement: "Develop features and lead a team of engineers to produce enhancements of an internal game editor.",
                achievements: [
                    "Developed a performance improvement of 400% on some select critical internal reporting tools.",
                    "Developed features to assist artists with tag management of assets.",
                    "Lead 6 engineers, providing metorship, technical direction and support for every step throughout the Software Development Lifecycle.",
                    "Scale system architecture to support plugins and allow for modular development of features and systems.",
                    "Own critical areas of the editor, ensuring stability and providing support for content creators to make assets for the game."
                ]
            }]
        }]
    },
};

export default DiabloIV;