import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardMedia, CardHeader, IconButton, Skeleton } from '@mui/material';
import { ComponentProps, GenericCallback } from '@models';
import { Column, ContainerAlignment, getMediaSource, Heading, hoverBackgroundColor, Row, Term, Text, useBorder, withTooltip } from '@app/components';
import { pixelateImage, recentTime } from '@utilities';
import { useExistingProject } from '@services';
import { OrientationType, ProjectSummaryData } from '@structures';
import { isEqual } from 'lodash';

export type ProjectSummaryProps = ComponentProps & ProjectSummaryData & {
  loading?: boolean,
  showDetails?: string,
  setShowDetails?: GenericCallback<string>
};

export const Project = ({
    id,
    title,
    orientation = OrientationType.Landscape,
    aliases,
    filter,
    version,
    initial,
    release,
    previews,
    description = "Coming soon...",
    tags,
    showDetails,
    setShowDetails,
    loading = false,
    className,
    style,
    children
}: ProjectSummaryProps) => {
    const noPreviews = !previews?.length;
    const [loaded, setLoaded] = useState(noPreviews);
    const [lastThumbnail, setLastThumbnail] = useState(previews);
    const pixelateFilter = (pixelSize: number) => (img: React.SyntheticEvent<HTMLImageElement>) => {
        if(!loaded || !isEqual(previews, lastThumbnail)) {
            pixelateImage(img.currentTarget, pixelSize);
            setLoaded(true);
            setLastThumbnail(previews);
        }
    };
    const isLoading = !loaded || loading;

    const containerClassName = "nes-container is-thin no-padding";
    const rounded = " is-rounded";
    const loadedBorder = " " + useBorder("white");
    const activeBorder = isLoading ? "" : loadedBorder;
    const selectBorder = " " + useBorder("yellow");
    const selectableBorder = isLoading ? "" : selectBorder;
    const interactable = " rpgui-cursor-point";
    const [preview] = previews || [];

    useEffect(() => {
        if(!isEqual(previews, lastThumbnail)) {
            setLoaded(!previews);
            if(!previews) {
                setLastThumbnail([]);
            }
        }
    }, [previews, lastThumbnail]);

    const handleShowDetails = () => {
        setShowDetails?.(showDetails !== id ? id : "");
    };

    const { setFilteredProjects: setFilteredTags } = useExistingProject();

    const handleSetTag = (category: string, tag: string) => {
        return () => () => {
            setFilteredTags(category, [tag]);
        };
    };

    const Timeline = withTooltip(() => {
        return (
            <>
                {initial ? (
                    <>{recentTime(initial)} - {recentTime(release)}</>
                ) : (
                    <>{release ? recentTime(release) : "?"}</>
                )}
            </>
        );
    });

    const Version = withTooltip(() => {
        return (
            <>&nbsp;{version?.toFixed(1)}</>
        );
    });

    const ShowMore = withTooltip(() => {
        return (
            <span style={{
                color: isLoading ? "grey" : "white",
                translate: "6px 1px",
                rotate: "90deg",
                width: 18,
                height: 18,
                fontSize: "18px"
            }}>
                …
            </span>
        );
    })

    return (
        <Card 
            className={classNames(containerClassName + rounded + activeBorder, className)}
            sx={{
                width: "clamp(350px, 28vw, 450px)",
                height: 333,
                backgroundColor: isLoading ? "grey" : "dimgrey",
                ...style
            }}
        >
            <CardHeader
                className={containerClassName + rounded}
                style={{height: "3em"}}
                action={setShowDetails && (
                    <IconButton
                        className={containerClassName + rounded + selectableBorder + interactable} aria-label="details"
                        style={{margin: "6px 10px"}}
                        disabled={isLoading}
                        sx={{
                            ...hoverBackgroundColor()
                        }}
                        onClick={handleShowDetails}
                    >
                        <ShowMore tooltip={`Show the full details for "${title}".`} />
                    </IconButton>
                )}
                title={
                    isLoading ? (
                        <Skeleton
                            animation="wave"
                            height="1.5em"
                            width="80%"
                            style={{ marginBottom: 6 }}
                            sx={{ margin: "auto" }}
                        />
                    ) : (
                        <Heading level={5} tooltip={aliases ? "Aliases:\n" + aliases.join("\n") : ""} style={{marginTop: 5}}>
                            <Text maxLines={1} className="full-width" customization={{text: { className: "full-width"}, tooltip: { prefix: "Fullname:\n"}}}>
                                {title}
                            </Text>
                        </Heading>
                    )
                }
                subheader={
                    <div className="super-font" style={{position: "absolute", bottom: 0, right: 0, left: 0}}>
                        {isLoading ? (
                            <Skeleton animation="wave" height="1.5em" width="40%" sx={{ margin: "auto" }} />
                        ) : (
                            <div style={{textAlign: "center"}}>
                                <Timeline tooltip={(initial ? "Initial Release Date" : "") + (initial && release ? " - " : "") + (release ? "Final Release Date" : "")} />
                                <Version tooltip="The Final/Gold Release Version" />
                            </div>
                        )}
                    </div>
                }
            />
            <div className={containerClassName + (isLoading ? rounded : "")}>
                <Skeleton
                    sx={{ height: 190 }}
                    animation="wave"
                    variant="rectangular"
                    style={{display: !isLoading ? "none" : "inherit"}}
                />
                <div style={{display: isLoading ? "none" : "inherit"}}>
                    {noPreviews ? (
                        <Column alignment={ContainerAlignment.Middle}>
                            <Row alignment={ContainerAlignment.Middle} style={{height: 190}}>
                                <Text customization={{text: { className: "full-width"}}} className="full-width">Preview Image Not Available</Text>
                                <div className="noise-container is-tube" onClick={handleShowDetails}><div className="noise"></div></div>
                            </Row>
                        </Column>
                    ) : (
                        <CardMedia
                            component={"img"}
                            width={orientation === OrientationType.Landscape ? "350" : "200"}
                            height="190"
                            image={getMediaSource(preview)}
                            alt={preview.description}
                            onLoad={pixelateFilter(filter?.settings?.pixelSize || 4)}
                            crossOrigin={"anonymous"}
                            className={`${OrientationType[orientation].toLowerCase()}`}
                            onClick={handleShowDetails}
                        />
                    )}
                    <div className="rpgui-tags" style={{position: "absolute", left: 0, bottom: 0}}>
                        {tags && Object.entries(tags).map(([key, value]) => (
                            value.flatMap((tag) => (
                                <Term 
                                    key={tag}
                                    className="rpgui-text is-tiny"
                                    option={tag}
                                    setOption={handleSetTag(key, tag)}
                                    active
                                    noPadding
                                    style={{margin: "3px 5px"}}
                                    tooltip={`Show only projects which ${key === "products" ? "are" : "use"} ${tag}`}
                                    seeThrough
                                >
                                    <span className={"is-tiny-text propogate-size"}>
                                        {tag}
                                    </span>
                                </Term>
                            ))
                        ))}
                    </div>
                </div>
            </div>
            <CardContent className={containerClassName + rounded} style={{height: "4.5em"}}>
                {isLoading ? (
                    <>
                        <Skeleton animation="wave" height="1.5em" />
                        <Skeleton animation="wave" height="1.5em" width="80%" />
                        <Skeleton animation="wave" height="1.5em" width="30%" />
                    </>
                ) : (
                    <Column style={{display: isLoading ? "none" : "inherit"}} alignment={ContainerAlignment.Middle}>
                        <Row alignment={ContainerAlignment.Middle} style={{height: "4.5em"}}>
                            <Text
                                className="full-width"
                                customization={{text: { className: "full-width is-small"}}}
                                maxLines={3}
                                style={{margin: "3px 5px"}}
                            >
                                {description}
                            </Text>
                        </Row>
                    </Column>
                )}
                {children}
            </CardContent>
        </Card>
    );
};

export default Project;