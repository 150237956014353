import { ComponentProps } from "@models/types";
import { Box, Fab, Fade, useScrollTrigger } from '@mui/material';
import { withTooltip } from "./Tooltip";

export const backToTopAnchor = "back-to-top-anchor";

export const ScrollbarTopAnchor = ({className, style, children}: ComponentProps) => {
    return (
        <div className={className} id={backToTopAnchor} style={style}>
            {children}
        </div>
    );
};

export type ScrollToTopOptions = {
    ownerDocument?: Document,
    id?: string,
    location?: ScrollIntoViewOptions["block"]
};

export const scrollTo = (options?: ScrollToTopOptions) => {
    const anchor = (
        options?.ownerDocument || document
    ).querySelector('#' + (options?.id || backToTopAnchor));

    if (anchor) {
        anchor.scrollIntoView({
            behavior: 'smooth',
            block: (options?.location || 'center'),
            inline: 'start'
        });
    };
}

export const ScrollbarControls = ({className, style, children}: ComponentProps) => {
    const trigger = useScrollTrigger({
        target: window,
        disableHysteresis: true,
        threshold: 100
    });

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        scrollTo({
            ownerDocument: (event.target as HTMLDivElement).ownerDocument
        });
    };

    return (
        <Fade in={trigger}>
            <Box
                className={className}
                onClick={handleClick}
                role="presentation"
                sx={{ ...style, position: 'fixed', bottom: 16, right: 16, zIndex: 1000 }}
            >
                {children}
            </Box>
        </Fade>
    );
};

export const ScrollbarTopButton = withTooltip(({className, style, children}: ComponentProps) => {
    return (
        <Fab className={className} style={style} size="small" aria-label="scroll back to top">
            <span style={{fontSize: 20, marginLeft: 3}}>↑</span>
            {children}
        </Fab>
    );
});


export const ScrollbarToolbar = ({className, style, children}: ComponentProps) => {
    return (
        <>
            <ScrollbarTopAnchor />
            <ScrollbarControls className={className} style={style}>
                <ScrollbarTopButton tooltip="Scroll to top!" verbose={true} placement="left-start" />
                {children}
            </ScrollbarControls>
        </>
    );
};

export default ScrollbarToolbar;