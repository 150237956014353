import { useState} from 'react';
import { ComponentProps } from '@models';
import {
    AppBar
} from '@mui/material';
import {
    CollapseMenu,
    ContactMenu,
    NavigationBar,
    NavigationItem,
    NavigationItems,
    NavigationMenu,
    Settings,
    useBorder
} from '@app/components';
import { Flex } from '@aws-amplify/ui-react';
import { useChangeHook } from '@utilities';
import { useUser } from '@services/index';

export type NavigationProps = ComponentProps & {
    navigation?: NavigationItems
};

export const Navigation = ({navigation, className, style, children}: NavigationProps) => {
    const { user, updateUser } = useUser();
    const goldThemeClasses = classNames(useBorder("gold"), "tiny-frame");

    const [menuOpen, setMenuOpen] = useState(false);
    const [barOverflow, setBarOverflow] = useState(false);
    const [barCollapsed, setBarCollapsed] = useState(false);
    const [profileInitials, setProfileInitials] = useState(false);
    const [profileVisible, setProfileVisible] = useState(false);
    const [profileSubMenu, setProfileSubMenu] = useState(false);
    const [profileHidden, setProfileHidden] = useState(false);

    const handleSetOpen = useChangeHook((event) => {
        const state = event as boolean;
        if(!menuOpen) {
            setProfileSubMenu(false);
        }
        setMenuOpen(state);
    });
    const handleSetOverflow = useChangeHook(setBarOverflow);
    const handleSetCollapsed = useChangeHook(setBarCollapsed);
    const handleSetInitials = useChangeHook(setProfileInitials);
    const handleSetVisible = useChangeHook(setProfileVisible);
    const handleSetSubMenu = useChangeHook((event) => {
        const state = event as boolean;
        if(!profileSubMenu) {
            setMenuOpen(false);
        }
        setProfileSubMenu(state);
    });
    const handleSetHidden = useChangeHook(setProfileHidden);

    const handleProfileOnBack = () => {
        setMenuOpen(true);
    };

    const handleProfileOnClick = () => {
        setProfileSubMenu(!profileSubMenu);
    };

    const goldSelect = {
        select: {
            list: goldThemeClasses
        }
    };

    const navigationBarDefaultChildren = !profileVisible ? [
        <NavigationItem key={"Profile"} onClick={handleProfileOnClick}>
            Profile
        </NavigationItem>
    ] : [];

    return (
        <AppBar
            position="sticky"
            id="page-anchor"
            style={{...style, paddingTop: 20}}
            className={classNames("navigation-bar is-sticky", className)}
        >
            <Flex direction="column" alignItems="center" style={{margin: 5}}>
                <ContactMenu
                    collapsed={barCollapsed}
                    className={goldThemeClasses}
                />
                <Flex
                    direction="row"
                    alignItems="center"
                    gap={10}
                    className="full-width"
                    style={{padding: `0 ${120 + (!profileInitials ? 200 : 100)}px 0 5px`}}>
                    <NavigationMenu 
                        collapsed={barCollapsed}
                        overflow={barOverflow}
                        open={menuOpen}
                        setOpen={handleSetOpen}
                    >
                        <Settings
                            collapsed={!barOverflow}
                            user={user}
                            setUser={updateUser}
                            visible={barCollapsed}
                            setVisible={handleSetVisible}
                            setInitials={handleSetInitials}
                            customization={goldSelect}
                            selectOpen={profileSubMenu}
                            selectSetOpen={handleSetSubMenu}
                            onBack={handleProfileOnBack}
                            allHidden={profileHidden}
                        />
                    </NavigationMenu>
                    <NavigationBar
                        items={navigation}
                        open={menuOpen}
                        setOpen={handleSetOpen}
                        setOverflow={handleSetOverflow}
                        setHidden={handleSetHidden}
                        customization={{
                            overflow: {
                                menu: goldSelect
                            }
                        }}
                        style={barCollapsed ? {
                            width: 0,
                            height: 0,
                            marginTop: -80
                        } : {}}
                    >
                        {navigationBarDefaultChildren}
                    </NavigationBar>
                </Flex>
                <CollapseMenu
                    collapsed={barCollapsed}
                    setCollapsed={handleSetCollapsed}
                >
                    {children}
                </CollapseMenu>
            </Flex>
        </AppBar>
    );
};

export default Navigation;
