import React from 'react';
import { TableRow, TableCell, Flex, Text } from '@aws-amplify/ui-react';
import { InputLabel } from '@mui/material';
import { ComponentProps, HandleDeleteModel, CommentModel, UserModel } from '@models/types';
import Avatar from '@components/Avatar';
import DeleteButton from '@app/templates/DeleteButton';
import ViewHtml from './Html';

export type CommentProps = ComponentProps & {
    user: UserModel,
    comment: CommentModel,
    owned?: boolean,
    onDelete?: (event: HandleDeleteModel<CommentModel>) => Promise<void>
};

export const Comment = ({comment, user, owned = false, onDelete} : CommentProps) => {
    const rowUser = comment.user?.id === user?.id ? user : comment.user as UserModel;
    const timestamp = (dateTimeString?: string | null) => {
      const dateTime = new Date(dateTimeString || '');
      return dateTime.toLocaleString();
    };
    const components = [
      <Avatar key={"avatar-" + comment.id} user={rowUser} customization={{ text: { hidden: true } }}/>,
      <Flex key={"metadata-" + comment.id} display="inline-flex" direction="column">
        <InputLabel className={`show-whitespace`} style={{margin: "0 5px", padding: "0 5px", maxWidth: "50vw"}}>{rowUser?.name}</InputLabel>
        <span className="super-font">{timestamp(comment?.updatedAt)}</span>
      </Flex>
    ];

    return (
      <TableRow
        className="comment-color"
      >
        <TableCell className="comment-root comment-border-color nes-container is-rounded">
          <Flex direction="column" justifyContent="center" className="comment-metadata rpgui-container framed-golden-2">
            <Flex className={`comment-metadata-container full-width align-items-${owned ? "start" : "end"}`} direction="column">
              <Flex direction="row" className="fit-content">
                <div className={`comment-balloon nes-balloon from-${owned ? "left" : "right"} show-whitespace`} style={{textAlign:"start"}}>
                  <Flex direction="column">
                    <div className="rpgui-cursor-select select-text no-paragraph-margin">
                      <ViewHtml html={comment.description} />
                    </div>
                  </Flex>
                  { owned && (
                    <DeleteButton
                      onClick={() => onDelete?.({data: comment})}
                      className="comment-delete"
                      tooltip="Delete Comment"
                      type="comment"
                    />
                  )}
                </div>
              </Flex>
              <Flex direction="row" className="fit-content">
                <Text as="span" className={`align-self-${owned ? "start" : "end"}`} style={{flexShrink: 0}}>
                  {owned ? components : components.slice().reverse()}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </TableCell>
      </TableRow>
    );
};

export default Comment;