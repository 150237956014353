import { useState } from "react";
import { OptionValue } from "@components/Option";
import { v4 } from "uuid";
import { withTooltip } from "@components/Tooltip";

export type JSXOptionType = {
    name?: React.ReactNode
    value: OptionValue
};
  
export type HTMLPickerElement = HTMLInputElement & {
    value: OptionValue
};

export type ToolbarPickerProps = {
    value?: OptionValue,
    defaultValue?: OptionValue,
    overrideDisplay?: React.ReactNode,
    options: JSXOptionType[],
    onChange?: React.ChangeEventHandler<HTMLPickerElement>,
    style?: React.CSSProperties
};

export const ToolbarPicker = withTooltip(({value, defaultValue, overrideDisplay, options, onChange, style}: ToolbarPickerProps) => {
    const [internalValue, setValue] = useState<OptionValue>(defaultValue || options[0].value);
    const currentValue = value !== undefined ? value : internalValue;

    const handlePickerClick = (event: React.MouseEvent<HTMLSpanElement>) => {
        const element = event.currentTarget;
        const parent = element.parentElement;
        const sibling = element.nextSibling as HTMLElement;
        if(!parent?.classList.contains("ql-expanded")) {
            element.setAttribute("aria-expanded", "true");
            sibling?.setAttribute("aria-hidden", "false");
            parent?.classList.add("ql-expanded");
        } else {
            element.setAttribute("aria-expanded", "false");
            sibling?.setAttribute("aria-hidden", "true");
            parent?.classList.remove("ql-expanded");
        }
    };

    const handlePickerChange = (event: React.MouseEvent<HTMLSpanElement>) => {
        const element = event.currentTarget;
        const sibling = element?.parentElement?.previousSibling as HTMLElement;
        const value = element.getAttribute("data-value");
        onChange?.({ currentTarget: { value } } as React.ChangeEvent<HTMLPickerElement>);
        if(value && sibling) {
            setValue(value);
            sibling.click();
        }
    };

    const index = options.findIndex(x => x.value.toString() === currentValue.toString());
    const selected = options[index];
    const uuid = v4();

    return (
        <span className="ql-display ql-picker ql-custom" style={{width: "fit-content", ...style}}>
            <span
                className="ql-picker-label"
                tabIndex={0}
                role="button"
                aria-expanded="false"
                aria-controls={`ql-picker-options-${uuid}`}
                data-value={selected.value}
                data-label={selected.name}
                onClick={handlePickerClick}
            >
                {
                    overrideDisplay || (
                        <>
                            {selected.name} <svg viewBox="0 0 18 18"> <polygon className="ql-stroke" points="7 11 9 13 11 11 7 11"></polygon> <polygon className="ql-stroke" points="7 7 9 5 11 7 7 7"></polygon> </svg>
                        </>
                    )
                }
            </span>
            <span className="ql-picker-options" aria-hidden="true" tabIndex={-1} id={`ql-picker-options-${uuid}`}>
                {options?.map((option, i) => (
                    <span
                        key={option.value}
                        tabIndex={0}
                        role="button"
                        className={"ql-picker-item" + (i === index ? " ql-selected" : "")}
                        data-value={option.value}
                        data-label={option.name}
                        onClick={handlePickerChange}
                    >
                        {option.name}
                    </span>
                ))}
            </span>
        </span>
    );
});

export default ToolbarPicker;