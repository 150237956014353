import "./App.css";
import { Amplify, } from 'aws-amplify';
import config from './aws-exports';
import { Authenticator } from "@aws-amplify/ui-react";
import { SnackbarProvider } from 'notistack';
import SnackbarDefaultActions from "@components/SnackbarDefaultActions";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import classNames from 'classnames';
import Site from "@layouts/Site";
import TooltipProvider from "@components/TooltipProvider";
import '@cetra-games/rpgui-react/dist/rpgui.js';
import '@cetra-games/rpgui-react/dist/rpgui.css';
import { DynamicRouter } from "@components/Navigation/DynamicRouter";
import routes from './Routes.config.json';
import Layout from '@app/Layout';
import { QueryParametersProvider } from "@components/QueryParameters";

Amplify.configure(config);

global.classNames = classNames;

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#5f5f5f',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.secondary.main,
      main: '#868686',
      // dark: will be calculated from palette.secondary.main,
      // contrastText: will be calculated to contrast with palette.secondary.main
    },
    contrastThreshold: 4.5,
  },
});

const App = () => {  
  return (
    <SnackbarProvider maxSnack={3} action={SnackbarDefaultActions} autoHideDuration={5000}>
      <ThemeProvider theme={theme}>
        <Site navigation={routes}>
          <DynamicRouter
            parent={<Layout />}
            configuration={routes}
          />
        </Site>
      </ThemeProvider>
    </SnackbarProvider>
  );
};

const AppWithProviders = () => {
  return (
    <Authenticator.Provider>
      <TooltipProvider verbose={false}>
        <QueryParametersProvider>
          <App />
        </QueryParametersProvider>
      </TooltipProvider>
    </Authenticator.Provider>
  );
}

export default AppWithProviders;