import { ProjectAssets, ImageAssets, VideoAssets, DownloadAssets } from '@models';
import * as Title_01 from './Title_01.png';
import * as Vidoe_01 from './Video_01.mp4';

export const Title: ImageAssets = {
    Title_01
};

export const Images: ImageAssets = {
    
};

export const Videos: VideoAssets = {
    Vidoe_01
};

export const Downloads: DownloadAssets = {
    
};

const RPGMakerVXBattleSystem: ProjectAssets = {
    Title,
    Images,
    Videos,
    Downloads
};

export default RPGMakerVXBattleSystem;