import { useEffect } from 'react';
import { ComponentProps } from '@models';
import { Divider, Heading } from '@app/components';
import { toTitleCase } from '@utilities';

export type PageProps = ComponentProps & {
  name: string,
  title?: string,
  heading?: string,
};

export const Page = ({name, title, heading, className, style, children} : PageProps) => {
  if(title == null) title = toTitleCase(name);

  useEffect(() => {
    if(title) document.title = `Portfolio App - ${title}`;
  }, [title]);

  return (
    <div id={"page-" + name} className={classNames("page", className)} style={style}>
      <Divider style={{marginTop: 20}} />
      <Heading id="page-heading">{heading || title}</Heading>
      <Divider style={{marginBottom: 20}} />
      {children}
    </div>
  );
};

export default Page;