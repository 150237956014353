import { useState, useEffect, createRef } from 'react';
import { ComponentProps, GenericAsyncCallback, GenericCallback, HandleUpdateModel, ModalStatus, ReactEvent, TemporaryModel, UserModel } from '@models';
import {
    Avatar,
    Button,
    ButtonVariant,
    FileBrowse,
    Dialog,
    DialogContent,
    DialogActions,
    Select,
    SelectClasses,
    SelectHeader,
    NavigationItem,
    SelectChangeEvent,
    Login
} from '@app/components';
import { DeleteButton } from '@app/templates';
import {
    Flex,
    Text,
    TextField,
    Image,
} from '@aws-amplify/ui-react';
import { useRenderOnResize } from '@utilities';
import { useAuthentication } from '@services';

export type SettingsChangeEvent = ReactEvent<HTMLDivElement, boolean>;

export type SettingsCustomization = {
    select?: SelectClasses
};

export type SettingsProps = ComponentProps & {
    user: UserModel,
    setUser: GenericAsyncCallback<HandleUpdateModel<UserModel>, string>,
    collapsed?: boolean,
    visible?: boolean,
    setVisible?: GenericCallback<SettingsChangeEvent>,
    allHidden?: boolean,
    setInitials?: GenericCallback<SettingsChangeEvent>,
    selectOpen?: boolean,
    selectSetOpen?: GenericCallback<SettingsChangeEvent>,
    onBack?: GenericCallback<HTMLButtonElement>,
    customization?: SettingsCustomization
};

export const Settings = ({user, setUser, collapsed, visible, setVisible, allHidden, setInitials, selectOpen, selectSetOpen, onBack, customization, className, style, children}: SettingsProps) => {
    const { signOut, signedIn, tryLocalSignIn } = useAuthentication();
    const [settingsModal, setSettingsModal] = useState(ModalStatus.Closed);
    const [temporaryUserData, setTemporaryUserData] = useState({} as TemporaryModel<UserModel>);
    const [loginModal, setLoginModal] = useState(false);
    const settings = createRef<HTMLDivElement>();

    useRenderOnResize();

    const handleTryLogin = () => {
        if(tryLocalSignIn) {
            tryLocalSignIn();
        } else {
            setLoginModal(true);
        }
    };

    const handleLoginClose = () => {
        setLoginModal(false);
    };

    const handleOpenDialog = async () =>
    {
        setTemporaryUserData({} as any);
        setSettingsModal(ModalStatus.Open);
    };

    const handleEditUser = (field: string, value?: any) =>
    (event: (React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLButtonElement>)) =>
    {
        setTemporaryUserData({
            ...temporaryUserData,
            target: event.target,
            [field]: value !== undefined ? value : event.currentTarget.value
        });
    };

    const handleUpdateUser = async () => {
        try {
            if(!user?.id) return;
            setSettingsModal(ModalStatus.Closing);
            const { target, ...userChanges } = temporaryUserData;
            const newProfile = {
                id: user.id,
                name: userChanges.name != null ? userChanges.name : user.name,
                icon: userChanges.icon != null ? userChanges.icon : user.icon,
                iconFile: userChanges.iconFile
            };
            if(!!newProfile.icon) {
                newProfile.icon = `${user.id}/${target.files[0].name}`;
                newProfile.iconFile = target.files[0];
            }
            const error = await setUser({data: newProfile});
            if(!error) {
                setSettingsModal(ModalStatus.Closed);
            } else {
                console.error(error);
            }
        } catch (error) {
            console.error(error);
            setSettingsModal(ModalStatus.Open);
        }
    };

    const handleCloseDialog = async () => {
        setSettingsModal(ModalStatus.Closed);
    };

    const handleSelectSetOpen = (event: React.ChangeEvent<SelectChangeEvent>) => {
        selectSetOpen?.({ element: event.target.element, value: event.target.value });
    };

    const handleBack = (event: React.MouseEvent<HTMLButtonElement>) => {
        onBack?.(event.target as HTMLButtonElement);
    };

    const hidden = visible && (document.body.clientWidth < 540);

    const noText = visible && (document.body.clientWidth <= 580);

    useEffect(() => {
        if(settings.current) {
            setVisible?.({element: settings.current, value: !hidden});
        }
    }, [settings, hidden, setVisible, allHidden]);

    const initials = document.body.clientWidth < 780 && !collapsed;

    useEffect(() => {
        if(settings.current) {
            setInitials?.({element: settings.current, value: initials});
        }
    }, [settings, initials, setInitials]);

    const isLeftAligned = document.body.clientWidth <= 500 && (!visible && allHidden);

    const alignedStyles: React.CSSProperties | undefined = isLeftAligned ? {
        position: "absolute",
        top: 3,
        left: signedIn ? 0 : 15
    } : {
        paddingTop: 3,
        paddingRight: initials ? 0 : !allHidden ? 90 : 60
    };

    const alignedClasses = isLeftAligned 
        ? "on-left"
        : "on-right"
    + "navigation-overflow-menu-container";

    const items = signedIn ? [
        <NavigationItem key={"edit-profile"} onClick={handleOpenDialog}>Edit Profile</NavigationItem>,
        <NavigationItem key={"sign-out"} onClick={signOut}>Sign Out</NavigationItem>
    ] : [
        <NavigationItem key={"sign-in"} onClick={handleTryLogin}>Sign In</NavigationItem>
    ];

    return (
        <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={className}
            style={style}
            ref={settings}
        >
            <Select
                toggled={selectOpen}
                onToggle={handleSelectSetOpen}
                classes={{
                    ...customization?.select,
                    list: classNames("nes-container is-dark is-rounded", customization?.select?.list),
                    container: classNames(hidden ? "settings-menu-container" : "", customization?.select?.container, alignedClasses),
                }}
                style={alignedStyles}
            >
                <SelectHeader style={{ display: (hidden ? "none" : "inherit") }}>
                    <Avatar
                        user={user}
                        tooltip="Modify User Settings"
                        verbose={true}
                        className={classNames("has-box-shadow")}
                        customization={{text: { 
                            className: classNames(!collapsed ? "is-dark" : "", "no-wrap"),
                            compact: initials,
                            hidden: noText
                        }}}
                        style={{height: 24}}
                    />
                </SelectHeader>
                {hidden && <NavigationItem onClick={handleBack}>&lt; Back</NavigationItem>}
                {items}
            </Select>
            <Login onClose={handleLoginClose} open={loginModal} />
            <Dialog
                open={settingsModal !== ModalStatus.Closed}
                onClose={handleCloseDialog}
                fitContent={true}
            >
                <DialogContent style={{overflow: "auto", height: "52vh"}}>
                    <Text as="span" style={{fontWeight: "bold", color: "goldenrod"}}>User Name</Text>
                    <TextField
                        name="name"
                        label="User Name"
                        labelHidden
                        variation="quiet"
                        defaultValue={user.name}
                        onChange={handleEditUser("name")}
                        required
                    />
                    <Text as="span" style={{fontWeight: "bold", color: "goldenrod"}}>User Icon</Text>
                    <span className="amplify-flex amplify-input amplify-input--quiet fit-content">
                        {user.iconURL && temporaryUserData.icon === undefined ? (
                            <>
                            <Image
                                src={user.iconURL}
                                alt={`Icon preview for ${user.name}`}
                                style={{ width: 100 }}
                            />
                            <DeleteButton tooltip={"Delete Custom Icon"} onClick={handleEditUser("icon", "")} className="align-self-start rpgui-cursor-point" />
                            </>
                        ) : (
                            <FileBrowse
                                name="image"
                                accept="image/png, image/jpeg"
                                className="align-self-end"
                                onChange={handleEditUser("icon")}
                                style={{minWidth: "220px"}}
                            />
                        )}
                    </span>
                </DialogContent>
                <DialogActions>
                    <Button
                        loading={settingsModal === ModalStatus.Closing}
                        variant={ButtonVariant.Secondary}
                        autoFocus onClick={handleUpdateUser}
                    >
                        Save
                    </Button>
                    <Button onClick={handleCloseDialog} style={{marginLeft: "20px", height: "3em"}}>
                    Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            {children}
        </Flex>
    );
};

export default Settings;
