import { ProjectData, Tags, Languages, Software, ProjectStatus, Products, Contributors, OrientationType } from '@structures/ProjectData';
import { Title, Images } from '@projects/Cetra Tools';
import { FileType } from '@models';

export const ImageAssets = Object.values(Images);

export const CetraTools: ProjectData = {
    summary: {
        id: "CT",
        title: "Cetra Tools",
        orientation: OrientationType.Portrait,
        aliases: ["CT"],
        filter: { settings: { pixelSize: 1 }},
        version: 1.0,
        status: ProjectStatus.Completed,
        initial: new Date(2015, 11, 1),
        release: new Date(2016, 0, 1),
        previews:[{
            file: Title.Title_01,
            type: FileType.Image,
            description: "This is the main window for the tool.",
            caption: `In App display of the floating utility window.`
        }],
        tags: {
            [Tags.Products]: [
                Products.Tools,
                Products.Films
            ],
            [Tags.Software]: [
                Software.VS
            ],
            [Tags.Languages]: [
                Languages.Max
            ]
        },
        description: "A utility tool which enhances the capabiilities of 3DS Max."
    },
    details: {
        description: "A tool inside of 3DS Max, which allows for some powerful 3DS Max enhancements."
            + "<br/><br/>"
            + "The tool was used to assist in the production of a indie short film titled: Ginger & Snapper.",
        gallery: [{
            file: ImageAssets[0],
            type: FileType.Image,
            description: "An image of a window showing 1 list properties button and 3 buttons titled: model, animation, and unwrap.",
            caption: "The main tool window listing the individual categories for 3D model & animation creation assistance that this tool provides."
        }, {
            file: new URL("https://www.youtube.com/embed/EXVTQZWwuQA?si=6lkpecf6dDulLnNU?controls=0"),
            type: FileType.Video,
            description: "",
            caption: "Movie trailer"
        }, {
            file: new URL("https://www.youtube.com/embed/Jl1dAXbPq5k?si=_VczRZd95G3ZzgUw?controls=0"),
            type: FileType.Video,
            description: "",
            caption: "The full short film."
        }],
        contributions: [{
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Tools Engineer",
                involvement: "Developed all of the tools logic as discovered through requirements gathering with artists.",
                achievements: [
                    "Developed several model tools, such as: mirroring verticies.",
                    "Developed several animation tools, such as: reversing a selected timeline of key frame animations.",
                    "Developed several unwrapping tools, such as: auto unwrapping capabilities."
                ]
            }, {
                title: "Technical Advisor",
                involvement: "Served as an advisor for all technical matters during production, removing roadblocks as necessary.",
                achievements: [
                    "Assisted artist by providing guidance on best practies for performing: unwrapping, animation and sculpting.",
                    "Provided feedback as part of iterative development during the course of the film."
                ]
            }]
        }]
    },
};

export default CetraTools;