import React, { DOMAttributes, ButtonHTMLAttributes } from "react";
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';
import { ComponentProps, InputComponentProps, ThemeVariant } from '@models/types';
import { Spinner } from './Spinner';

export enum ButtonTheme {
    NES = "nes-btn",
    RPG = "rpgui-button",
    Modern = ""
};

export enum ButtonVariant {
    Primary = "primary",
    Secondary = "secondary",
    Success = "success",
    Error = "error",
    Warning = "warning",
    Info = "info",
    Link = "text"
};

export enum ButtonAction {
    Submit = "submit",
    Reset = "reset",
    Button = "button"
};

const themes = {
    [ButtonTheme.RPG]: {
        [ButtonVariant.Primary]: "",
        [ButtonVariant.Secondary]: "golden"
    }
} as ButtonThemeVariant;

export type ButtonThemeVariant = ThemeVariant<ButtonTheme, ButtonVariant>;

export type OnClickType = DOMAttributes<HTMLButtonElement>["onClick"];

export type ButtonActionType = ButtonHTMLAttributes<HTMLButtonElement>["type"]; 

export type ButtonProps = ComponentProps & InputComponentProps & {
    theme?: ButtonTheme,
    action?: ButtonActionType,
    onClick?: OnClickType,
    loading?: boolean,
    variant?: ButtonVariant
};

export const Button = ({theme = ButtonTheme.RPG, action = ButtonAction.Button, onClick, loading, variant = ButtonVariant.Primary, autoFocus, disabled, className, style, children, ...rest} : ButtonProps) => {
    const classTheme = themes[theme]?.[variant] || "";

    const Loading = <Spinner loading={loading || false}>{children}</Spinner>

    const ButtonComponent = (props: MuiButtonProps) => {
        return (
            theme === ButtonTheme.Modern 
            ? <MuiButton variant="contained" {...props}>{Loading}</MuiButton>
            : <button className={classNames(theme, className, classTheme, variant === ButtonVariant.Link && "simple")} {...props}>
                {Loading}
            </button>
        )
    };

    return ButtonComponent({type: action, onClick, style: style, color: (variant !== ButtonVariant.Link ? variant : "primary"), variant: "text", autoFocus, disabled, ...rest});
};

export default Button;