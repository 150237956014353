import { ComponentProps } from '@models/types';
import Social from './Social';

export type DonationProps = ComponentProps & {
  username: string
};

export const Donation = ({username, className, style, children} : DonationProps) => {
  return (
    <Social
      className={classNames("animation-wiggle-once animation-wiggle-hover", className)}
      style={style}
      type={"donation"}
      theme={"rpg"}
      link={new URL(`https://www.ko-fi.com/${username}`)}
    >
      {children}
    </Social>
  );
};

export default Donation;