import React, { useState } from 'react';
import { Button } from '@components/Button';

export const FileBrowse = (props: any) => {
    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);
    const [fileUploaded, setFileUploaded] = useState('No file selected.');
    
    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = (event: any) => {
        hiddenFileInput.current?.click();
    };
    
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file 
    const handleChange = (event: any) => {
        const fileUploaded = event.target.files[0];
        setFileUploaded(fileUploaded?.name);
        props?.onChange?.(event);
    };
    
    return (
        <span className="amplify-flex amplify-flex-center">
            <Button style={props.style} onClick={handleClick}>
                Browse...
            </Button>
            {
                fileUploaded
            }
            <input
                {...props}
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{...props.style, display: 'none'}} /* Make the file input element invisible */
            />
        </span>
    );
};

export default FileBrowse;