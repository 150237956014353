import { GenericCallback, ComponentChildrenProps } from '@models';
import {
    OverflowMenu,
    OverflowChangeEvent,
    OverflowMenuCustomization,
    SelectChangeEvent,
    NavigationItem,
    NavigationMenuChangeEvent,
    RoutesConfiguration
} from '@components';
import { useBooleanChangeEvent } from '@utilities';

export type NavigationItems = RoutesConfiguration;

export type NavigationBarCustomization = {
    overflow?: OverflowMenuCustomization
};

export type NavigationBarProps = ComponentChildrenProps & {
    items?: NavigationItems,
    open: boolean,
    setOpen: GenericCallback<NavigationMenuChangeEvent>,
    setOverflow: GenericCallback<OverflowChangeEvent>,
    setHidden?: GenericCallback<OverflowChangeEvent>,
    customization?: NavigationBarCustomization
};

export const NavigationBar = ({items, open, setOpen, setOverflow, setHidden, customization, className, style, children}: NavigationBarProps) => {
    const [_open, _setOpen] = useBooleanChangeEvent(open, setOpen);

    const handleExpand = (event: React.ChangeEvent<SelectChangeEvent>) => {
        // FIXME: This needs to be fixed, figure out why both calls are necessary, should only use the second line.
        _setOpen({ currentTarget: event.target.element } as React.MouseEvent<HTMLDivElement>);
        setOpen({element: event.target.element as unknown as HTMLButtonElement, value: event.currentTarget.value});
    };

    const handleOverflow = (event: React.ChangeEvent<OverflowChangeEvent>) => {
        setOverflow({element: event.target.element, value: event.target.value});
    };

    const handleHidden = (event: React.ChangeEvent<OverflowChangeEvent>) => {
        setHidden?.({element: event.target.element, value: event.target.value});
    };

    const overflow = customization?.overflow;

    return (
        <>
            <OverflowMenu
                expanded={_open}
                className={classNames("navigation-overflow-menu-toolbar", className)}
                style={style}
                margin={-90}
                customization={{
                    ...overflow,
                    menu: {
                        ...overflow?.menu,
                        container: classNames("navigation-overflow-container", overflow?.menu?.container),
                        menu: classNames("navigation-overflow-menu", overflow?.menu?.menu),
                        button: {
                            ...overflow?.menu?.button,
                            classes: classNames("navigation-overflow-menu-button sticky-fixed simple", overflow?.menu?.button?.classes)
                        },
                        select: {
                            ...overflow?.menu?.select,
                            header: classNames("navigation-overflow-menu-header", overflow?.menu?.select?.header),
                            item: classNames("navigation-overflow-menu-item", overflow?.menu?.select?.item),
                            container: classNames("navigation-overflow-menu-container", overflow?.menu?.select?.container),
                            list: classNames("nes-container is-dark is-rounded", overflow?.menu?.select?.list)
                        }
                    }
                }}
                onExpand={handleExpand}
                onOverflow={handleOverflow}
                onHidden={handleHidden}
            >
                {(children || [])
                    .concat((items || [])
                    .filter(item => !item.hidden && !item.path.includes("*"))
                    .map(item => {
                        const name = item.display?.name || item.component;
                        return (
                            <NavigationItem
                                key={item.path + " " + name}
                                link={item.path}
                                aliases={item.aliases}
                                className={item.className}
                            >
                                {name}
                            </NavigationItem>
                        );
                    }
                ))}
            </OverflowMenu>
        </>
    );
};

export default NavigationBar;
