import { createRef } from 'react';
import { Popover } from '@mui/material';
import { ComponentProps, GenericCallback } from '@models';
import Divider from './Divider';
import Button, { ButtonVariant } from './Button';

export type ConfirmationProps = ComponentProps & {
    anchor?: HTMLElement,
    onInit?: GenericCallback<HTMLDivElement>,
    onClose?: GenericCallback<boolean>
};

export const Confirmation = ({anchor, onInit, onClose, className, style, children} : ConfirmationProps) => {
    const component = createRef<HTMLDivElement>();

    const handleInit = () => {
        if(component.current) {
            onInit?.(component.current);
        }
    };
    
    const handleClose = (confirmed: boolean) => () => {
        onClose?.(confirmed);
    };


    const open = Boolean(anchor);

    return (
        <Popover
            open={open}
            anchorEl={anchor}
            onLoad={handleInit}
            onClose={handleClose(false)}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            ref={component}
            className={classNames(className, "rpgui-content")}
            style={{fontSize: "12px", zIndex: 12000, ...style}}
            classes={{
                paper: "rpgui-container framed-golden pixelated"
            }}
        >
            <span>
                {children}
            </span>
            <Divider />
            <div style={{display: "inline-flex", justifyItems: "center"}}>
                <Button variant={ButtonVariant.Secondary} onClick={handleClose(true)} style={{height: 48}}>Confirm</Button>
                <Button onClick={handleClose(false)} style={{height: 42, margin: "auto"}}>Cancel</Button>
            </div>
        </Popover>
    );
};

export default Confirmation;
