import { ComponentProps } from "@models/types"
import { ToolbarButton } from "./ToolbarButton";

export const HeartEmoji = () => <div>♥</div>;

export const InsertHeartEmoji = ({className, style, children}: ComponentProps) => {
    return (
        <ToolbarButton className={className} style={style} module="insert-heart" tooltip="Heart Emoji"><HeartEmoji />
            {children}
        </ToolbarButton>
    );
};

export default InsertHeartEmoji;
