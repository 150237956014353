import React from 'react';
import { IconButton as MuiIconButton } from '@mui/material';
import { ComponentProps } from '@models/types';
import { withTooltip } from '@app/components/Tooltip';
import { Icon } from '@app/components/Icon';

export type IconButtonProps = ComponentProps & {
    icon: string,
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
    onHoverStart?: React.MouseEventHandler<HTMLButtonElement>,
    onHoverStop?: React.MouseEventHandler<HTMLButtonElement>
};

export const IconButton = withTooltip(({icon, onClick, onHoverStart, onHoverStop, className, style, children} : IconButtonProps) => {
  return (
    <MuiIconButton style={style} onClick={onClick} onMouseEnter={onHoverStart} onMouseLeave={onHoverStop} className={className}>
      <Icon icon={icon}>{children}</Icon>
    </MuiIconButton>
  );
});

export default IconButton;