import { ProjectData, Tags, Languages, Software, ProjectStatus, Products, Contributors } from '@structures/ProjectData';
import { Title, Videos } from '@projects/RPG Maker VX - Custom Battle System';
import { FileType } from '@models';

export const VideoAssets = Object.values(Videos);

export const RPGMakerVXBattleSystem: ProjectData = {
    summary: {
        id: "RPGVXBS",
        title: "RPG Maker VX Battle System",
        aliases: ["RPGVX", "Battle System", "RPGVXBS"],
        filter: { settings: { pixelSize: 1 }},
        version: 1.00,
        status: ProjectStatus.Completed,
        initial: new Date(2010, 7, 5),
        release: new Date(2010, 9, 2),
        previews:[{
            file: Title.Title_01,
            type: FileType.Image,
            description: "A hybrid front and side view battle system for a JRPG style game.",
            caption: `Preview of the battle system during the "Action Phase".`
        }],
        tags: {
            [Tags.Products]: [
                Products.Games,
                Products.Tools
            ],
            [Tags.Software]: [
                Software.RPG
            ],
            [Tags.Languages]: [
                Languages.Ruby
            ]
        },
        description: "A unique RPG battle system which combines a Front and Side view, along with phases."
    },
    details: {
        description: "An alternative to using Front or Side view battle systems: this one does both!"
            + "<br/><br/>"
            + "Makes generating actions for each skill/item/command much easier by utilizing action sequences that can be created without programming experience."
            + "<br/><br/>"
            + "Originally intended to be used as a follow up for my game DBZ Raging Pride, but it works flawlessly as a standalone system.",
        gallery: [{
            file: VideoAssets[0],
            type: FileType.Video,
            description: "An video showing the battle system in action, along with all phases and text on how to make use of it.",
            caption: "A technical demo of the battle system and how it can be used."
        }],
        contributions: [{
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Programmer",
                involvement: "Developed all of the battle systems logic and flow, along with structures to support its data.",
                achievements: [
                    "Developed a data structure which utilizes sequences to organize the flow of battle actions.",
                    "Developed a battle system complete with phases of flow.",
                    "Integrated data structures into RPG Maker by utilizing notes.",
                    "Designed & developed the entire user interface."
                ]
            }]
        }]
    },
};

export default RPGMakerVXBattleSystem;