import {
  Link,
  Page,
  Section
} from "@app/components";

export const Home = () => {
  const googleSearchLink = "https://www.google.com/search?q=stay+a+while+and+listen&hl=en&tbm=isch&sxsrf=AJOqlzW64H9xvXjvexGwnPLPAnPLFnhJJQ:1677963776730&source=lnms&sa=X"
  const tcrfLink = "https://tcrf.net/index.php?title=Dragon_Ball_Z%3A_Super_Saiya_Densetsu";

  return (
    <Page name="home" heading="Welcome net traveler!">
      <Section name="content">
        <span
          style={{
            width: "clamp(400px, 50vw, 100%)",
            fontSize: "clamp(10px, 2vw, 20px)",
            display: "inline-block"
          }}
        >
          <p>
            Since you've come such a long way, why don't you <Link url={googleSearchLink} popOut={true}>stay a while and listen</Link>?
          </p>
          <p>
            My name is Dino Suvalic, online I usually go by "MakoInfused".
            I’m an experienced professional lead software engineer.
            I went to college for game development and then started working on games. However,
            my career path took me on many side quests through other industries.
          </p>
          <p>
            I’ve worked on all kinds of software products; I've solved many puzzles and smashed
            plenty of bugs and fiends! I have experience developing games, websites, 3-D films,
            and applications from the ground up. I have a strong problem solving mind, with an
            expertise in multiple programming languages and familiarity with all kinds of tools
            and development software.
          </p>
          <p>
            I have a lot of experience working with clients and product teams, as well as leading other engineers.
            As a result: I'm strong technically and work well with others. I care just as much about developing 
            excellent code as I do building efficient autonomous teams, all while fostering a positive human experience.
          </p>
          <p>
            I have built and deployed several web applications using public cloud providers, coded several 
            front-ends and back-ends using various technologies. I've built invoice systems from the ground up
            for large financial tech companies.
          </p>
          <p>
            I have worked on and shipped several independent games, both by myself and as part of a team. 
            I regularly develop my own tools and utilities for Unity and my own custom game engine, 
            as well as many older engines in my past.
          </p>
          <p>
            I’ve worked on reverse engineering games, building utilities and applications as part of 
            internet communities to help others do the same; I’ve even <Link url={tcrfLink} popOut={true}>published articles about the topic</Link>!
          </p>
        </span>
      </Section>
    </Page>
  );
};

export default Home;