import { ProjectData, Tags, OtherLanguages, OtherSoftware, ProjectStatus, Products, Contributors, OrientationType } from '@structures/ProjectData';
import { Downloads, Title, Images } from '@projects/Chuunin Exams - Forest Of Death';
import { FileType } from '@models';

export const ImageAssets = Object.values(Images);

export const DownloadAssets = Object.values(Downloads);

export const ChuuninExamsForestOfDeath: ProjectData = {
    summary: {
        id: "CEFOD",
        title: "Chuunin Exams - Forest of Death",
        orientation: OrientationType.Portrait,
        filter: { settings: { pixelSize: 2 }},
        version: 1.07,
        status: ProjectStatus.Completed,
        initial: new Date(2009, 7),
        release: new Date(2010, 0),
        previews:[{
            file: Title.Title_01,
            type: FileType.Image,
            description: "This is how the Map Title appears in the Warcraft 3 engine.",
            caption: `In game view of Map Title Screen.`
        }],
        tags: {
            [Tags.Products]: [
                Products.Games
            ],
            [Tags.Software]: [
                OtherSoftware.World
            ],
            [Tags.Languages]: [
                OtherLanguages.JASS
            ]
        },
        description: "A Naruto themed role-playing battle arena game!"
    },
    details: {
        description: "The game takes place during the Chuunin Exams of the original Naruto series."
            + "<br/><br/>"
            + "Players can choose to play on a team as:"
            + "<ul><li>Naruto, Sasuke and Sakura.</li><li>Choji, Shikamaru and Ino.</li><li>Neji, Rock Lee and Tenten.</li><li>Kiba, Shino and Hinata.</li><li>Gaara, Kankuro and Temari.</li></ul>"
            + "The gameplay features 3 opposing ninja teams, each of 3 players, fighting for sacred scrolls."
            + "<br/><br/>"
            + "Each team starts with 1 scrolls and needs 2 in order to enter the final phase of the game: a deathmatch. Scrolls are gathered by stealing them from opposing teams."
            + "<br/><br/>"
            + "Along the way players can work together to take down various threats in the forest in order to acquire rate items or money which can be used to purchase weapons, armor items and traps."
            + "<br/><br/>"
            + "Teams can battle each other for larger experience or team up to take down unsuspecting players that get caught without their teammates!"
            + "<br/><br/>"
            + "Enormous threats such as Orchimaru and Kabuto also lurk the forest, they possess great abilities and power that can quickly overcome entire teams, so be prepared!"
            + "<br/><br/>"
            + "Once a team acquires 2 scrolls between the 3 teammates, they can proceed to the finals where they can no longer train, but are gauranteed a spot to win it all and become real ninjas!"
            + "<br/><br/>"
            + "The finals consist of 1v1 battles, while allies and enemies watch, the winner of each round proceeds to the next where they can win it all for their team!",
        gallery: [{
            file: ImageAssets[0],
            type: FileType.Image,
            description: "",
            caption: "All Playable Characters and Bosses lined up in front of the Ninja Tower."
        }, {
            file: DownloadAssets[0],
            type: FileType.Download,
            description: `<button class="rpgui-button">Download</button>`,
            caption: "Last official production/protected release of the game."
        }, {
            file: DownloadAssets[1],
            type: FileType.Download,
            description: `<button class="rpgui-button">Download</button>`,
            caption: "Last official internal/unprotected build of the game."
        }],
        contributions: [{
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Game Designer",
                involvement: "Designed the Game world, put together documentation for the assets, logic, maps and world.",
                achievements: [
                    "Designed the core gameplay mechanics",
                    "Created Game Design Documents.",
                    "Created Technical Design Documents.",
                    "Created Asset Design Documents."
                ]
            }]
        }, {
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Level Designer",
                involvement: "Designed and built out a world for the game to take place, while keeping it consistent with the series lore and logic.",
                achievements: [
                    "Created a massive map for the game to take place.",
                    "Designed over 100 unique abilities spread out across 20 wanna-be ninjas and their cohorts.",
                    "Setup complex triggers for scroll drops and combining them in the inventory, as well as deathmatch events."
                ]
            }]
        }, {
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Game Programmer",
                involvement: "Developed all of the games logic in accordance with the rules of the game.",
                achievements: [
                    "Created custom code for 15 different characters and their unique abilities using JASS.",
                    "Created custom code for 20 unique items and 15 unique enemies.",
                    "Resolved desynch issues which plagued some games built using the Warcraft 3 engine.",
                ]
            }]
        }]
    },
};

export default ChuuninExamsForestOfDeath;