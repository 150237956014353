export type WaitForElementOverload = {
    <K extends keyof HTMLElementTagNameMap>(selector: K, parent?: HTMLElement): Promise<unknown>;
    <K extends keyof SVGElementTagNameMap>(selector: K, parent?: HTMLElement): Promise<unknown>;
    (param: string, parent?: HTMLElement): Promise<unknown>;
};

export const waitForElement: WaitForElementOverload = (selector: string, parent?: HTMLElement) => {
    const parentElement = parent || document.body;
    return new Promise(resolve => {
        if (parentElement.querySelector(selector)) {
            return resolve(parentElement.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (parentElement.querySelector(selector)) {
                resolve(parentElement.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(parentElement, {
            childList: true,
            subtree: true
        });
    });
};

export default waitForElement;