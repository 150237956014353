import { ComponentProps } from "@models";

export type LinkProps = ComponentProps & {
    url: string,
    popOut?: boolean
};

export const Link = ({url, popOut, className, style, children}: LinkProps) => {
    const props = popOut ? { target:"_blank", rel:"noreferrer" } : {};
    return (
        <a className={className} style={style} href={url} {...props} >
            {children}
        </a>
    );
};

export default Link;
