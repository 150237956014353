import { ComponentProps, ReactEvent, GenericCallback } from '@models';
import {
    Button,
    ButtonVariant,
    Divider
} from '@components';
import { withTooltip } from '@components/Tooltip';
import { useBooleanToggleEvent } from '@utilities';

export type CollapseChangeEvent = ReactEvent<HTMLButtonElement, boolean>;

export type CollapseMenuProps = ComponentProps & {
    collapsed?: boolean,
    setCollapsed?: GenericCallback<CollapseChangeEvent>
};

export const CollapseIcon = withTooltip(() => <span style={{fontSize: 40, height: 35, lineHeight: "16px"}}>⤻</span>);
export const ExpandIcon = withTooltip(() => <span style={{fontSize: 40, height: 35, lineHeight: "16px"}}>⤺</span>);

export const CollapseMenu = ({collapsed, setCollapsed, className, style, children} : CollapseMenuProps) => {
    const [_collapsed, _setCollapsed] = useBooleanToggleEvent(collapsed, setCollapsed);

    return (
        <>
            { !_collapsed && (
                <>
                    {children}
                    <Divider className="full-width" style={{margin: 0}} />
                </>
            )}
            <div style={{position: "absolute", bottom: -16}}>
                <Button className={className} style={style} variant={ButtonVariant.Link} onClick={_setCollapsed}>
                    {!_collapsed
                        ? <CollapseIcon tooltip="Collapse Menu" verbose={true} />
                        : <ExpandIcon tooltip="Expand Menu" verbose={true} />
                    }
                </Button>
            </div>
        </>
    );
};

export default CollapseMenu;
