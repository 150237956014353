import { GenericCallback } from '@models';
import {useChangeEvent, ChangeHandler} from './ChangeEvent';

export const useBooleanEvent = (handler: ChangeHandler<boolean | undefined>, value?: boolean, setValue?: GenericCallback<any>) => {
    return useChangeEvent(value, false, handler, setValue);
};

export const useBooleanChangeEvent = (value?: boolean, setValue?: GenericCallback<any>) => {
    return useBooleanEvent((value) => value, value, setValue);
};

export const useBooleanToggleEvent = (value?: boolean, setValue?: GenericCallback<any>) => {
    return useBooleanEvent((value) => !value, value, setValue);
};

export default useChangeEvent;