import { ProjectData, Tags, Languages, Software, ProjectStatus, Products, Contributors, OtherSoftware, OtherLanguages } from '@structures/ProjectData';
import { Title, Images } from '@projects/Paper Flyer Adventures';
import { FileType } from '@models';

export const ImageAssets = Object.values(Images);

export const PaperFlyerAdventures: ProjectData = {
    summary: {
        id: "PFA",
        title: "Paper Flyer Adventures",
        aliases: ["PFA"],
        filter: { settings: { pixelSize: 3 }},
        version: 1.0,
        status: ProjectStatus.Completed,
        initial: new Date(2015, 6, 1),
        release: new Date(2016, 0, 10),
        previews:[{
            file: Title.Title_01,
            type: FileType.Image,
            description: "A paper airplane is visible soaring through a library and about to come into contact with a gold coin.",
            caption: `Core gameplay elements of the game.`
        }],
        tags: {
            [Tags.Products]: [
                Products.Games
            ],
            [Tags.Software]: [
                Software.VS,
                Software.Unity,
                OtherSoftware.Cocos
            ],
            [Tags.Languages]: [
                Languages.CSharp,
                OtherLanguages.OBJC
            ]
        },
        description: "A wholesome game about a boy playing with a paper airplane to pass the time."
    },
    details: {
        description: "This arcade style time waster plane game is guaranteed to challenge you and make time fly. So challenging that we haven't beat it yet!"
            + "<br/><br/>"
            + "Fly your paper plane through 3 different scenes and dodge obstacles to keep you in the action. Collect coins to unlock acheivements and activate special power ups."
            + "<br/><br/>"
            + "This is a free full game version with no in-app purchases. 3 levels of increasing difficulty to keep you challenged with bosses on the 2nd and 3rd levels to beat.",
        gallery: [{
            file: new URL("https://www.youtube.com/embed/WCXC2VQqtzI?controls=0"),
            type: FileType.Video,
            description: "",
            caption: "The official gameplay trailer."
        }, {
            file: ImageAssets[0],
            type: FileType.Image,
            description: "An image of a paper airplane soaring through a library.",
            caption: "The first level of the game."
        }, {
            file: ImageAssets[1],
            type: FileType.Image,
            description: "An image of a paper airplane soaring through a city park.",
            caption: "The second level of the game."
        }, {
            file: ImageAssets[2],
            type: FileType.Image,
            description: "An animated image of a paper airplane soaring through a library.",
            caption: "Flying through the first level."
        }, {
            file: ImageAssets[3],
            type: FileType.Image,
            description: "An animated image of a paper airplane soaring through a city park.",
            caption: "Flying through the second level."
        }, {
            file: ImageAssets[4],
            type: FileType.Image,
            description: "An animated image of a paper airplane soaring through a ski resort.",
            caption: "Flying through the third level."
        }],
        contributions: [{
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Game Programmer",
                involvement: "Developed the entire game from scratch, based on a small prototype that was created in Coco2D & Objective C.",
                achievements: [
                    "Reviewed original code and converted it from Objective C to C#.",
                    "Developed parallax background system and optimized through several iterations for mobile platforms.",
                    "Developed remainder of systems such as level system in order to scale game to feature multiple levels, bosses, and collectable powerup items!",
                    "Successfully delievered final build for Android, and IOS."
                ]
            }]
        }]
    },
};

export default PaperFlyerAdventures;