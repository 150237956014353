import React, { useEffect, useState, useRef, useCallback } from 'react';
import useClasses from './Style';
import { ComponentProps, GenericCallback, ReactComponents, ReactEvent } from '@models';
import { dataIndexer, MoreMenu, MoreMenuCustomization, indexer, VisibilityMap } from './MoreMenu';
import { SelectChangeEvent } from './Select';

const overflowMenuStyles = () => ({
    visible: {
        order: 0,
        visibility: "visible",
        opacity: 1
    },
    invisible: {
        order: 100,
        visibility: "hidden",
        pointerEvents: "none"
    },
    toolbarWrapper: {
        display: "flex",
        flexShrink: 0,
        padding: "0 20px",
        width: "100%",
        height: "1.5em"
    },
    overflowStyle: {
        order: 99,
        height: 24
    }
});

export type OverflowChangeEvent = ReactEvent<HTMLDivElement, boolean>;

export type OverflowMenuCustomization = {
    menu?: MoreMenuCustomization
};

export type OverflowMenuProps = ComponentProps & {
    expanded?: boolean,
    onExpand?: React.ChangeEventHandler<SelectChangeEvent>,
    onOverflow?: React.ChangeEventHandler<OverflowChangeEvent>,
    onHidden?: React.ChangeEventHandler<SelectChangeEvent>,
    maxSize?: number,
    maxSizes?: number[],
    margin?: number,
    children: ReactComponents,
    customization?: OverflowMenuCustomization,
    visibleChildren?: GenericCallback<number>,
    tooltip?: string
};

export const OverflowMenu = ({ expanded, onExpand, onOverflow, onHidden, maxSize, maxSizes, margin, className, style, children, customization, visibleChildren, tooltip } : OverflowMenuProps) => {
    const overflowMenuClasses = useClasses(overflowMenuStyles);
    const navRef = useRef<HTMLDivElement>(null);
    const [visibilityMap, setVisibilityMap] = useState<VisibilityMap>({});

    const handleIntersection = useCallback((entries: any[]) => {
        const updatedEntries = {} as VisibilityMap;
        entries.forEach((entry: IntersectionObserverEntry) => {
            const element = entry.target as HTMLElement;
            const id = element.dataset[indexer];
            if(id) {
                if(maxSizes) {
                    const index = parseInt(id);
                    updatedEntries[id] = index < maxSizes.length && document.documentElement.clientWidth > maxSizes[index];
                } else {
                    if (entry.isIntersecting) {
                        updatedEntries[id] = true;
                    } else {
                        updatedEntries[id] = false;
                    }
                }
            }
        });
                
        const differentMembers = (arr1: any[], arr2: any[]) => {
            if(arr1?.length !== arr2?.length) return true;
            return arr1.some((_value, index) => {
                return arr1[index][1] !== arr2[index][1];
            });
        };

        let value;
        value = Object.values(visibilityMap).some((v, i) => v === false && i < entries.length);
        onOverflow?.({ currentTarget: { value }, target: { value } } as React.ChangeEvent<SelectChangeEvent> );
        value = Object.values(visibilityMap).every((v) => v === false);
        onHidden?.({ currentTarget: { value }, target: { value } } as React.ChangeEvent<SelectChangeEvent> );

        if(differentMembers(Object.entries(updatedEntries).sort(), Object.entries(visibilityMap).sort())) {
            const visible = Object.values(updatedEntries).filter(v => v === true);
            visibleChildren?.(visible.length);
            setVisibilityMap((prev: VisibilityMap) => ({
                ...prev,
                ...updatedEntries
            }));
        }
    }, [visibilityMap, onOverflow, onHidden, maxSizes, visibleChildren]);

    const navWidth = (navRef.current?.getBoundingClientRect()?.width || 1);

    useEffect(() => {
        const width = margin || (navWidth > (maxSize || 0) ? 0 : -20);

        const observer = new IntersectionObserver(handleIntersection, {
            rootMargin: `0px ${width}px 0px 0px`,
            root: navRef.current,
            threshold: 1
        });
    
        // We are addting observers to child elements of the container div
        // with ref as navRef.
        Array.from([].slice.call(navRef.current?.children)).forEach((item: any) => {
            if (item.dataset[indexer]) {
                observer.observe(item);
            }
        });
        return () => observer.disconnect();
    }, [maxSize, navWidth, handleIntersection, margin]);

    return (
        <div className={classNames(className, overflowMenuClasses.toolbarWrapper)} ref={navRef} style={style}>
            {React.Children.map(children, (child, index) => {
                const Child = child.type;
                return <Child {...child.props} className={classNames(child.props.className, {
                    [overflowMenuClasses.visible]: !!visibilityMap[index],
                    [overflowMenuClasses.invisible]: !visibilityMap[index]
                }, !visibilityMap[index] ? "ql-hidden" : "")} {...{[dataIndexer]: index}}>
                    {child.props.children}
                </Child>
            })}
            <MoreMenu
                expanded={expanded}
                visibilityMap={visibilityMap}
                className={overflowMenuClasses.overflowStyle}
                customization={customization?.menu}
                onExpand={onExpand}
                tooltip={tooltip}
            >
                {children}
            </MoreMenu>
        </div>
    );
};