import { Color } from '@models';
import { useClasses } from '@app/components';

export const borderColor = (fill: Color) => {
    return {
        borderImageSource: `url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="UTF-8" ?><svg version="1.1" width="8" height="8" xmlns="http://www.w3.org/2000/svg"><path d="M3 1 h1 v1 h-1 z M4 1 h1 v1 h-1 z M2 2 h1 v1 h-1 z M5 2 h1 v1 h-1 z M1 3 h1 v1 h-1 z M6 3 h1 v1 h-1 z M1 4 h1 v1 h-1 z M6 4 h1 v1 h-1 z M2 5 h1 v1 h-1 z M5 5 h1 v1 h-1 z M3 6 h1 v1 h-1 z M4 6 h1 v1 h-1 z" fill="${fill}" /></svg>') !important`,
    };
}

export const colorBorderStyles = (fill: Color) => () => ({
    borderImage: {
        ...borderColor(fill)
    }
});

export const hoverBackgroundColor = (color?: Color) => {
    return {
        ":hover": {
            backgroundColor: color || "gold"
        }
    };
};

export const hoverBorderColor = (color?: Color) => {
    return {
        ":hover": {
            ...borderColor(color || "yellow")
        }
    };
}; 

export const useBorder = (color: Color) => {
    return useClasses(colorBorderStyles(color)).borderImage as string;
};
