import { ProjectData, Tags, Languages, Software, ProjectStatus, Products, Contributors } from '@structures/ProjectData';
import { Title, Images } from '@projects/Super Fighting Robot';
import { FileType } from '@models';

export const ImageAssets = Object.values(Images);

export const SuperFightingRobot: ProjectData = {
    summary: {
        id: "SFR",
        title: "Super Fighting Robot",
        aliases: ["SFR", "Megaman"],
        filter: { settings: { pixelSize: 2 }},
        version: 0.8,
        status: ProjectStatus.Incomplete,
        initial: new Date(2016, 1, 11),
        release: new Date(2016, 2, 31),
        previews:[{
            file: Title.Title_01,
            type: FileType.Image,
            description: "A blue fighting robot is holding a robot and surrounded by the text 'Super Fighting Robot'.",
            caption: `Concept art for the game.`
        }],
        tags: {
            [Tags.Products]: [
                Products.Games
            ],
            [Tags.Software]: [
                Software.VSC,
                Software.Unity
            ],
            [Tags.Languages]: [
                Languages.CSharp
            ]
        },
        description: "A fan remake of the Megaman X game."
    },
    details: {
        description: "A faithful recreation of the Megaman X game in Unity."
            + "<br/><br/>"
            + "Served as a foundational learning project for an action platformer game.",
        gallery: [{
            file: ImageAssets[0],
            type: FileType.Image,
            description: "An animated image of a red fighting robot walking through a desolate cityscape and shooting energy projectiles at other robots.",
            caption: "The first level of the game."
        }, {
            file: ImageAssets[1],
            type: FileType.Image,
            description: "An animated image of a red fighting robot sliding on the side of a bridge that's broken in half.",
            caption: "Shows off the wall sliding effect."
        }, {
            file: ImageAssets[2],
            type: FileType.Image,
            description: "An animated image of a red fighting robot charging his blaster to deal additional damage and then getting hit by an enemy who got too close.",
            caption: "Charging attacks and taking damage effects."
        }],
        contributions: [{
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Lead Unity Developer",
                involvement: "Developed the relevant systems in order to get an action platformer game and mentored other engineer with their implementations.",
                achievements: [
                    "Developed zones for enemies to spawn and trigger story events.",
                    "Developed parallax background system with multiple layers.",
                    "Developed enemy AI as a finite state machine, which is customizable per enemy type.",
                    "Setup all assets, including art, audio, animations, enemies and levels for the game.",
                    "Provided guidance on: projectile shooting, charging, jumping, and movement systems."
                ]
            }]
        }]
    },
};

export default SuperFightingRobot;