import React, { useMemo, useState } from 'react';
import { MoreVertOutlined as MoreItemsIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import useClasses from './Style';
import { GenericKey, ReactComponents } from '@models/types';
import { Select, SelectHeader, SelectClasses, SelectChangeEvent } from './Select';
import { withTooltip } from './Tooltip';

export type VisibilityMap = {
    [key: GenericKey]: boolean
};

interface DataMap {
    [key: string]: any;
};

export type MoreMenuCustomization = {
    button?: {
        classes?: string,
        attributes?: DataMap
    },
    menu?: string,
    container?: string,
    item?: string,
    select?: SelectClasses,
    icon?: (open: boolean) => JSX.Element
};

export type MoreMenuProps = {
    expanded?: boolean,
    onExpand?: React.ChangeEventHandler<SelectChangeEvent>,
    children: ReactComponents,
    className?: string,
    visibilityMap?: VisibilityMap,
    customization?: MoreMenuCustomization,
    tooltip?: string
};

export const moreMenuStyles = () => ({
    inOverflowMenu: {
      "&:hover": {
        backgroundColor: "transparent"
      }
    }
});

export const indexer = "indexer";

export const dataIndexer = `data-${indexer}`;

const IconButtonWithTooltip = withTooltip(IconButton, { style: {height: "fit-content"}});

export const MoreMenu = ({ expanded, onExpand, children, className, visibilityMap, customization, tooltip } : MoreMenuProps) => {
    const moreMenuClasses = useClasses(moreMenuStyles);
    const [open, setOpen] = useState(false);

    const handleOnExpand = (event: React.ChangeEvent<SelectChangeEvent>) => {
        setOpen(event.currentTarget.value);
        onExpand?.(event);
    };

    const shouldShowMenu = useMemo(
        () => !!visibilityMap && Object.values(visibilityMap || []).some((v) => v === false),
        [visibilityMap]
    );
    
    if (!shouldShowMenu) {
        return null;
    }
    return (
        <div className={classNames(className, customization?.container)}>
            <Select
                toggled={expanded}
                className={customization?.menu}
                classes={customization?.select}
                onToggle={handleOnExpand}
            >
                <SelectHeader>
                    <IconButtonWithTooltip
                        style={{borderRadius: 0}}
                        className={customization?.button?.classes || "rpgui-cursor-point"}
                        tooltip={tooltip}
                        {...customization?.button?.attributes}
                    >
                        { customization?.icon?.(open) || <MoreItemsIcon /> }
                    </IconButtonWithTooltip>
                </SelectHeader>
                {React.Children.map(children, (child, index) => {
                    const Child = child.type;
                    if(!(visibilityMap?.[index] || false)) {
                        return (
                            <Child
                                {...child.props}
                                className={classNames(child.props.className, moreMenuClasses.inOverflowMenu, customization?.item)}
                            >
                                {child.props.children}
                            </Child>
                        );
                    }
                    return null;
                })}
            </Select>
        </div>
    );
};