import { createRef, useRef, useEffect, useCallback, useState } from 'react';
import { ComponentProps, GenericCallback } from '@models';
import { Button, ButtonVariant, Comments, Heading, Icon, MediaViewer, scrollTo, Section, InteractiveHtml, withTooltip } from '@app/components';
import { ProjectData, ProjectMedia } from '@structures';
import { clamp, useKeyboardPress, useRenderOnResize } from '@utilities';
import { Box, SwipeableDrawer } from '@mui/material';
import './ProjectDetails.css';
import { IconButton } from '@templates';

export const modulo = (value: number, modulo: number) => {
    return (value%modulo + modulo)%modulo;
}

export type ProjectDetailsProps = ComponentProps & {
    open: string,
    setOpen: GenericCallback<string>,
    contributor?: string,
    data?: ProjectData,
    loading?: boolean
};

export const ProjectDetails = ({open, setOpen, contributor, data, loading, className, style, children}: ProjectDetailsProps) => {
    const { summary, details } = data || {};
    const id = summary?.id;
    const component = createRef<HTMLDivElement>();
    const visibility = useRef({} as {[key: string]: number});
    const sections = useRef([] as Element[]);
    const observer = useRef<IntersectionObserver>();
    const [recalculated, recalculating] = useState(0);
    const [fullscreen, setFullscreen] = useState<ProjectMedia>();
    const [showControls, setShowControls] = useState(true);
    const [showMaps, setShowMaps] = useState(false);
    const marginBottom = useRef(0);
    const [index, setIndex] = useState(-1);
    const sectionData = [
        { id: "gallery", image: "image", tooltip: "Gallery" },
        { id: "description", image: "text", tooltip: "Description" },
        { id: "roles", image: "job", tooltip: "Roles", mini: true },
        { id: "comments", image: "speech", tooltip: "Comments", }
    ];
    const recalculate = () => recalculating(recalculated + 1);
    const allContributors = contributor !== "any"
        ? details?.contributions?.filter(x => x.contributor.id === contributor)
        : details?.contributions;

    useRenderOnResize(() => {
        const documentHeight = document.documentElement.clientHeight;
        const rootComponent = component.current?.querySelector(".rpgui-project-details");
        marginBottom.current = Math.max((documentHeight - (rootComponent?.clientHeight || 0)) / 2, 50);
    }, { initialize: true });

    useEffect(() => {
        sections.current = Array.from(component.current?.querySelectorAll("[id^='section-']") || []);

        observer.current = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const element = entry.target as HTMLElement;
                visibility.current[element.id] = entry.intersectionRatio;
            });
        }, {
            threshold: [0],
            rootMargin: "-30px 0px 0px 0px"
        });

        return () => observer.current?.disconnect();
    }, [component]);

    const handleToggle = (visible: boolean) => () => {
        if(id) {
            setOpen?.(visible ? id : "");
        }
    };

    const getCurrentIndex = useCallback((offset: number) => {
        if(component.current) {
            const currentIndex = Object.entries(visibility.current)
                .map(([, value]) => value)
                .reduce((indexOfMax, value, index, array) => value > array[indexOfMax] ? index : indexOfMax, 0);
            const newIndex = clamp(currentIndex + offset, firstChapter(), finalChapter());
            return newIndex;
        }
        return -1;
    }, [component]);

    const handleScroll = useCallback(() => {
        sections.current.forEach(section => observer.current?.observe(section));
        const currentIndex = getCurrentIndex(0);
        if(index !== currentIndex) {
            setIndex(currentIndex);
        }
    }, [getCurrentIndex, index]);

    useEffect(() => {
        handleScroll();
    }, [handleScroll, recalculated]);

    const handleLoad = () => {
        recalculate();
    };

    const firstChapter = () => 0;
    const finalChapter = () => sections.current.length - 1;

    const canUseLastChapter = () => index > firstChapter();
    const canUseNextChapter = () => index < finalChapter();
    const canUseChapter = (chapterIndex: number) => index !== chapterIndex;

    const moveToId = (index: number) => {
        if(sections.current) {
            scrollTo({id: sections.current[index].id, location: "start"});
        }
    };
    const moveToIdByOffset = (offset: number) => {
        moveToId(getCurrentIndex(offset));
    };

    const handleLastChapter = () => moveToIdByOffset(-1);
    const handleNextChapter = () => moveToIdByOffset(1);
    const handlePickChapter = (chapterIndex: number) => () => moveToId(chapterIndex);
    
    const gallery = details?.gallery || [];
    const handleFullscreenEnter = (media: ProjectMedia) => setFullscreen(media);
    const handleFullscreenMove = (offset: number) => {
        const index = modulo((gallery.findIndex(x => x === fullscreen) || 0) + offset, gallery.length || 0);
        setFullscreen(gallery[index]);
    }
    const handleFullscreenLast = () => handleFullscreenMove(-1);
    const handleFullscreenNext = () => handleFullscreenMove(+1);
    const handleFullscreenExit = () => setFullscreen(undefined);

    const handleToggleControls = () => setShowControls(!showControls);
    const handleShowMaps = (value: boolean) => {
        if(showMaps !== value) {
            setShowMaps(value);
        }
    };
    useKeyboardPress((event) => {
        if(fullscreen) {
            if(event.key === "Escape") {
                handleFullscreenExit();
            } else if(event.key === " ") {
                handleToggleControls();
            } else if(event.key === "ArrowLeft") {
                handleFullscreenLast();
            } else if (event.key === "ArrowRight") {
                handleFullscreenNext();
            }
        }
    });

    const name = (section: string) => `project-details-${section}`;

    return (
        <>
            {fullscreen && (
                <div className="rpgui-project-details">
                    <div className={classNames("is-fullscreen", showControls ? "show-controls" : "hide-controls")} style={style}>
                        <div className="fullscreen-content">
                            <MediaViewer className="fullscreen-media" media={fullscreen} onClick={handleToggleControls} highlight={showMaps} />
                            <span className="fullscreen-button fullscreen-caption">
                                {(gallery.findIndex(x => x === fullscreen) || 0) + 1}/{gallery?.length}: {fullscreen.caption}
                            </span>
                        </div>
                        {fullscreen.maps?.length && (
                            <div className="fullscreen-button fullscreen-map-info">
                                <IconButton
                                    className="animation-selection-pulse"
                                    classes="" icon="question-mark md"
                                    tooltip="This image has sections which can be highlighted in order to provide more information."
                                    onHoverStart={() => handleShowMaps(true)}
                                    onHoverStop={() => handleShowMaps(false)}
                                >
                                    ?
                                </IconButton>
                            </div>
                        )}
                        <div className="fullscreen-button fullscreen-exit">
                            <IconButton style={{marginLeft: 3}} icon="cancel md" tooltip="Exit Fullscreen" onClick={handleFullscreenExit}>X</IconButton>
                        </div>
                        <div className="fullscreen-button fullscreen-last">
                            <IconButton icon="left-arrow md" tooltip="Previous" onClick={handleFullscreenLast}>↑</IconButton>
                        </div>
                        <div className="fullscreen-button fullscreen-next">
                            <IconButton icon="right-arrow md" tooltip="Next" onClick={handleFullscreenNext}>↑</IconButton>
                        </div>
                    </div>
                </div>
            )}
            <SwipeableDrawer
                anchor={"bottom"}
                open={!!open}
                onOpen={handleToggle(true)}
                onClose={handleToggle(false)}
                className={classNames("rpgui-content", className)}
                classes={{
                    paper: "rpgui-project-details"
                }}
                ref={component}
                style={style}
                sx={{
                    '& .rpgui-project-details': {
                        margin: `0 auto ${marginBottom.current}px auto`
                    },
                }}
                disableSwipeToOpen={true}
                disableEscapeKeyDown={!!fullscreen}
            >
                {!loading && (<>
                    <Box
                        className={"rpgui-container framed content"}
                        onLoad={handleLoad}
                        onScroll={handleScroll}
                    >
                        <Section name={name("gallery")} title={summary?.title}>
                            {(summary?.previews || [] as ProjectMedia[]).concat(details?.gallery || []).map(media => {
                                return <div className={"rpgui-media-container"} key={media.caption?.toString() || media.description}>{
                                    <figure>
                                        <div>
                                            <MediaViewer media={media} onFullscreen={handleFullscreenEnter} className="rpgui-media" />
                                        </div>
                                        <figcaption>
                                            <span style={{margin: "auto"}}>{media.caption}</span>
                                        </figcaption>
                                    </figure>
                                }</div>
                            })}
                        </Section>
                        <Section name={name("description")} title="Description">
                            <InteractiveHtml html={details?.description?.toString() || ""} />
                        </Section>
                        {contributor && (
                            <Section name={name("roles")} title="Responsibilities">
                                {allContributors?.map((contribution, index) => {
                                    const { contributor } = contribution; 
                                    return (
                                        <div key={`${contributor.id}-${index}`} className={`contributor-${contributor.id}`}>
                                            {contribution.roles.map(role => {
                                                return (
                                                    <div key={role.title} className="role">
                                                        <div className="role-subsection">
                                                            <Heading level={5}>Role</Heading>
                                                            <span>
                                                                {role.title}
                                                            </span>
                                                        </div>
                                                        <div className="role-subsection">
                                                            <Heading level={5}>Involvement</Heading>
                                                            <span>
                                                                <InteractiveHtml html={role.involvement?.toString() || ""} />
                                                            </span>
                                                        </div>
                                                        <div className="role-subsection">
                                                            <Heading level={5}>Achievements</Heading>
                                                            <ul>
                                                                {role.achievements.map(achievement => (
                                                                    <li key={achievement}>{achievement}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )})}
                            </Section>
                        )}
                        <Section name={name("comments")} title="Comments">
                            <Comments location={id} />
                        </Section>
                        {children}
                    </Box>
                </>)}
            </SwipeableDrawer>
            {open && (
                <div className="rpgui-project-details">
                    <Box className="controls" sx={{bottom: `${Math.max((marginBottom.current || 0) - 48, 0)}px !important`}}>
                        <div className="controls-container rpgui-container framed">
                            <div className="control-container">
                                <Button variant={ButtonVariant.Link} disabled={!canUseLastChapter()} onClick={handleLastChapter}>
                                    <Icon
                                        icon="up-arrow"
                                        tooltip={`Up 1 Section`} 
                                        className="md control incremental has-pointer-events"
                                    >↑</Icon>
                                </Button>
                            </div>
                            {sections.current.map((chapter, currentIndex) => {
                                const dataIndex = sectionData.findIndex(x => chapter.id.includes(x.id));
                                const section = sectionData[dataIndex];
                                const active = !canUseChapter(currentIndex);
                                const Control = withTooltip(() => (
                                    <div className="control" style={{position: "relative"}}>
                                        <Icon
                                            icon={section?.image}
                                            className={classNames("md", section?.mini ? "animation-spin" : "")}
                                        >
                                            {!section?.image && currentIndex}
                                        </Icon>
                                        {section?.mini && (
                                            <div
                                                className={"rpgui-icon mini-me ignore-pointer-events"}
                                                style={{position: "absolute", top: -3, left: -3}}
                                            />
                                        )}
                                    </div>
                                ));
                                return (
                                    <div key={chapter.id} className={classNames("control-container", active ? "active" : "")}>
                                        <Button
                                            variant={ButtonVariant.Link}
                                            disabled={active}
                                            onClick={handlePickChapter(currentIndex)}
                                            style={{height: "30px"}}
                                        >
                                            <Control className="has-pointer-events" tooltip={section?.tooltip} />
                                        </Button>
                                    </div>
                                );
                            })}
                            <div className="control-container">
                                <Button variant={ButtonVariant.Link} disabled={!canUseNextChapter()} onClick={handleNextChapter}>
                                    <Icon
                                        icon="down-arrow"
                                        tooltip={`Down 1 Section`}
                                        className="md control incremental has-pointer-events"
                                    >↑</Icon>
                                </Button>
                            </div>
                        </div>
                    </Box>
                </div>
            )}
        </>
    );
};