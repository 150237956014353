import { Flex, Table, TableBody } from '@aws-amplify/ui-react';
import { InputLabel } from '@mui/material';
import { ComponentProps } from '@models';
import { Button } from '@components/Button';
import { Comment } from '@components/Comment';
import { CommentEditor } from '@components/CommentEditor';
import { Dropdown } from '@components/Dropdown';
import { useAuthentication, useComments, useUser } from '@services/index';

export type CommentsProps = ComponentProps & {
    location?: string
};

export const Comments = ({location, className, style, children}: CommentsProps) => {
  const {
    comments,
    totalComments,
    loadMoreComments,
    createComment,
    deleteComment,
    commentsPerPage,
    setCommentsPerPage
  } = useComments(location);
  const { user } = useUser();
  const { signedIn, authenticatedUser } = useAuthentication();
  const commentsPerPageOptions = [1, 2, 5, 10, 20, 50];

  return (
    <div className={classNames("comments-section", className)} style={style}>
        <div className="fit-content" style={{margin: "auto"}}>
          <Table className="comments fixed">
            <TableBody>
              {comments.map((note) => (
                <Comment
                  key={note.id || note.name}
                  comment={note} user={user}
                  owned={signedIn && authenticatedUser?.username === note.user?.id}
                  onDelete={deleteComment}
                />
              ))}
            </TableBody>
          </Table>
        </div>
        {totalComments > 0 ? (
          <>
            <span style={{display: "inherit", textAlign: "center"}}>
              Showing {comments.length} of {totalComments} {comments.length > 1 ? "comments" : "comment"}
            </span>
            {totalComments !== comments.length &&
              <Flex direction="row" justifyContent="center" className="comment-loader" style={{height: "3em"}}>
                <InputLabel style={{alignSelf:"flex-end"}}>Comments</InputLabel>
                <Dropdown
                  value={commentsPerPage}
                  onChange={setCommentsPerPage}
                  options={commentsPerPageOptions}
                  classes={''}
                />
                <Button className="amplify-flex-optional" onClick={loadMoreComments} style={{height: "100%"}}>
                  Show More
                </Button>
              </Flex>
            }
          </>
        ) : (
          <div className="comment-metadata rpgui-container framed-golden-2">
            <div className="comment-empty nes-container is-rounded is-thin no-margin">
              <span>No Comments... yet!</span>
              <br/><br/>
              <span>☁️ Please provide some wisdom as tribute to the great clouds! ☁️</span>
            </div>
          </div>
        )}
        <CommentEditor
          owned={signedIn}
          onCreate={createComment}
          style={{margin: "2rem 0"}}
        />
        {children}
    </div>
  );
};

export default Comments;