import { useEffect, useState } from "react";
import { ComponentWithChildrenProps } from "@models/types";
import RichTextEditor from "@components/RichTextEditor";
import { SelectChangeEvent } from "@components/Select";
import { OverflowMenu } from "@components";

export type ToolbarOverflowMenuProps = ComponentWithChildrenProps & {
    editor: RichTextEditor,
    maxSizes: number[],
    tooltip: string
};

export const ToolbarOverflowMenu = ({editor, maxSizes, className, style, tooltip, children} : ToolbarOverflowMenuProps) => {
    const [refresh, setRefresh] = useState<HTMLElement | null>();
    const maxCollapse = document.documentElement.clientWidth > maxSizes[maxSizes.length-1];
    const verticalOffset = -Math.floor(Math.min(document.documentElement.clientWidth / 400, 3));

    useEffect(() => {
        setRefresh(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);
    
    const handleOnExpand = (event: React.ChangeEvent<SelectChangeEvent>) => {
        const change = event.currentTarget;
        if(change?.value) {
            setRefresh(change.element.parentElement);
        }
    };

    return (
        <OverflowMenu
            className={classNames("ql-ignore-hover ql-children-select", className)}
            customization={{
                menu: {
                    button: {
                        classes: "rpgui-cursor-point ql-show-more"
                    },
                    select: {
                        list: "ql-custom-picker"
                    },
                    item: "ql-custom-handle"
                }
            }}
            style={{...style, maxWidth: "15vw", minWidth: 30, padding: 0, marginRight: !maxCollapse ? 30 : 0, translate: `0px ${verticalOffset}px` }}
            maxSizes={maxSizes}
            onExpand={handleOnExpand}
            tooltip={tooltip}
        >
            {children}
        </OverflowMenu>
    );
};

export default ToolbarOverflowMenu;