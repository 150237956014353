import React, { useState, useEffect } from 'react';
import { Flex as AmplifyFlex } from '@aws-amplify/ui-react';
import { ComponentProps } from '@models/types';
import { withTooltip } from '@components/Tooltip';
import Option from '@components/Option';

export type DropdownProps = ComponentProps & {
  value?: any,
  options?: any[],
  option?: (value: any) => React.ReactNode,
  onChange?: React.ChangeEventHandler<HTMLSelectElement>
};

export const Dropdown = withTooltip(({value, options, option, onChange, className, style, children} : DropdownProps) => {
  const [select, setSelect] = useState<HTMLSelectElement>();

  useEffect(() => {
    if(select) {
      RPGUI.create(select, "dropdown");
    }
  }, [select, value]);

  const handleSetSelect = (select: HTMLSelectElement) => {
    setSelect(select);
  }

  return (
    <AmplifyFlex direction="column">
      <select
        id="simple-dropdown-standard"
        value={value}
        className={`rpgui-dropdown rpgui-cursor-point ${className}`}
        onChange={onChange}
        style={{...style, display: "none"}}
        ref={handleSetSelect}
      >
        {children}
        { options?.map(load => (
          option ? option(load) : <Option key={load} value={load} />
        ))}
      </select>
    </AmplifyFlex>
  );
});

export default Dropdown;