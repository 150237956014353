import { ProjectData, Tags, Languages, Software, ProjectStatus, Products, Contributors, Technologies, OrientationType } from '@structures/ProjectData';
import { Title, Images } from '@projects/Makonoid';
import { FileType } from '@models';

export const ImageAssets = Object.values(Images);

export const Makonoid: ProjectData = {
    summary: {
        id: "MN",
        title: "Makonoid",
        orientation: OrientationType.Portrait,
        aliases: ["Mako", "FF7", "FF7 Makonoid"],
        filter: { settings: { pixelSize: 1 }},
        version: 1.0,
        status: ProjectStatus.Completed,
        initial: new Date(2012, 9, 1),
        release: new Date(2012, 9, 31),
        previews:[{
            file: Title.Title_01,
            type: FileType.Image,
            description: "A dialog with text and two people shows, as well as an emoji if a rattled face.",
            caption: `Main window of the chat application.`
        }],
        tags: {
            [Tags.Products]: [
                Products.Tools
            ],
            [Tags.Software]: [
                Software.VS
            ],
            [Tags.Languages]: [
                Languages.CSharp
            ],
            [Tags.Technologies]: [
                Technologies.WinForms
            ]
        },
        description: "A tool to modify menu text from FF7 on the PSX."
    },
    details: {
        description: "A tool which allows users to modify what text is displayed inside of the menus in FF7 on the PSX."
            + "<br/><br/>"
            + "It was developed as part of an effort to provide a complete toolkit for that games modification.",
        gallery: [{
            file: ImageAssets[0],
            type: FileType.Image,
            description: "An image of a window showing a Stat menu with textboxes to change each text value.",
            caption: "The stat menu with text placed on the screen as it would appear in game."
        }, {
            file: ImageAssets[1],
            type: FileType.Image,
            description: "An image of a window showing a Name screen with textboxes to change each text value.",
            caption: "The name character screen with text placed on the screen as it would appear in game."
        }, {
            file: ImageAssets[2],
            type: FileType.Image,
            description: "An image of a window showing a address offset box and values as they appear in hex and text.",
            caption: "The hex editor built-in the application."
        }, {
            file: ImageAssets[3],
            type: FileType.Image,
            description: "An image of a window showing a Config screen with textboxes to change each text value.",
            caption: "The config screen with text placed on the screen as it would appear in game."
        }],
        contributions: [{
            contributor: Contributors.MakoInfused,
            roles: [{
                title: "Tools Engineer",
                involvement: "Developed all of the tools logic and designed the WinForms UI.",
                achievements: [
                    "Developed a hex editor in order to modify anything which the Editor couldn't do natively.",
                    "Developed a several tabs that should allow complete modification of all menu text in the game.",
                    "Developed a de/encoder to convert text to and from a human-readable format and transport it into the game.",
                    "Designed & developed the entire user interface."
                ]
            }]
        }]
    },
};

export default Makonoid;