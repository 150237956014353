import { useState } from 'react';
import { Flex } from '@aws-amplify/ui-react';
import { ComponentProps, CreateNoteModel, HandleCreateModel } from '@models/types';
import Button from '@components/Button';
import RichTextEditor from './RichTextEditor';

export type CommentEditorProps = ComponentProps & {
  owned: boolean,
  onCreate: (event: HandleCreateModel<CreateNoteModel>) => Promise<void>
};

export const CommentEditor = ({owned: signedIn, onCreate, className, style, children} : CommentEditorProps) => {
  const [text, setText] = useState('');

  const handleCreateNote = () => {
    onCreate({
      data: {
        description: text
      },
      reset: () => {
        setText('');
      }
    });

  }
  
  return (<>
    {signedIn && (
      <div
        style={{...style, marginLeft: "auto", marginRight: "auto"}}
        className={`comment-submit amplify-flex amplify-flex-center amplify-flex-optional ${className}`}
      >
        <Flex>
          <Flex direction="column" gap={0}>
            <RichTextEditor
              value={text}
              onChange={setText}
              className="comment-message-color comment-border-color"
              placeholder="Leave Comment..."
              maxSize={200}
            />
            <Button onClick={handleCreateNote} className={"fit-content align-self-end"}>
              Add Comment
            </Button>
            {children}
          </Flex>
        </Flex>
      </div>
    )}
  </>);
};

export default CommentEditor;