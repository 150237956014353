import { ComponentProps } from '@models/types';
import { withTooltip } from './Tooltip';

export type IconProps = ComponentProps & {
  icon: string
};

export const Icon = withTooltip(({icon, className, style, children} : IconProps) => {
  return (
    <span className={classNames(`rpgui-icon ${icon}`, className)} style={style}>
      {children}
    </span>
  );
}, { style: { width: "fit-content" } });

export default Icon;