import React, { useState } from 'react';
import styled from '@mui/styled-engine';
import { ComponentProps, ReactEvent } from '@models/types';
import { useChild } from '@utilities';

const SelectContainer = styled("div")`
  width: fit-content;
  max-width: 10.5em;
  margin: 0 auto;
`;

const SelectHeaderDiv = styled("div")`
  margin-bottom: 4px;
  height: 24px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
`;

const SelectListContainer = styled("div")`
  position: absolute;
  z-index: 1000;
`;

const SelectList = styled("ul")`
  padding: 0;
  margin: 0;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #3faffa;
  width: fit-content;
`;

const SelectListItem = styled("li")`
  margin-left: 0 !important;
  list-style: none;
  margin-bottom: 0.5em;
  min-height: 24px;
  width: fit-content;
`;

export type SelectHeaderProps = ComponentProps & {
  onClick?: React.MouseEventHandler<HTMLDivElement>
};

export const SelectHeader = ({onClick, className, style, children} : SelectHeaderProps) => {
  return (
      <SelectHeaderDiv style={style} className={className} onClick={onClick}>
        {children}
      </SelectHeaderDiv>
  );
};

export type SelectClasses = {
  header?: string,
  container?: string,
  list?: string,
  item?: string
};

export type SelectChangeEvent = ReactEvent<HTMLDivElement, boolean>;

export type SelectProps = ComponentProps & {
  toggled?: boolean,
  onToggle?: React.ChangeEventHandler<SelectChangeEvent>,
  classes?: SelectClasses
};

export const Select = ({toggled, onToggle, classes, className, style, children}: SelectProps) => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>(null);

  const isOpen = toggled != null ? toggled : open;

  const setExpanded = (element: HTMLElement, value: boolean) => {
    setOpen(value);
    onToggle?.({ currentTarget: { element, value }, target: { element, value } } as React.ChangeEvent<SelectChangeEvent>);
  };

  const handleToggle = (event: React.MouseEvent<HTMLDivElement>) => {
    setExpanded(event.currentTarget, !isOpen);
  };

  const optionClicked = (value: any) => (event: React.MouseEvent<HTMLLIElement>) => {
    setSelectedOption(value);
    setExpanded(event.currentTarget, false);
  };

  const [header, items] = useChild(children, SelectHeader);
  const HeaderComponent = header.length > 0 ? header[0]?.type : null;

  return (
    <SelectContainer className={className} style={style}>
        {HeaderComponent ? (
            <HeaderComponent {...header[0].props} className={classes?.header} onClick={handleToggle} />
        ) : (
            <SelectHeaderDiv className={classes?.header} onClick={handleToggle}>
              {selectedOption || React.Children.toArray(items)?.[0]}
            </SelectHeaderDiv>
        )
        }
        {isOpen && (
          <SelectListContainer className={classes?.container}>
            <SelectList className={classes?.list}>
              {React.Children.map(items, (child) => {
                return (
                  <SelectListItem className={classes?.item} onClick={optionClicked(child)}>
                    {child}
                  </SelectListItem>
              )})}
            </SelectList>
          </SelectListContainer>
        )}
    </SelectContainer>
  );
};

export default Select;