import React from 'react';
import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material';
import { ComponentProps } from '@models/types';
import { useTooltip } from './TooltipProvider';

export type TooltipProps = ComponentProps & {
    tooltip?: React.ReactNode,
};

export type TooltipPlacement = MuiTooltipProps['placement'];

export type WithtoolTipOptions = {
  block?: boolean,
  classes?: string,
  selectable?: boolean,
  placement?: TooltipPlacement,
  verbose?: boolean
};

export const tooltipClasses = "rpgui-container framed-golden tooltip-text rpgui-text rpgui-tooltip-text";

export type WithTooltipProps = TooltipProps & WithtoolTipOptions;

export const Tooltip = ({tooltip, style, className, children} : TooltipProps) => {
    return (
      <span className={`${tooltipClasses} ${className}`}
        style={{padding: "0px", boxShadow: "5px 2.5px #0000006a", ...style}}
      >
        {tooltip}{children}
      </span>
    );
};

export function withTooltip<T>(
  WrappedComponent: React.ComponentType<T>,
  options?: WithTooltipProps
) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithTheme = (props: T & WithTooltipProps) => {
    const context = useTooltip();
    const { verbose, ...baseProps} = props;

    if(context.verbose === false && (props.verbose || options?.verbose)) {
      return (
        <WrappedComponent aria-label={props.tooltip} {...(baseProps as T)}>
            {props.children}
        </WrappedComponent>
      );
    }

    // props comes afterwards so we can override the default ones.
    return (
      <MuiTooltip
        title={props.tooltip}
        className={classNames(props.classes !== undefined ? props.classes : props.className, "tooltip")}
        placement={options?.placement || props.placement || "top"}
        classes={{
          popper: `rpgui-content tooltip-container`,
          tooltip: `${tooltipClasses} ${props.selectable && "rpgui-cursor-select select-text"}`
        }}
        style={{display: options?.block ? "flex" : "inline-flex", ...options?.style}}
        leaveDelay={props.selectable ? 250 : 0}
        data-indexer={(props as any)["data-indexer"]}
      >
        <div>
          <WrappedComponent aria-label={props.tooltip} {...(baseProps as T)}>
            {props.children}
          </WrappedComponent>
        </div>
      </MuiTooltip>
    );
  };

  ComponentWithTheme.displayName = `withTooltip(${displayName})`;

  return ComponentWithTheme;
};

export default Tooltip;