import { IconButton } from '@mui/material';
import { Close as IconClose } from '@mui/icons-material';
import { SnackbarKey, useSnackbar } from 'notistack';

// Fixes issue of not having default actions for a snackback: 
// https://github.com/iamhosseindhv/notistack/issues/156#issuecomment-523886367

function SnackbarDefaultActions(snackbarKey?: SnackbarKey) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <IconClose />
    </IconButton>
  );
}

export default SnackbarDefaultActions;