import React from 'react';
import { ComponentProps } from '@models/types';

export type OptionProps = ComponentProps & {
  value: any,
  display?: any,
};

export type OptionValue = string | number;

export type OptionType = {
  name?: string
  value: OptionValue
};

export const Option = ({value, display, className, style, children} : OptionProps) => {
  return (
    <option value={value} className={`rpgui-cursor-point ${className}`} style={style}>
      {display || value}{children}
    </option>
  );
};

export default Option;