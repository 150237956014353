import { Audio, Image, Video, FileWithMetadata, ReactChildren, Link } from "@models";

export type TagCategory = {
    [key: string]: string[]
};

export type ProjectImage = FileWithMetadata<Image | Link>;

export type ProjectVideo = FileWithMetadata<Video | Link>;

export type ProjectAudio = FileWithMetadata<Audio | Link>;

export type ProjectMedia = ProjectImage | ProjectVideo | ProjectAudio;

export enum ProjectStatus {
    Completed,
    Live,
    Unreleased,
    Incomplete,
    WorkInProgress
};

export enum OrientationType {
    Landscape,
    Portrait
};

export type ProjectPreviewFilter = {
    type?: string,
    settings?: any
}

export type ProjectSummaryData = {
    id: string,
    title: string,
    orientation?: OrientationType,
    aliases?: string[]
    filter?: ProjectPreviewFilter,
    status?: ProjectStatus,
    version?: number,
    initial?: Date,
    release?: Date,
    previews?: ProjectImage[],
    description?: ReactChildren,
    tags?: TagCategory,
};

export type ProjectRole = {
    title: string,
    involvement: ReactChildren,
    achievements: string[]
};

export type ProjectContributor = {
    id: string,
    name: string
};

export type ProjectContributions = {
    contributor: ProjectContributor,
    roles: ProjectRole[]
};

export type ProjectDetailsData = {
    gallery: ProjectMedia[],
    description?: ReactChildren,
    contributions?: ProjectContributions[]
};

export type ProjectData = {
    summary: ProjectSummaryData,
    details: ProjectDetailsData
};

export const Tags = Object.freeze({
    Software: "software",
    Products: "products",
    Languages: "languages",
    Platforms: "platforms",
    Technologies: "technologies"
});

export const Software = Object.freeze({
    Unity: "Unity",
    Unreal: "Unreal",
    VS: "Visual Studio",
    VSC: "Visual Studio Code",
    RPG: "RPG Maker"
});

export const OtherSoftware = Object.freeze({
    Olly: "OllyDbg",
    Galaxy: "Galaxy Editor",
    Flash: "Flash",
    World: "World Editor",
    DevKit: "Dev Kit Pro",
    Cocos: "Cocos 2D"
});

export const Products = Object.freeze({
    Games: "Games",
    Apps: "Applications",
    Tools: "Tools",
    Films: "Films"
});

export const OtherProducts = Object.freeze({
    Patches: "Patches",
});

export const Languages = Object.freeze({
    C: "C",
    CPlus: "C++",
    CSharp: "C#",
    JS: "JS",
    Max: "Max Script",
    SQL: "SQL",
    Ruby: "Ruby",
    VB: "VB.NET"
});

export const OtherLanguages = Object.freeze({
    JASS: "JASS",
    Galaxy: "Galaxy Script",
    AS3: "Action Script 3.0",
    PHP: "PHP",
    SNES: "Assembly 65c816",
    OBJC: "Objective C"
});

export const Platforms = Object.freeze({
    Windows: "Windows",
    Mac: "Mac OS",
    Linux: "Linux",
    IPhone: "IPhone",
    Android: "Android",
    Web: "Web"
});

export const OtherPlatforms = Object.freeze({
    SC2: "Starcraft 2",
    WC3: "Warcraft 3",
    NDS: "Nintendo DS"
});

export const Technologies = Object.freeze({
    WinForms: "WinForms",
    WPF: "Windows Presentation Foundation"
});

export const OtherTechnologies = Object.freeze({
    Dream: "Dreamweaver"
});

export const Contributors = Object.freeze({
    MakoInfused: {
        id: "MakoInfused",
        name: "Dino Suvalic"
    }
});
