import {
    MenuOutlined as MenuIcon,
    MenuOpenOutlined as MenuOpenIcon
} from '@mui/icons-material';
import { Flex } from '@aws-amplify/ui-react';
import { ComponentProps, ReactEvent, GenericCallback } from '@models';
import {
    Button,
    ButtonVariant
} from '@components';
import { withTooltip } from '@components/Tooltip';
import { useBooleanToggleEvent } from '@utilities';

export type NavigationMenuChangeEvent = ReactEvent<HTMLButtonElement, boolean>;

export type NavigationMenuProps = ComponentProps & {
    collapsed?: boolean,
    overflow?: boolean,
    open?: boolean,
    setOpen?: GenericCallback<NavigationMenuChangeEvent>
};

export const MenuOpen = withTooltip(MenuIcon);
export const MenuClose = withTooltip(MenuOpenIcon);

export const NavigationMenu = ({collapsed, overflow, open, setOpen, className, style, children}: NavigationMenuProps) => {
    const [_open, _setOpen] = useBooleanToggleEvent(open, setOpen);

    return (
        <>
            {(
                <div
                    className="full-width"
                    style={{...(collapsed ? {top: 10, left: 25} : {}), position: "absolute", height: "1.5em", pointerEvents: "none"}}
                >
                    <Flex direction="row" alignItems="center" style={{marginRight: 30}}>
                        <Flex className={classNames("full-width", className)} alignItems="flex-end" direction="column" style={style}>
                            <Flex direction="row" style={{pointerEvents: "all"}}>
                                {children}
                                {overflow && (
                                    <Button
                                        variant={ButtonVariant.Link}
                                        className={classNames(collapsed ? "is-dark" : "", "has-box-shadow")}
                                        onClick={_setOpen}
                                    >
                                        { _open
                                            ? <MenuClose
                                                className="navigation-overflow-button-icon"
                                                placement="left-end"
                                                tooltip="Close Menu"
                                                verbose={true}
                                            />
                                            : <MenuOpen
                                                className="navigation-overflow-button-icon"
                                                placement="left-end"
                                                tooltip="Open Menu"
                                                verbose={true}
                                            />
                                        }
                                    </Button>
                                )}
                            </Flex>
                        </Flex>
                    </Flex>
                </div>
            )}
        </>
    );
};

export default NavigationMenu;
