import { useCallback, useState } from "react";
import { API } from "aws-amplify";
import { AmplifyUser } from "@aws-amplify/ui/dist/types/types/authenticator";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { hasNoNetwork, isDevelopment } from "@utilities";
export type { GraphQLResult } from "@aws-amplify/api-graphql";

export const useAuthentication = () => {
  const isLocal = isDevelopment() && hasNoNetwork();
  const [localAuth, setLocalAuth] = useState(false);
  const { authStatus, user: authenticatedUser, signOut } = useAuthenticator(context => [context.authStatus, context.signOut, context.user]);
  const signedIn = authStatus === 'authenticated';
  const tryLocalSignIn = () => {
    if(isLocal) {
      setLocalAuth(true);
    }
  };
  const tryLocalSignOut = () => {
    if(isLocal) {
      setLocalAuth(false);
    }
  };

  return {
      signedIn: isLocal ? localAuth : signedIn,
      authenticatedUser: isLocal ? (localAuth ? { username: "de7b1402-dd3c-4998-96de-8855c4bb8c8f" } : {}) as AmplifyUser : authenticatedUser,
      signOut: isLocal ? tryLocalSignOut : signOut,
      tryLocalSignIn: isLocal ? tryLocalSignIn : undefined,
  };
};

export const useGraphQL = () => {
  const { signedIn } = useAuthentication();

  const apiCall = useCallback(async function internalApiCall(query: string, data?: any) {
      // https://docs.amplify.aws/lib/graphqlapi/authz/q/platform/js/
      try {
        return await API.graphql({
          query: query,
          variables: data,
          authMode: signedIn ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
        });
      } catch (error) {
        if(signedIn) throw error;
      }
  }, [signedIn]);

  return {
      apiCall
  };
};