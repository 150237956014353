import { useState, useCallback } from 'react';
import { contain, IntrinsicScale } from 'intrinsic-scale';
import { ComponentProps, MapType } from '@models';
import SelectableTooltip from './SelectableTooltip';
import { isEqual } from 'lodash';

export type ObjectSize = IntrinsicScale;

export type MapFactoryProps = ComponentProps & {
    map: MapType,
    highlight?: boolean
};

export const MapFactory = ({ map, highlight, className, style, children }: MapFactoryProps) => {
    if(map.map === "tooltip") {
        return (
            <SelectableTooltip
                className={className}
                style={style}
                highlight={highlight}
                tooltip={map}
            />
        );
    }

    return <div className={className} style={style}>{children}</div>;
};

export type MapViewerProps = ComponentProps & {
    media: HTMLImageElement | HTMLVideoElement | HTMLAudioElement,
    maps?: MapType[],
    highlight?: boolean,
    onClick?: React.MouseEventHandler<HTMLDivElement>
};

export const MapViewer = ({media, maps, highlight, onClick, className, style, children} : MapViewerProps) => {
    const [mapSize, setMapSize] = useState<ObjectSize>();

    const calculateMaps = useCallback(() => {
        if(media) {
            const { width: currentWidth, height: currentHeight } = media.getBoundingClientRect();
            const naturalWidth = media instanceof HTMLVideoElement ? media.videoWidth
                : media instanceof HTMLImageElement ? media.naturalWidth
                : currentWidth;
            const naturalHeight = media instanceof HTMLVideoElement ? media.videoHeight
                : media instanceof HTMLImageElement ? media.naturalHeight
                : currentHeight;
            const { x, y, width, height } = contain(currentWidth, currentHeight, naturalWidth, naturalHeight);
            const newMapSize = {x, y, width, height};
            if(!isEqual(mapSize, newMapSize)) {
                setMapSize(newMapSize);
            }
        }
    }, [mapSize, media]);

    calculateMaps();
    
    return (
        <div
            className={classNames("media-map", className)}
            style={{left: `${mapSize?.x}px`, top: `${mapSize?.y}px`, width: mapSize?.width, height: mapSize?.height, ...style}}
            onClick={onClick}
        >
            {/* https://github.com/clarketm/image-map ? */}
            {maps?.map((map, index) => {
                return (
                    <MapFactory
                        key={`${map.text}-${index}`}
                        map={map}
                        highlight={highlight}
                    >
                        {children}
                    </MapFactory>
                );
            })}
        </div>
    );
};

export default MapViewer;
