import React, { createRef, useRef, useEffect } from 'react';
import { Flex, Text } from '@aws-amplify/ui-react';
import { Color, ComponentProps } from '@models/types';
import { withTooltip } from '@components/Tooltip';

export type ProgressProps = ComponentProps & {
  display?: {
    value?: boolean
  },
  value: number,
  min?: number,
  max?: number,
  color?: Color,
  animation?: {
    bar?: string,
    fill?: string,
  }
};

export const Progress = withTooltip(({display, value, min = 0, max = 100, color, animation, className, style, children} : ProgressProps) => {
  const div = createRef<HTMLDivElement>();
  const divBar = useRef<HTMLDivElement>();
  const full = value > max;

  useEffect(() => {
    if(div?.current) {
      RPGUI.create(div.current, "progress");
      RPGUI.create(div.current, "progress");
      RPGUI.set_value(div.current, Math.max(Math.min(value / (max - min), 1), 0));
    }
  }, [div, value, min, max]);

  useEffect(() => {
    if(div?.current) {
      divBar.current = div.current.querySelector(".rpgui-progress-fill") as HTMLDivElement;
      if(divBar?.current) {
        divBar.current.style.backgroundBlendMode = "hard-light";
        divBar.current.style.backgroundColor = color ? color : "initial";
        divBar.current.style.animation = full && animation?.fill 
          ? `${animation.fill} 0.5s infinite linear`
          : "initial";
      }
    }
  }, [div, animation?.fill, full, color]);

  const Component = () => {
    return ( 
      <div
        ref={div}
        className={`rpgui-progress ${className}`}
        style={{animation: full ? `${animation?.bar} 1s infinite alternate` : "initial", ...style}}
      >
        {children}
      </div>
    )
  };

  return (
    display?.value ?
      <Flex direction={"row"}>
        <Text style={{alignSelf: "center"}}>{value}%</Text>
        <Component />
      </Flex>
      : <Component />
  );
}, { block: true });

export default Progress;