import { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import * as pages from "@pages";

export type DynamicRouteComponent = {
    display?: {
        name?: string,
        icon?: string,
        className?: string
    },
    component: string,
    className?: string,
    hidden?: boolean
};

export type RouteConfiguration = DynamicRouteComponent & {
    path: string,
    parameters?: string[],
    aliases?: string[]
};

export type RoutesConfiguration = RouteConfiguration[];

export const DynamicRouteLoader = () => {
    return (
        <></>
    );
};

export type DynamicPageProps = {
    name: string,
    props: any,
    className?: string,
};

export const DynamicPage = ({name, props, className}: DynamicPageProps) => {
    const Component = (props: any) => <>{(pages as any)[name](props)}</>;

    return (
        <Component {...props} className={classNames(props.className, className)} />
    );
};

export type DynamicRouteProps = {
    parent?: JSX.Element,
    path: string,
    parameters?: string[],
    configuration: DynamicRouteComponent,
    props?: any
};

export const DynamicRoute = ({parent, path, parameters, configuration, props}: DynamicRouteProps) => {
    if(configuration) {
        const { component, className } = configuration;
        const Component = (props: any) => (
            <DynamicPage
                name={component}
                props={props}
                className={className}
            />
        );

        if(path.includes("*")) {
            return <Route path={path} element={<Component {...props} />} />
        }

        return (
            <Route path={path} element={parent}>
                <Route index element={<Suspense fallback={<DynamicRouteLoader />}>
                    <Component {...props} />
                </Suspense>} />
                {parameters?.map(param => (
                    <Route key={param} path={":" + param} element={<Suspense fallback={<DynamicRouteLoader />}>
                        <Component {...props} />
                    </Suspense>} />
                ))}
            </Route>
        );
    }
    return <></>;
};

export type DynamicRoutesProps = {
    parent?: JSX.Element,
    configuration: RoutesConfiguration,
    props?: any
};

export const DynamicRouter = ({parent, configuration, props}: DynamicRoutesProps) => {
    return (
        <BrowserRouter>
            <Routes>
                {configuration?.map((item: RouteConfiguration) => {
                    const { path, parameters, aliases, ...configuration } = item;
                    const paths = [path, ...(aliases || [])];
                    return paths.map((path) => {
                        return DynamicRoute({parent, path, parameters, configuration, props});
                    });
                })}
            </Routes>
        </BrowserRouter>
    );
};
