import { createContext, useContext } from "react";
import { ReactChildren } from "@models/types";

type TooltipProviderOptions = {
    verbose?: boolean
};

const defaultProps: TooltipProviderOptions = {
    verbose: true
};

export type TooltipProviderProps = TooltipProviderOptions & {
    children?: ReactChildren
};

/* The context itself */
const TooltipContext = createContext(defaultProps);

/* The provider lets you change the provided components */
export const TooltipProvider = ({ children, ...rest }: TooltipProviderProps = defaultProps) => (
    <TooltipContext.Provider value={rest}>{children}</TooltipContext.Provider>
);

/* A custom hook to get access to the provided components */
export const useTooltip = () => useContext(TooltipContext);

/* The components provider itself */
export default TooltipProvider;