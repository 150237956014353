import React, { useState } from 'react';
import { Button, ButtonTheme } from '@components/Button';
import { ComponentProps } from '@models/types';
import { withTooltip } from '@components/Tooltip';
import { Confirmation } from '@components';

export type DeleteButtonProps = ComponentProps & {
  type?: string,
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  confirm?: boolean | string
};

export const DeleteButton = withTooltip(({type, onClick, confirm = true, className, style, children} : DeleteButtonProps) => {
  const [anchor, setAnchor] = useState<HTMLElement>();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if(anchor) {
      setAnchor(undefined);
      onClick?.(event);
    } else if(confirm) {
      setAnchor(event.currentTarget);
    } else {
      onClick?.(event);
    }
  };
  
  const handleConfirm = (confirmed: boolean) => {
    if(confirmed) {
      anchor?.dispatchEvent(
        new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1
        })
      );
    } else {
      setAnchor(undefined);
    }
  };

  return (
    <>
      <Button theme={ButtonTheme.NES} onClick={handleClick} className={`delete-button ${className}`} style={style}>
        X
        {children}
      </Button>
      <Confirmation anchor={anchor} onClose={handleConfirm}>
        {typeof confirm === 'string' ? confirm : `Are you sure you wish to delete this ${type || "item"}?`}
      </Confirmation>
    </>
  );
});

export default DeleteButton;