import { Accordion, AccordionDetails, AccordionSummary, Box, InputLabel, Typography } from '@mui/material';
import { isEqual } from 'lodash';
import { ComponentProps, GenericCallback } from '@models';
import { Column, ContainerAlignment, GrowType, hoverBorderColor, Row, Term, Text, useBorder } from '@app/components';
import { useRenderOnResize } from '@utilities';

export type TermsProps = ComponentProps & {
    label: string
    options: any[],
    selected?: any[]
    nullable?: boolean,
    unknown?: boolean,
    onChange?: GenericCallback<any[]>,
    show?: string,
    setShow?: GenericCallback<string>
};

export const Terms = ({label, options, selected, nullable, unknown = true, onChange, show, setShow, className, style, children}: TermsProps) => {
    const sortedOptions = [...options, "Other"].sort();

    useRenderOnResize();

    const selectAll = isEqual(sortedOptions, selected);
    const allOther = unknown && selected?.includes("Other");
    const others = selected?.filter(option => !options.includes(option));
    const standards = selected?.filter(option => options.includes(option));
    const other = unknown && !!others?.length;

    const grow = GrowType.Inner;

    const handleOption = (option: string) => {
        return () => onChange?.(!selected?.includes(option)
            ? [...(selected || []), option].sort()
            : selected.filter(x => x !== option));
    };

    const handleAllOptions = () => {
        return () => onChange?.(selectAll ? [] : sortedOptions);
    };

    const handleOther = () => {
        return () => onChange?.(!selected?.includes("Other")
            ? [...(standards || []), "Other"].sort()
            : selected.filter(x => x !== "Other"));
    };

    const handleShow = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setShow?.(isExpanded ? panel : "");
    };

    const join = (strings: string[], delimiter: string, lastDelimiter: string) => {
        if(strings.length <= 1) {
            return strings[0];
        }
        return strings.slice(0, -1).join(delimiter) + lastDelimiter + strings.slice(-1);
    };

    return (
        <Accordion expanded={show === label.toLowerCase()} onChange={handleShow(label.toLowerCase())} disableGutters>
            <AccordionSummary
                expandIcon={<span style={{rotate: "90deg"}}>&gt;</span>}
                className={classNames(className, `rpgui-cursor-point propogate-no-margin nes-container is-thin is-rounded ${useBorder("white")}`)}
                style={{backgroundColor: "dimgrey", padding: "0 5px", minHeight: "2em", ...style}}
                sx={{...hoverBorderColor()}}
            >
                <Box style={{display: "inline-flex", width: "100%"}}>
                    <Typography style={{ width: "clamp(160px, 33%, 500px)", flexShrink: 0 }}>
                        <InputLabel
                            className="no-margin"
                            style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                fontSize: "clamp(12px, 2vw, 15px)"
                            }}
                        >{label}</InputLabel>
                    </Typography>
                    <span className="super-font" style={{ width: "66%" }}>
                        <Text
                            className="no-margin"
                            style={{
                                maxWidth: "60vw"
                            }}
                            maxLines={1}
                        >
                            Selected: {
                                selectAll ? "All"
                                : selected?.length
                                ? (document.body.clientWidth > 600 || selected?.length <= 1 ? join(selected, ", ", " & ") : `${selected?.length}`)
                                : "None"
                            }
                        </Text>
                    </span>
                </Box>
            </AccordionSummary>
            <AccordionDetails style={{backgroundColor: "grey"}}>
                <Column
                    alignment={ContainerAlignment.Middle}
                >
                    <Row spacing={5} alignment={ContainerAlignment.Middle} wrap>
                        <div style={{margin: "auto"}}>
                            <Term
                                option={selectAll.toString()}
                                setOption={handleAllOptions}
                                active={selectAll}
                                disabled={!nullable && selectAll}
                                width={180}
                            >
                                {selectAll ? "Select None" : "Select All"}
                            </Term>
                        </div>
                        <Row spacing={5} alignment={ContainerAlignment.Middle} wrap>
                            {options.map((option) => {
                                const hasOption = selected?.includes(option)
                                return (
                                    <Term
                                        key={option}
                                        option={option}
                                        setOption={handleOption}
                                        active={hasOption}
                                        disabled={!nullable && hasOption && (selected?.length || 0) <= 1}
                                        grow={grow}
                                    >
                                        {option}
                                    </Term>
                                );
                            })}
                            {unknown &&
                                <Term
                                    option={selectAll.toString()}
                                    setOption={handleOther}
                                    active={other}
                                    disabled={!nullable && allOther && (selected?.length || 0) <= 1}
                                    grow={grow}
                                >
                                    Other
                                </Term>
                            }
                            {children}
                        </Row>
                    </Row>
                </Column>
            </AccordionDetails>
        </Accordion>
    );
};

export default Terms;
