
export const modelToDto = <Model extends {}, Dto extends keyof Model>(obj: Model): Omit<Model, Dto> => {
    let result: any = {};
    for (let prop in obj) {
        if (typeof obj[prop] !== 'object' && typeof obj[prop] !== 'function') {
            result[prop] = obj[prop]
        }
    }
    return result;
};

export default modelToDto;
