import { ComponentProps, TooltipLocation, CoordinateShape } from '@models';
import { Box } from '@mui/material';
import { withTooltip } from './Tooltip';

export type SelectableTooltipProps = ComponentProps & {
    tooltip: TooltipLocation<CoordinateShape>,
    highlight?: boolean
};

export const SelectableTooltip = ({tooltip, highlight, className, style, children}: SelectableTooltipProps) => {
    const { color = "grey", opacity = 50 } = tooltip;
    let position: React.CSSProperties = {};
    let shape: React.CSSProperties = {};

    switch (tooltip.position.type) {
        case "coordinate":
            switch (tooltip.position.shape) {
                case "rectangle":
                    position = {
                        left: tooltip.position.x,
                        top: tooltip.position.y,
                        width: tooltip.position.width,
                        height: tooltip.position.height
                    };
                    break;
                    case "circle":
                        position = {
                            left: tooltip.position.x,
                            top: tooltip.position.y,
                            width: tooltip.position.radius,
                            height: `calc(${tooltip.position.radius} * ${tooltip.position.ratio})`
                        };
                        shape = {
                            borderRadius: "50%"
                        }
                        break;
            }
            break;
    }

    const Tooltip = withTooltip(() => {
        return (
            <Box
                style={{width: "100%", height: "100%", ...shape}}
                className={classNames(className, highlight ? "animation-selection-pulse" : "")}
                sx={{
                    "&:hover": {
                        backgroundColor: color,
                        opacity: opacity / 100
                    }
                }}
            >
                {children}
            </Box>
        );
    }, {
        style: {position: "absolute", ...position, ...style}
    })

    return (
        <Tooltip tooltip={<div style={{ whiteSpace: "pre-wrap" }}>{tooltip.text}</div>} />
    );
};

export default SelectableTooltip;
