
export const getInitials = (name?: string) => {
    if(name == null) return '';
    const initials = name.split(' ');
    const firstLetter = initials?.[0]?.[0];
    const lastLetter = initials?.length > 1 ? initials[initials.length-1]?.[0] : '';
    return `${firstLetter}${lastLetter}`;
};

export default getInitials;
