import { Note, User } from '@models/index';
import { Property } from 'csstype';

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;

export type Color = RGB | RGBA | HEX | Property.Color;

export type Size = Property.Width<string | number>;

export type Email = `${string}@${string}.${string}`;

export type ReactChildren = React.ReactNode;

export type ReactSetState<T> = React.SetStateAction<T>;

export interface BasicComponentProps {
  id?: string,
  className?: string,
  style?: React.CSSProperties
};

export interface ChildrenComponentProps<T> {
  children?: T
};

export interface WithChildrenComponentProps<T> {
  children: T
};

export type ComponentProps = BasicComponentProps & ChildrenComponentProps<ReactChildren>;

export type ComponentChildrenProps = BasicComponentProps & ChildrenComponentProps<ReactComponents>;

export type ComponentWithChildrenProps = BasicComponentProps & WithChildrenComponentProps<ReactComponents>;

export interface InputComponentProps {
  autoFocus?: boolean,
  disabled?: boolean
};

export interface CssStylesheet {
  [key: string]: React.CSSProperties
};

export interface ReactEventType {};
export type ReactEventWithTarget<T extends HTMLElement> = ReactEventType & {
  element: T,
};
export type ReactEventWIthValue<V extends any> = ReactEventType & {
  value: V
};

export type ReactEvent<T extends HTMLElement, V extends any> = ReactEventWithTarget<T> & ReactEventWIthValue<V>;

export type ReactComponent = React.ReactElement<any, any>;

export type ReactComponents = ReactComponent[];

export type GenericKey = string | symbol;

export type GenericObject<T> = {
  [key: GenericKey] : T | GenericObject<T>
};

export type GenericCallback<T, R = void> = (event: T) => R;

export type GenericAsyncCallback<T, R = void> = (event: T) => Promise<R>;

export type GenericFunction<T> = () => GenericObject<T>;

export type GenericEnum = GenericKey | number;

export type ThemeVariant<ThemeType extends GenericEnum, VariantType extends GenericEnum> = {
    [keys in ThemeType]: {
        [keys in VariantType]: string
    }
};

export type TemporaryModel<T> = T & {
  target: any
};

export type UserRuntimeModel = {
  iconURL?: string,
  iconFile?: File
};

export type UserModel = User & UserRuntimeModel;

export type CommentModel = Note & {
  readonly owner: string
};

export type HandleCreateModel<T> = {
  data: T,
  reset: () => void
};

export type HandleUpdateModel<T> = {
  data: T
};

export type HandleDeleteModel<T> = {
  data: T
};

export type CreateNoteModel = {
  description: string
};

export enum SortDirection {
  Ascending = "ASC",
  Descending = "DESC"
};

export const ModalStatus = {
  Open: 1,
  Closing: 2,
  Closed: 3
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ModalStatus = typeof ModalStatus[keyof typeof ModalStatus];

export type RequestError = {
  data: any,
  errors: {
    locations: string[],
    message: string,
    path: string
  }[]
}

export type ObjectNameMapping<T> = {
  [key in Extract<keyof T, string>]: string;
};

export type Image = typeof import("*.gif") | typeof import("*.jpeg") | typeof import("*.jpg") | typeof import("*.png")
| typeof import("*.bmp") | typeof import("*.svg");
export type Video = typeof import("*.mp4") | typeof import("*.mov");
export type Audio = typeof import("*.mp3") | typeof import("*.wav");
export type Download = typeof import("*.zip");
export type Link = URL;

export type ImageAssets = { [key: string]: Image };
export type VideoAssets = { [key: string]: Video };
export type AudioAssets = { [key: string]: Audio };
export type DownloadAssets = { [key: string]: Download };

export type ProjectAssets = {
  Title: ImageAssets,
  Images?: ImageAssets,
  Videos?: VideoAssets,
  Audio?: AudioAssets,
  Downloads?: DownloadAssets
};

export enum FileType {
  Image,
  Video,
  Audio,
  Download,
  Other
};

export type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc['length']]>;

export type IntRange<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>;

export type Map<T extends string> = {
  map: T
};

export type TooltipLocation<T extends PositionType> = Map<"tooltip"> & {
  text: string,
  position: T,
  color?: Color,
  opacity?: IntRange<1, 100>
};

export type PositionCoordinate = {
  type: "coordinate"
};

export type CoordinateRectangle = PositionCoordinate & {
  shape: "rectangle"
  x: string,
  y: string,
  width: string,
  height: string
};

export type CoordinateCircle = PositionCoordinate & {
  shape: "circle",
  x: string,
  y: string,
  radius: string,
  ratio: number
};

export type CoordinatePolygon = PositionCoordinate & {
  shape: "polgyon",
  points: {
    x: string,
    y: string
  }
};

export type CoordinateShape = CoordinateRectangle | CoordinateCircle | CoordinatePolygon;

export type PositionType = CoordinateShape;

export type MapType = TooltipLocation<PositionType>;

export interface FileWithMetadata<T> {
  file: T,
  type: FileType,
  description: string,
  caption: ReactChildren,
  maps?: MapType[]
};
