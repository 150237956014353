import React, { createRef, useEffect } from 'react';
import { ComponentProps } from '@models/types';
import { withTooltip } from '@components/Tooltip';

export enum SliderVariant {
  Thick = "golden",
  Basic = ""
};

export type SliderProps = ComponentProps & {
  variant?: SliderVariant,
  value: number,
  onChange: React.ChangeEventHandler<HTMLInputElement>
  min: number,
  max: number
};

export const Slider = withTooltip(({variant = SliderVariant.Thick, value, onChange, min, max, className, style, children} : SliderProps) => {
  const slider = createRef<HTMLInputElement>();

  useEffect(() => {
    RPGUI.create(slider.current, "slider");
  }, [slider]);

  const handleOnChange = () => {
    if(slider?.current) {
      onChange?.({currentTarget: slider.current} as React.ChangeEvent<HTMLInputElement>);
    }
  };
  
  return (
    <>
      <input
        className={`rpgui-slider ${className} ${variant}`}
        id={"cg"}
        type="range"
        min={min}
        max={max}
        value={value}
        style={{width: "100%", ...style}}
        ref={slider}
        onChange={onChange}
      >
        {children}
      </input>
      <div className="rpgui-slider-change" onClick={handleOnChange} />
    </>
  );
}, { block: true });

export default Slider;